import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_ID } from "../config/constants";
import { httpClient } from "../config/httpClient";
import {
  fetchCities,
  fetchCountries,
  fetchStates,
  generalApiCall,
  setUser as setUserGlobal,
} from "../store/actions";
import _ from 'lodash';

const AuthContext = createContext({
  userData: {
    permissions:[]
  },
  isAuthenticated: () => {},
  getUserId: () => {},
  hasAccess:()=>{},
  logout: () => {},
  setUser: () => {},
});

function AuthProvider(props) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => {
    return {
      userData: state.general.userData,
    };
  });

  const notification = useSnackbar();

  useEffect(() => {
    // dispatch(
    //   generalApiCall({
    //     endpoint: "/common/getStates",
    //     method: "POST",
    //     apiBody: {
    //       pagination: true,
    //     },
    //     key: "states",
    //   })
    // );

    dispatch(fetchCountries());

    if (localStorage.getItem(USER_ID)) {
      httpClient("GET", `/${process.env.REACT_APP_ACCESS_MODE}/user`, {
        body: {},
      })
        .then((result) => {
          dispatch(setUserGlobal(result));
        })
        .catch((er) => {
          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        });
    }
  }, [dispatch, notification]);

  const isAuthenticated = () => {
    return window.localStorage.getItem("user_token");
  };

  const getUserId = () => {
    return userData.id;
  };

  const getUser = () => {
    return userData;
  };

  const setUser = (user) => {
    const {
      email,
      last_name,
      first_name,
      profile_picture,
      id,
      country,
      state,
      city,
    } = user;
    console.log(":USER:", user);
    dispatch(setUserGlobal(user));

    // dispatch(
    //   fetchCities({
    //     state_id: state,
    //   })
    // );
    // dispatch(
    //   fetchStates({
    //     country_id: country,
    //   })
    // );

    return new Promise((resolve, reject) => {
      window.localStorage.setItem("userId", user.id);
      window.localStorage.setItem("user_token", user.token);
      window.localStorage.setItem("email", user.email);
      window.localStorage.setItem(
        "permissions",
        JSON.stringify(user.permissions)
      );
      window.localStorage.setItem(
        "user",
        JSON.stringify({ email, last_name, first_name, profile_picture, id })
      );

      resolve(user);
    });
  };

  const hasAccess = (page) => {
    // console.log(
    //   "Permission HAS ACESS: ",
    //   JSON.parse(localStorage.getItem("permissions"))
    // );
    let activePages = [];
    activePages = userData.permissions;
  
    if (!_.isNull(activePages) && activePages.includes(page)) {
      return true;
    } else return false;
  };
  

  const logout = () => {
    window.localStorage.clear();
    window.location.reload("/login");
  };

  return (
    <AuthContext.Provider
      value={{ userData, hasAccess, getUserId, setUser, logout, getUser, isAuthenticated }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { useAuth, AuthProvider };
