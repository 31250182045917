import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Menu,
  Typography,
  TableRow,
  TableCell,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Modal from "../MuiComponent/Modal";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import Datatable from "../MuiComponent/Datatable";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { httpClient } from "../config/httpClient";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useFormik } from "formik";
import { addCountryValidation } from "../validations/login-validate";

const tableHeader = [
  {
    id: "population",
    label: "NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "DIAL CODE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //     id: 'MANAGE',
  //     label: 'MANAGE',
  //     minWidth: 170,
  //     align: 'left',
  //     format: (value) => value.toFixed(2),
  // },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    marginTop: "25px",
    width: "165vh",
    overflowX: "auto",
    backgroundColor: "white",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function CountryManagement({ userType }) {
  const classes = useStyles();
  const [mode, setMode] = useState("add");
  const [confirmation, setConfirmation] = React.useState(false);
  const getCountriesApiStatus = useCallbackStatus();
  const [page, setPage] = React.useState(0);
  const [search, setSearchKey] = useState();
  const [countryList, setCountryList] = useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modal, setModal] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getCountries = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
      pagination_required: true,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getCountriesApiStatus.run(
      httpClient("POST", "/common/getCountry", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS: ", total);
        setCountryList(data);
        setTotalPages(total);
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addTemplate = () => {
    setModal(!modal);
  };

  useEffect(() => {
    getCountries();
  }, [search]);

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: () => {
      // if (mode == 'edit')
      //     updateCategoy()
      // if (mode == 'delete')
      //     deleteCategory()
      // else
      //     addCategory()
    },
    validationSchema: addCountryValidation,
  });

  const theme = useTheme();

  return (
    <>
      <Modal
        title="Add Template"
        height="440px"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={() => alert("saveHandler")}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={classes.inputLabel}>
                  Template Name*
                </InputLabel>
                <TextField variant="outlined" placeholder="write here" />

                <InputLabel className={classes.inputLabel}>
                  Template File*
                </InputLabel>
                <TextField
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  helperText="*Template format pdf/docx and size should not be more than 2 MB."
                  variant="outlined"
                />

                <InputLabel className={classes.inputLabel}>
                  Category*
                </InputLabel>
                <Select variant="outlined">
                  <MenuItem>Employment</MenuItem>
                </Select>
                {/* <TextField FormHelperTextProps={{style:{fontSize:"10px"}}} helperText="*Template format pdf/docx and size should not be more than 2 MB." variant="outlined"/> */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={classes.inputLabel}>
                  Description*
                </InputLabel>
                <TextField variant="outlined" multiline />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Country Management
        </Typography>
      </Header>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          {/* <Button variant="contained" color="primary" onClick={addTemplate}>Add Template</Button> */}
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getCountriesApiStatus.isPending}
              getDataList={getCountries}
              totalPages={totalPages}
              tableHeader={tableHeader}
            >
              {countryList.map((country) => {
                return (
                  <TableRow>
                    <TableCell>{country.name}</TableCell>
                    <TableCell>{country.dial_code}</TableCell>
                    {/* <TableCell>
                                            <PopupState variant="popover" popupId="demo-popup-menu">
                                                {(popupState) => (
                                                    <React.Fragment>
                                                        <IconButton  {...bindTrigger(popupState)}>
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                        <Menu {...bindMenu(popupState)}>
                                                            <MenuItem onClick={() => {
                                                                setValues({
                                                                    id: country.id,
                                                                    name: country.name
                                                                })
                                                                setMode('edit')
                                                                setModal(true)
                                                                popupState.setOpen(false)
                                                            }}>
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem onClick={() => {
                                                                setValues({
                                                                    id: country.id,
                                                                    name: country.name
                                                                })
                                                                setMode('delete')
                                                                setConfirmation(true)
                                                                popupState.setOpen(false)
                                                            }}>
                                                                Delete
                                                            </MenuItem>
                                                        </Menu>
                                                    </React.Fragment>
                                                )}
                                            </PopupState>
                                        </TableCell> */}
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default CountryManagement;
