import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import Modal from "../../MuiComponent/Modal";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { httpClient } from "../../config/httpClient";
import moment from "moment";
import Datatable from "../../MuiComponent/Datatable";

const columns = [
  { id: "name", align: "left", label: "Transaction Date", minWidth: 170 },
  {
    id: "detailed_profile",
    label: "CUSTOMER NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "size",
    label: "ORDER ID",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "PAYMENT ID",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "VIEW",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  heading: {
    fontWeight: 600,
  },
  value: {
    color: "#63646c",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

const ModalHeader = () => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box flexDirection="row" justifyContent="space-between">
          <Box className={classes.heading} style={{ fontSize: "14px" }}>
            Client Details
          </Box>
        </Box>
        <Box
          flexDirection="row"
          textAlign="right"
          justifyContent="space-between"
        >
          <Box style={{ fontSize: "14px" }}>
            <span className={classes.heading}>Time:</span>{" "}
            <span className={classes.value}>12/06/2020</span>
          </Box>
        </Box>
      </Box>
    </>
  );
};

function TransactionDatatable({ filter }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  const fetchDataApiStatus = useCallbackStatus();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearchKey] = useState("");
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = async (pageSize = 10, pageNumber = 1) => {
    await fetchDataApiStatus.run(
      httpClient(
        "GET",
        `/admin/transactions?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&status=${filter}`,
        {
          body: {},
        }
      ).then((result) => {
        console.log("Transaction List: ", result);

        const {
          data: { data, meta },
        } = result;

        setData(data);
        setTotalRecords(meta.total);
      })
    );
  };

  const viewClient = () => {
    setModal(!modal);
  };

  const theme = useTheme();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter]);

  return (
    <>
      <Modal
        height="240px"
        width="100%"
        headerComponent={ModalHeader}
        saveBtnText="OKAY"
        saveHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      ></Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by order ID or payment ID"
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              getDataList={fetchData}
              totalRecords={totalRecords}
              loading={fetchDataApiStatus.isPending}
              tableHeader={columns}
            >
              {data.map((item) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell>
                      {moment(item.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {item.name} {item.last_name}
                    </TableCell>
                    <TableCell>{item.order_id}</TableCell>
                    <TableCell>{item.payment_id}</TableCell>
                    <TableCell align="center">
                      <a style={{ color: "#2895c4" }} onClick={viewClient}>
                        View
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
            {/* <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 600 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell>
                          {moment(item).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {item.name} {item.last_name}
                        </TableCell>
                        <TableCell>{item.order_id}</TableCell>
                        <TableCell>{item.payment_id}</TableCell>
                        <TableCell align="center">
                          <Link
                            style={{ color: "#2895c4" }}
                            onClick={viewClient}
                          >
                            View
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default TransactionDatatable;
