import {
  CssBaseline,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { portalTheme } from "../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    background: portalTheme.primary,
    // backgroundImage: "linear-gradient(to bottom, #d2b26a , #e1bc54)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontWeight: "600",
    fontSize: "43px",
    color: theme.palette.custom.heading,
  },
  subHeading: {
    color: theme.palette.custom.subHeading,
    fontSize: "18px",
  },
}));

function OuterLayout(props) {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={4} md={6} className={classes.image}>
        <img style={{ height: "400px" }} src={props.logo} alt="Brand" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        component={Paper}
        elevation={4}
        square
        container
        alignItems="center"
      >
        <Grid item xs={12}>
          <div className={classes.paper}>
            <Typography
              component="h3"
              variant="h3"
              gutterBottom
              className={classes.heading}
            >
              {props.heading}
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              gutterBottom
              className={classes.subHeading}
            >
              {props.subHeading}
            </Typography>
            {props.children}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OuterLayout;
