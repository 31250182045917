import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Menu,
  Select,
  MenuItem,
} from "@material-ui/core";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import { useFormik } from "formik";
import {
  editSliderValidation,
  addSliderValidation,
} from "../validations/login-validate";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Datatable from "../MuiComponent/Datatable";
import _ from "lodash";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";
import { useHistory } from "react-router-dom";
import DetailView from "./DetailView";
import clsx from "clsx";
import moment from "moment";
import Modal from "../MuiComponent/Modal";

const tableHeader = [
  { id: "name", align: "left", label: "DATE & TIME", minWidth: 170 },
  {
    id: "size",
    label: "FEEDBACK ON",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "RATING",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "FEEDBACK",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "FEEDBACK BY",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function ReviewRating() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const [mode, setMode] = useState("add");
  const [view, setView] = useState("false");
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = React.useState(false);
  const addSliderApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const getBannersApiStatus = useCallbackStatus();
  const approveReviewApiStatus = useCallbackStatus();
  const [filter, setFilter] = useState("pending");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderList, setOrderList] = React.useState([]);
  const [selectedData, setselectedData] = React.useState({});
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const [openModal, setOpenModal] = useState(false);
  const [ratingFilter, setreviewSelect] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [orderDetail, setOrderDetail] = useState({
    cart: { items: [] },
    billing_address: {},
    shipping_address: {},
  });

  const deleteProduct = () => {
    alert("delete");
    setOpenModal(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
    }
  };

  //   const getCategories = async () => {
  //     const apiBody = {
  //       pagination_required: true,
  //     };

  //     await getCategoryListStatus.run(
  //       httpClient("POST", "/common/category", {
  //         body: apiBody,
  //       }).then((result) => {
  //         console.log("Result: ", result);

  //         const { data } = result;

  //         setCategoriesList(data);
  //       })
  //     );
  //   };

  const getOrderList = async (pageSize = 10, pageNumber = 1) => {
    await getBannersApiStatus.run(
      httpClient(
        "GET",
        `/admin/review?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&status=${filter}&rating=${ratingFilter}&pagination_required: true&type=admin`,
        {
          body: {},
        }
      ).then((result) => {
        console.log("getSLiders List: ", result);

        const {
          data: { data, meta },
        } = result;

        setOrderList(data);
        setTotalPages(meta.total);
      })
    );
  };

  const editBanner = async () => {
    let fd = new FormData();
    fd.append("id", values.id);
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);

    if (typeof image == "object") fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/updateSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const updateReview = async ({ id, status }) => {
    await approveReviewApiStatus.run(
      httpClient("Post", "/admin/updateReview", {
        body: {
          id: id,
          status: status,
        },
      })
        .then((result) => {
          console.log("Result approveReview", result);
          setOpenModal(false);
          getOrderList();
        })
        .catch((er) => {
          console.log("Error approveReview", er);
        })
    );
  };

  const deleteBanner = async () => {
    await addSliderApiStatus.run(
      httpClient("POST", "/admin/deleteSlider", {
        body: {
          id: values.id,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setConfirmation(false);
          //   getSliders();

          notification.enqueueSnackbar("Slider Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addSlider = async () => {
    let fd = new FormData();
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);
    fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/createSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      //   category_id: "",
      link: "",
      type: "App",
      image: "",
      active: "Y",
    },
    onSubmit: () => {
      if (mode == "edit") editBanner();
      else if (mode == "delete") deleteBanner();
      else addSlider();
    },
    validationSchema:
      mode == "edit" || mode == "delete"
        ? editSliderValidation
        : addSliderValidation,
  });

  //   useEffect(() => {
  //     getCategories();
  //   }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getOrderList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter, ratingFilter]);

  const NewConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            Confirm delete
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Review Rating
        </Typography>
      </Header>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => setFilter("pending")}
            className={clsx({
              [classes.activeFilter]: filter === "pending",
            })}
            variant="contained"
          >
            PENDING
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({ [classes.activeFilter]: filter === "approved" })}
            onClick={() => setFilter("approved")}
          >
            APPROVED
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({ [classes.activeFilter]: filter === "rejected" })}
            onClick={() => setFilter("rejected")}
          >
            REJECTED
          </Button>
        </Box>
      </Box>
      {view == "false" ? (
        <>
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={5}
            >
              <Box style={{ paddingRight: "20px" }}>Search</Box>
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: { padding: "12px", backgroundColor: "white" },
                }}
                placeholder="by order ID, name, email"
              />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                // flexDirection: "row-reverse",
                paddingLeft: "30px",
              }}
              item
              xs={7}
            >
              <Box>Rating</Box>
              <Select
                className={globalClasses.selectInput}
                style={{ marginLeft: "20px", paddingRight: "20px" }}
                labelId="demo-simple-select-outlined-label"
                value={ratingFilter}
                onChange={(event) => {
                  setreviewSelect(event.target.value);
                }}
                variant="outlined"
              >
                <MenuItem value={5}>5 Stars</MenuItem>
                <MenuItem value={4}>4 Stars</MenuItem>
                <MenuItem value={3}>3 Stars</MenuItem>
                <MenuItem value={2}>2 Stars</MenuItem>
                <MenuItem value={1}>1 Star</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Datatable
                  getDataList={getOrderList}
                  totalRecords={totalPages}
                  loading={getBannersApiStatus.isPending}
                  tableHeader={tableHeader}
                >
                  {orderList.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </TableCell>
                        <TableCell>{item.product_name}</TableCell>
                        <TableCell>{item.rating} Star</TableCell>
                        <TableCell>{item.review}</TableCell>
                        <TableCell>{_.upperCase(item.reviewer_name)}</TableCell>
                        <TableCell>
                          {/* <Link
                            className={globalClasses.link}
                            style={{ color: "#2895c4" }}
                            onClick={() => {
                              //   console.log(order);
                              //   setOrderDetail(order);
                              //   setView("true");
                            }}
                          >
                            View
                          </Link> */}

                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton {...bindTrigger(popupState)}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      updateReview({
                                        id: item.id,
                                        status: "approved",
                                      });
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Approved
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setselectedData(item);
                                      setOpenModal(true);
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Rejected
                                  </MenuItem>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Datatable>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <DetailView data={orderDetail} onBack={() => setView("false")} />
        </>
      )}

      <Modal
        height="169px"
        width="388px"
        headerComponent={NewConfirmationModalHeader}
        status={openModal}
        loading={approveReviewApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Yes"
        closeHandler={() => setOpenModal(false)}
        saveHandler={() => {
          updateReview({ id: selectedData.id, status: "rejected" });
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Are you sure you want to Reject this Review ?
        </Box>
      </Modal>
    </>
  );
}

export default ReviewRating;
