import { createAction } from "@reduxjs/toolkit";
import {
  FETCH_CITIES_DATA,
  FETCH_COUNTRY_DATA,
  FETCH_STATE_DATA,
  GENERAL_API_CALL_DATA,
  SET_REDUCER_DATA,
  SET_USER,
} from "../constants";
import { GENERAL_API_CALL } from "../constants";

export const setUser = (payload) => {
  return {
    type: SET_USER,
    payload,
  };
};

export const fetchCountries = createAction(FETCH_COUNTRY_DATA);
export const fetchCities = createAction(FETCH_CITIES_DATA);
export const fetchStates = createAction(FETCH_STATE_DATA);
export const setReducerData = createAction(SET_REDUCER_DATA);
export const generalApiCall = createAction(GENERAL_API_CALL);
export const setGeneralApiData = createAction(GENERAL_API_CALL_DATA);
