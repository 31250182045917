import {
  Box,
  Grid,
  Paper,
  useTheme,
  MenuItem,
  Select,
  IconButton,
  TableRow,
  Menu,
  TableCell,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TextField from "../../MuiComponent/TextField";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datatable from "../../MuiComponent/Datatable";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import moment from "moment";

const tableHeader = [
  {
    id: "population",
    label: "Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "ph_no",
    label: "Phone Number",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "view",
    label: "View",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "MANAGE",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function InquireyDatatable() {
  const [search, setSearchKey] = useState("");
  const [data, setData] = useState([]);
  const [topCategory, setTopCategory] = useState("new");
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const [totalPages, setTotalPages] = React.useState(10);
  const classes = useStyles();
  const inquireyApiStatus = useCallbackStatus();

  const getInquirey = useCallback(async (pageSize = 10, pageNumber = 1) => {
    await inquireyApiStatus.run(
      httpClient(
        "POST",
        `/admin/listInquiries?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&status=${topCategory}&pagination_required= true`,
        {
          body: {},
        }
      )
        .then((result) => {
          console.log("Result Inquirey", result);
          const {
            data: { data, meta },
          } = result;

          setData(data);
          setTotalPages(meta.total);
        })
        .catch((er) => {
          console.log("ERROR", er);
        })
    );
  },[]);

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      getInquirey();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [topCategory, search, getInquirey]);

  return (
    <div>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          item
          xs={6}
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <Box style={{ paddingLeft: "20px" }}>Type</Box>
            <Select
              className={globalClasses.selectInput}
              style={{ marginLeft: "20px", paddingRight: "20px" }}
              labelId="demo-simple-select-outlined-label"
              value={topCategory}
              onChange={(e) => setTopCategory(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="resolved">Resolved</MenuItem>
            </Select>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            {" "}
            <Datatable
              loading={inquireyApiStatus.isPending}
              getDataList={getInquirey}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {data.map((category,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(category.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.email}</TableCell>
                    <TableCell>{category.phone_number}</TableCell>
                    <TableCell>
                      <a
                        className={globalClasses.link}
                        style={{ color: "#2895c4" }}
                        onClick={() => {
                          console.log(category);
                        }}
                      >
                        View
                      </a>
                    </TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  // setValues({
                                  //   id: category.id,
                                  //   name: category.name,
                                  // });
                                  // setMode("edit");
                                  // setModal(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  // setValues({
                                  //   id: category.id,
                                  //   name: category.name,
                                  // });
                                  // if (category.totalquestion == 0) {
                                  //   setMode("delete");
                                  //   setConfirmation(true);
                                  //   popupState.setOpen(false);
                                  // } else {
                                  //   setAlertModal(true);
                                  //   popupState.setOpen(false);
                                  // }
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default InquireyDatatable;
