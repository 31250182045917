import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useGeneralStyle from "../../assets/css/general";
import LockIcon from "../../assets/images/drawer/password.svg";
import PeopleIcon from "../../assets/images/icons/people.svg";
import CallIcon from "../../assets/images/icons/phone_call.svg";
import logo from "../../assets/images/logo/Image.png";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import OuterLayout from "../../layouts/login/OuterLayout";
import TextField from "../../MuiComponent/TextField";
import { useAuth } from "../../providers/AuthProvider";
import { adminRegistrationValidation } from "../../validations/login-validate";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    height: "8px",
    minHeight: "unset",
  },
  inputLabel: {
    color: theme.palette.custom.heading,
    fontSize: "14px",
  },
  inputLabelRegister: {
    color: theme.palette.custom.heading,
    fontSize: "22px",
  },
}));

function AdminRegister(props) {
  let history = useHistory();
  let auth = useAuth();
  const globalClasses = useGeneralStyle();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [code, setCode] = useState("+91");
  const [encrypted_token, setToken] = useState("");
  const [expires, setExpiry] = useState("");
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const registerAdminApiStatus = useCallbackStatus();
  const apiSource = useCancelRequest();
  const notification = useSnackbar();
  const registerApiStatus = useCallbackStatus();
  const registrationApiStatus = useCallbackStatus();

  const {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      confirm_password: "",
      phone_number: "",
      password: "",
      email: "",
      expires: "",
      signature: "",
      // city: "none",
      // area: "none",
      // gender: "male",
    },
    validationSchema: adminRegistrationValidation,
    onSubmit: () => {
      registration();
    },
  });

  const getQueryParamToken = () => {
    const parsed = queryString.parse(history.location.search);

    const temptoken = parsed.signature;
    if (!temptoken) {
      window.location = "/login";
      return;
    }
    console.log(parsed);

    //     const decryptedData = decrypt(temptoken, process.env.REACT_APP_DEFAULT_SECRET_KEY);
    //     const parsedObj = JSON.parse(decryptedData);

    //     console.log("URL PARMS: ",parsedObj)
    setEmail(parsed.email);
    setToken(temptoken);
    // setExpiry(parsed.expires);
    setRole(parsed.role);
    //     setToken(temptoken)
  };

  const registration = async () => {
    const parsed = queryString.parse(history.location.search);
    console.log(parsed);
    await registrationApiStatus.run(
      httpClient("POST", "/admin/register", {
        body: {
          ...values,
          role: role,
          expires: parsed.expires,
          email: parsed.email,
          signature: parsed.signature,
        },
      })
        .then((result) => {
          notification.enqueueSnackbar("User Registered Successfully", {
            variant: "success",
          });
          console.log("registration result :", result);
          history.replace("/login");
        })
        .catch((er) => {
          console.log("Error register :", er);
        })
    );
  };

  useEffect(() => {
    getQueryParamToken();
  }, []);

  useEffect(() => {
    if (auth.getUserId()) {
      history.push("/");
    }
  });

  return (
    <OuterLayout heading="Register" logo={logo} subHeading="">
      <Grid spacing={3} container>
        <Grid item xs={6}>
          <Box className={classes.inputLabelRegister}>Email</Box>
          <Box>{email}</Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.inputLabelRegister}>Role</Box>
          <Box>{role}</Box>
        </Grid>
        <Grid item xs={6}>
          <InputLabel className={classes.inputLabel}>First Name</InputLabel>
          <TextField
            id="first_name"
            name="first_name"
            error={touched.first_name && errors.first_name}
            helperText={touched.first_name && errors.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            style={{ paddingTop: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="emailicon" src={PeopleIcon} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Enter your first name"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel className={classes.inputLabel}>Last Name</InputLabel>
          <TextField
            id="last_name"
            name="last_name"
            error={touched.last_name && errors.last_name}
            helperText={touched.last_name && errors.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            style={{ paddingTop: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="emailicon" src={PeopleIcon} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Enter your last name"
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel className={classes.inputLabel}>Password</InputLabel>
          <TextField
            id="password"
            name="password"
            error={touched.password && errors.password}
            helperText={touched.password && errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            type={showCurrentPassword ? "text" : "password"}
            style={{ paddingTop: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="emailicon" src={LockIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  size="small"
                  aria-label="Toggle password visibility"
                  onClick={() =>
                    setCurrentShowPassword((prevValue) => !prevValue)
                  }
                >
                  {showCurrentPassword ? (
                    <VisibilityOff
                      fontSize="small"
                      className={globalClasses.icon}
                    />
                  ) : (
                    <Visibility
                      fontSize="small"
                      className={globalClasses.icon}
                    />
                  )}
                </IconButton>
              ),
            }}
            variant="outlined"
            placeholder="Enter your password"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel className={classes.inputLabel}>
            Confirm Password
          </InputLabel>
          <TextField
            id="confirm_password"
            name="confirm_password"
            error={touched.confirm_password && errors.confirm_password}
            helperText={touched.confirm_password && errors.confirm_password}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirm_password}
            type={showConfirmPassword ? "text" : "password"}
            style={{ paddingTop: "10px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="emailicon" src={LockIcon} />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  size="small"
                  aria-label="Toggle password visibility"
                  onClick={() =>
                    setConfirmShowPassword((prevValue) => !prevValue)
                  }
                >
                  {showConfirmPassword ? (
                    <VisibilityOff
                      fontSize="small"
                      className={globalClasses.icon}
                    />
                  ) : (
                    <Visibility
                      fontSize="small"
                      className={globalClasses.icon}
                    />
                  )}
                </IconButton>
              ),
            }}
            variant="outlined"
            placeholder="Enter your password"
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Mobile Number</InputLabel>
          <Box display="flex">
            <Box>
              <Select
                variant="outlined"
                fullWidth
                value={code}
                onChange={(event) => setCode(event.target.value)}
                style={{
                  width: "80px",
                  height: "43px",
                  marginTop: "10px",
                  marginRight: "10px",
                  textAlign: "left",
                }}
                className={globalClasses.selectInput}
              >
                <MenuItem value="1">+91</MenuItem>
                <MenuItem value="2">+98</MenuItem>
                <MenuItem value="3">+1</MenuItem>
                {/* {countryCodes.map((code, i) => (
                  <MenuItem key={i} value={code.number}>
                    {code.number}
                  </MenuItem>
                ))} */}
              </Select>
            </Box>
            <Box>
              <TextField
                id="phone_number"
                name="phone_number"
                error={touched.phone_number && errors.phone_number}
                helperText={touched.phone_number && errors.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone_number}
                type="number"
                style={{ paddingTop: "10px", width: "300px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="emailicon" src={CallIcon} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Enter your mobile no"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box style={{ paddingTop: "60px", textAlign: "center" }}>
        <Button
          disabled={registrationApiStatus.isPending}
          style={{ fontWeight: "600" }}
          variant="contained"
          color="primary"
          onClick={submitForm}
        >
          {registrationApiStatus.isPending ? (
            <CircularProgress size={23} />
          ) : (
            "Register"
          )}
        </Button>
        <Typography
          variant="body1"
          component="p"
          style={{ paddingTop: "12px" }}
        >
          Already have an account?{" "}
          <Link to="/login" className={globalClasses.link}>
            Login
          </Link>
        </Typography>
      </Box>
    </OuterLayout>
  );
}

export default AdminRegister;
