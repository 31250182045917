import { Box, Button, Grid, useTheme } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import _ from "lodash";
import moment from "moment";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "../../providers/AuthProvider";
import { currency } from "../../utils/utility";
var converter = require("number-to-words");

Composition.defaultProps = {
  data: {
    items: [],
  },
};

function Composition({ data, onBack }) {
  const theme = useTheme();
  const { userData } = useAuth();
  const location = useLocation();
  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  function printdiv(elem) {
    var header_str =
      "<html><head><title>" + document.title + "</title></head><body>";
    var footer_str = "</body></html>";
    var new_str = document.getElementById(elem).innerHTML;
    var old_str = document.body.innerHTML;
    document.body.innerHTML = header_str + new_str + footer_str;
    window.print();
    document.body.innerHTML = old_str;
    // return false;
  }
  //   let {
  //     state: { data },
  //   } = location;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Button
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBackIosIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ backgroundColor: "white" }}>
            <Box
              ref={componentRef}
              id="printable_div_id"
              style={{ margin: "10px 10px", marginLeft: "20px" }}
            >
              <meta
                httpEquiv="Content-Type"
                content="text/html; charset=utf-8"
              />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n        .page-break {\n            page-break-after: always;\n        }\n        table{\n            border-collapse: collapse;\n            width: 100%;\n        }\n",
                }}
              />
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}>
                    BILL OF SUPPLY
                  </div>
                  <div>(ORIGINAL FOR RECIPIENT)</div>
                </div>
              </div>
              <table border={1}>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      {_.upperCase(businessDetails.name)}
                      <div>{businessDetails.address}</div>
                      {/* 156, B-Block Sec-14 Udaipur (Raj.) */}
                      <div>
                        {businessDetails.city_name +
                          ", " +
                          businessDetails.state_name +
                          " (" +
                          businessDetails.pincode +
                          ")"}
                      </div>
                      <div>Mo: {businessDetails.contact_number}</div>
                      <div>Email: {businessDetails.email}</div>
                    </td>
                    <td rowSpan={2} colSpan={4}>
                      <table width="100%" height="200px">
                        <tbody>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <td
                              style={{ borderRight: "1px solid" }}
                              width="50%"
                            >
                              Order ID
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {data.cart_id}
                              {/* {data.invoice_number} */}
                            </td>
                          </tr>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <td
                              style={{ borderRight: "1px solid" }}
                              width="50%"
                            >
                              Date
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {moment(data.created_at).format("DD MMM YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ borderRight: "1px solid" }}
                              width="50%"
                            >
                              Mode
                            </td>
                            <td style={{ textAlign: "left" }}>
                              CASH
                              {/* {_.capitalize("data.payment_mode")} */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Buyer (Bill to)</div>
                      <Box fontWeight="600">{data?.name}</Box>
                      {/* <Box>{data.billing_address.line1}</Box>
                  <Box>{data.billing_address.city}</Box> */}
                      <Box>Mob: {data.mobile}</Box>

                      {/* <div>156, B-Block Sec-14 Udaipur (Raj.)</div>
                  <div>313001</div>
                  <div>Mo: 9460970462</div>
                  <div>Email: jainelectricals2022@gmail.com</div> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <table>
                        <tbody>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <td width="70px">S.No</td>
                            <td>Description of Goods</td>
                            <td>Quantity</td>
                            <td>Rate</td>
                            <td>Discount(%)</td>
                            <td>{/* Tax */}</td>
                            <td>Amount (₹)</td>
                          </tr>
                          {data.items.map((item, index) => (
                            <tr>
                              <td width="70px">{index + 1}</td>
                              <td>
                                {" "}
                                {item.name + " "}
                                {Object.keys(item.options).length > 0 &&
                                  `[${Object.keys(item.options)
                                    .map((key) => {
                                      return `${key}: ${item.options[key].value}`;
                                    })
                                    .join(", ")}]`}
                                {/* {(() => {
                                  if (Array.isArray(item.options) == false) {
                                    let string = [];

                                    Object.keys(item.options).map((key) => {
                                      string.push(
                                        `${key}: ${item.options[key][0].value}, `
                                      );
                                    });

                                    return `[ ${string.join(", ")}]`;
                                  }
                                })()} */}
                              </td>
                              <td>{item.quantity}</td>
                              <td>
                                {currency({
                                  value: item.product_price,
                                  currency: userData.currency,
                                })}
                              </td>
                              <td>{item.discount}</td>
                              <td></td>
                              <td>
                                {currency({
                                  value: item.total_price,
                                  currency: userData.currency,
                                })}
                              </td>
                            </tr>
                          ))}
                          {data.discount > 0 && (
                            <tr style={{ borderTop: "1px solid" }}>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                  paddingRight: 30,
                                }}
                                colSpan={2}
                              >
                                Discount
                              </td>
                              <td>{/* 03 */}</td>
                              <td colSpan={2}></td>
                              <td>{/* 03 */}</td>
                              <td style={{ fontWeight: "bold" }}>
                                {data.discount}%
                              </td>
                            </tr>
                          )}

                          <tr style={{ borderTop: "1px solid" }}>
                            <td
                              style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                paddingRight: 30,
                              }}
                              colSpan={2}
                            >
                              Total
                            </td>
                            <td>{/* 03 */}</td>
                            <td colSpan={2}></td>
                            <td>{/* 03 */}</td>
                            <td style={{ fontWeight: "bold" }}>
                              {currency({
                                value: data.total_payable,
                                currency: userData.currency,
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td style={{ paddingRight: 30 }}>
                      Amount Chargeable (in words)
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td style={{ paddingRight: 30, fontWeight: "bold" }}>
                      INR {_.startCase(converter.toWords(data.total_payable))}{" "}
                      Only
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td
                      style={{
                        paddingRight: 30,
                        borderRight: "1px solid",
                        width: "50%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 100,
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          Company PAN: {businessDetails.pancard}
                        </div>
                        <div>
                          <div>Declaration:</div>
                          <div style={{ fontStyle: "italic" }}>
                            We declare that this invoice shows the actual price
                            of the goods described and that all particulars are
                            true and correct.
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ paddingRight: 30 }} colSpan={2}>
                      <div>Company's Bank Details</div>
                      <div>
                        A/C Holder Name:{" "}
                        {businessDetails?.account_details?.account_holder_name}
                      </div>
                      <div>
                        Bank Name: {businessDetails?.account_details?.bank_name}
                      </div>
                      <div>
                        A/C No.:{" "}
                        {businessDetails?.account_details?.account_number}
                      </div>
                      <div>
                        IFS Code: {businessDetails?.account_details?.ifsc_code}
                      </div>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid", height: 100 }}>
                    <td
                      style={{
                        paddingRight: 30,
                        borderRight: "1px solid",
                        width: "50%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 100,
                          flexDirection: "column",
                        }}
                      >
                        <div>Customer's Seal &amp; Signature</div>
                      </div>
                    </td>
                    <td
                      style={{ paddingRight: 30, textAlign: "right" }}
                      colSpan={2}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 100,
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>For Jain Electricals</div>
                        <div>Authorised Signatory</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <Box textAlign={"end"} marginRight={"10px"}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                handlePrint();
                // printdiv("printable_div_id");
                // window.print();
              }}
            >
              Print
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Composition;
