import { TextField as MuiTextField} from '@material-ui/core'
import React from 'react'

function TextField(props) {
    return (
        <MuiTextField fullWidth inputProps={{style:{padding:"12px"}}}  {...props} />
    )
}

export default TextField
