import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import TextField from "../../MuiComponent/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { adminProfileForm } from "../../validations/login-validate";
import PeopleIcon from "../../assets/images/icons/people.svg";
import { USER_TOKEN } from "../../config/constants";
import { httpClient } from "../../config/httpClient";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(() => ({
  inputRootAutoComplete: {
    height: "43px",
    padding: "0px 8px !important",
  },
}));

function PersonalDetails({ profileData, refresh, loading }) {
  const globalClasses = useGeneralStyle();
  const theme = useTheme();
  const classes = useStyles();
  const auth = useAuth();
  const notification = useSnackbar();
  const [countries, setCountries] = useState([
    { dial_code: "+91", name: "India" },
  ]);
  const updateProfileApiStatus = useCallbackStatus();

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    submitForm,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      prefix: "",
      first_name: "",
      last_name: "",
      contact_number: "",
      profile_picture: "",
    },
    validationSchema: adminProfileForm,
    onSubmit: () => {
      updateProfile();
    },
  });

  useEffect(() => {
    rest.setValues({ ...profileData });
  }, [profileData]);

  let updateProfile = async () => {
    await updateProfileApiStatus.run(
      httpClient("POST", "/partner/updateProfile", {
        body: {
          id: auth.getUserId(),
          // age: values.age,
          // gender: values.gender,
          prefix: values.prefix,
          name: values.first_name,
          last_name: values.last_name,
          contact_number: values.contact_number.replace(/[-\s]/g, ""),
        },
      }).then((result) => {
        const { data } = result;
        data.token = window.localStorage.getItem(USER_TOKEN);

        console.log("Result Update Admin Profile: ", result, data);
        auth.setUser(data);

        refresh();

        notification.enqueueSnackbar("Profile Updated Successfully", {
          variant: "success",
        });
      })
    );
  };

  if (loading) {
    return (
      <Box
        style={{
          textAlign: "center",
          height: "50vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ padding: theme.spacing(2) }}>
      {/* {JSON.stringify(values)} */}
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              First Name
            </InputLabel>
            <TextField
              id="first_name"
              name="first_name"
              error={touched.first_name && errors.first_name}
              helperText={touched.first_name && errors.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Last Name
            </InputLabel>
            <TextField
              id="last_name"
              name="last_name"
              error={touched.last_name && errors.last_name}
              helperText={touched.last_name && errors.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Contact Number
            </InputLabel>
            <Box display={"flex"}>
              <Box marginRight={2}>
                {values.prefix !== "" && (
                  <Autocomplete
                    classes={{ inputRoot: classes.inputRootAutoComplete }}
                    id="combo-box-demo"
                    defaultValue={countries.find(
                      (country) => country.dial_code == values.prefix
                    )}
                    value={countries.find(
                      (country) => country.dial_code == values.prefix
                    )}
                    options={countries}
                    onChange={(event, newValue) => {
                      setFieldValue("prefix", newValue?.dial_code);
                    }}
                    getOptionLabel={(option) =>
                      `(${option.dial_code}) ${option.name}`
                    }
                    style={{ height: "50px", width: "150px" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="+91 (India)"
                          onChange={(event) => {
                            //setSearchKey(event.target.value);
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                )}
              </Box>
              <Box>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  error={touched.contact_number && errors.contact_number}
                  helperText={touched.contact_number && errors.contact_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="people-icon" src={PeopleIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            {/* <MuiPhoneNumber
              id="contact_number"
              name="contact_number"
              error={touched.contact_number && errors.contact_number}
              helperText={touched.contact_number && errors.contact_number}
              value={values.contact_number}
              defaultCountry={"in"}
              variant="outlined"
              onChange={(v) => {
                rest.setValues({ ...values, contact_number: v });
                console.log("PHone Numbert: ", v.replace(/[-\s]/g, ""));
              }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Phone No (Optional)"
            /> */}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={updateProfileApiStatus.isPending ? true : false}
            onClick={submitForm}
          >
            {updateProfileApiStatus.isPending ? (
              <CircularProgress size={23} />
            ) : (
              "Update"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PersonalDetails;
