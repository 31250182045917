import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from "../../MuiComponent/TextField";
import LockIcon from "../../assets/images/drawer/password.svg";
import logo from "../../assets/images/logo/Image.png";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
import { resetPasswordValidation } from "../../validations/login-validate";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.custom.heading,
    fontSize: "14px",
  },
}));

function ResetPassword(props) {
  let history = useHistory();
  let auth = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        password: "",
        confirm_password: "",
      },
      onSubmit: () => {
        resetpassword();
      },
      validationSchema: resetPasswordValidation,
    });

  const apiSource = useCancelRequest();
  const resetPasswordApiStatus = useCallbackStatus();
  const notification = useSnackbar();

  useEffect(() => {
    if (
      _.isUndefined(history.location.state) ||
      history.location.state.from != "otp-verification"
    ) {
      console.log("History:", history);
      history.push("/forgot-password");
    }
  });

  let resetpassword = async () => {
    const { email, otp } = history.location.state;

    let apiBody = {
      password: values.password,
      email,
      otp,
      entity_type: "admin",
    };

    await resetPasswordApiStatus.run(
      httpClient("POST", "/common/resetPassword", {
        body: apiBody,
      })
        .then((result) => {
          notification.enqueueSnackbar("Password Change Successfully", {
            variant: "success",
          });

          history.replace("/login");
        })
        .catch((er) => {
          console.log("Error: ", er);
        })
    );
  };

  const classes = useStyles();

  useEffect(() => {
    if (auth.getUserId()) {
      history.push("/");
    }
  });

  return (
    <OuterLayout
      heading="Reset Password"
      logo={logo}
      subHeading="Please create your new password"
    >
      <Box style={{ paddingTop: "30px" }}>
        <InputLabel className={classes.inputLabel}>New Password</InputLabel>
        <TextField
          id="password"
          name="password"
          helperText="Your password must be at least 8 character long and should not contain first name and lastname."
          onChange={handleChange}
          onBlur={handleBlur}
          // type="password"
          error={touched.password && errors.password}
          // helperText={touched.password && errors.password}
          value={values.password}
          style={{ paddingTop: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="lockicon" src={LockIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword((prevValue) => !prevValue)}
              >
                {showPassword ? (
                  <VisibilityOff fontSize="small" className={classes.icon} />
                ) : (
                  <Visibility fontSize="small" className={classes.icon} />
                )}
              </IconButton>
            ),
          }}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          placeholder="New Password"
        />
      </Box>
      <Box style={{ paddingTop: "30px" }}>
        <InputLabel className={classes.inputLabel}>Confirm Password</InputLabel>
        <TextField
          id="confirm_password"
          name="confirm_password"
          // type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.confirm_password && errors.confirm_password}
          helperText={touched.confirm_password && errors.confirm_password}
          value={values.confirm_password}
          style={{ paddingTop: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="lockicon" src={LockIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword((prevValue) => !prevValue)}
              >
                {showPassword ? (
                  <VisibilityOff fontSize="small" className={classes.icon} />
                ) : (
                  <Visibility fontSize="small" className={classes.icon} />
                )}
              </IconButton>
            ),
          }}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          placeholder="Confirm password"
        />
      </Box>
      <Box style={{ paddingTop: "30px", textAlign: "center" }}>
        <Button
          disabled={resetPasswordApiStatus.isPending}
          onClick={submitForm}
          style={{ fontWeight: "600" }}
          variant="contained"
          color="primary"
        >
          {resetPasswordApiStatus.isPending ? (
            <CircularProgress size={23} />
          ) : (
            "Done"
          )}
        </Button>
      </Box>
    </OuterLayout>
  );
}

export default ResetPassword;
