import { SET_PARENT_CATEGORIES,SET_CATEGORIES } from "../constants"

export const setParentCategory = (payload) => {
    return {
        type: SET_PARENT_CATEGORIES,
        payload
    }
}

export const setCategory = (payload) => {
    return {
        type: SET_CATEGORIES,
        payload
    }
}