import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import { useAuth } from "../providers/AuthProvider";
import { currency } from "../utils/utility";
import { portalTheme } from "../layouts/theme";

function Dashboard() {
  const theme = useTheme();
  const { userData } = useAuth();
  const fetchApiStatus = useCallbackStatus();
  const [dashboardData, setDashboardData] = useState({});

  const fetchDashboardData = useCallback(async () => {
    const apiBody = {
      business_id: userData.business_id,
    };

    await fetchApiStatus.run(
      httpClient("POST", "/partner/dashboard", {
        body: apiBody,
      }).then((result) => {
        let { data } = result;
        setDashboardData(data);
      })
    );
  }, [fetchApiStatus]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <div>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Dashboard
        </Typography>
      </Header>
      <Grid spacing={2} container style={{ padding: theme.spacing(3) }}>
        {/* <Grid item xs={3}>
          <Box
            style={{
              padding: "20px",
              background: "linear-gradient(to bottom,#1c80ac,#95d6ff)",
              color: "white",
              height: 100,
            }}
          >
            <Box style={{ fontSize: "20px", fontWeight: 600 }}>
              {dashboardData.new_orders}
            </Box>
            <Box style={{ fontSize: "18px", fontWeight: 600 }}>New Orders</Box>
          </Box>
        </Grid> */}
        <Grid item xs={3}>
          <Box
            style={{
              padding: "20px",
              background: portalTheme.primary,
              color: "white",
              height: 100,
            }}
          >
            <Box style={{ fontSize: "20px", fontWeight: 600 }}>
              {currency({
                value: dashboardData?.total_pos_sales,
                currency: userData.currency,
              })}
            </Box>
            <Box style={{ fontSize: "18px", fontWeight: 600 }}>Total Sales</Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            style={{
              padding: "20px",
              background: portalTheme.primary,
              color: "white",
              height: 100,
            }}
          >
            <Box style={{ fontSize: "20px", fontWeight: 600 }}>
              {dashboardData.walkin_users}
            </Box>
            <Box style={{ fontSize: "18px", fontWeight: 600 }}>
              Store Customers
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            style={{
              padding: "20px",
              background: portalTheme.primary,
              color: "white",
              height: 100,
            }}
          >
            <Box style={{ fontSize: "20px", fontWeight: 600 }}>
              {dashboardData.service_customers}
            </Box>
            <Box style={{ fontSize: "18px", fontWeight: 600 }}>
              Service Customers
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            style={{
              padding: "20px",
              background: portalTheme.primary,
              color: "white",
              height: 100,
            }}
          >
            <Box style={{ fontSize: "20px", fontWeight: 600 }}>
              {dashboardData.total_products}
            </Box>
            <Box style={{ fontSize: "18px", fontWeight: 600 }}>Products</Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
