import {
  Box,
  Button,
  DialogContentText,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Datatable from "../MuiComponent/Datatable";
import Modal from "../MuiComponent/Modal";
import TextField from "../MuiComponent/TextField";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import {
  addCountryValidation,
  addStateValidation,
} from "../validations/login-validate";
import useGeneralStyle from "../assets/css/general";
import { useDispatch, useSelector } from "react-redux";
import { selectCountries, selectStates } from "../store/selectors";
import { addState, fetchStates } from "../store/actions";

const tableHeader = [
  {
    id: "population",
    label: "NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "COUNTRY",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //     id: 'MANAGE',
  //     label: 'MANAGE',
  //     minWidth: 170,
  //     align: 'left',
  //     format: (value) => value.toFixed(2),
  // },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    marginTop: "25px",
    // width: "165vh",
    overflowX: "auto",
    backgroundColor: "white",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function StateManagement({ userType }) {
  const classes = useStyles();
  const getStatesApiStatus = useCallbackStatus();
  const [page, setPage] = React.useState(0);
  const [search, setSearchKey] = useState();
  const countries = useSelector(selectCountries);
  const { data, links, meta } = useSelector(selectStates);
  const [totalPages, setTotalPages] = React.useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addTemplate = () => {
    setModal(!modal);
  };

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      country_id: "",
    },
    onSubmit: () => {
      // if (mode == 'edit')
      //     updateCategoy()
      // if (mode == 'delete')
      //     deleteCategory()
      // else
      dispatch(addState(values));
      resetForm();
      setModal(!modal);
    },
    validationSchema: addStateValidation,
  });

  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        title="Add State"
        height="300px"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={submitForm}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={classes.inputLabel}>
                  State Name*
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  placeholder="write here"
                />
                <InputLabel className={classes.inputLabel}>Country*</InputLabel>
                <Select
                  id="country_id"
                  name="country_id"
                  value={values.country_id}
                  onChange={handleChange}
                  placeholder="Select Country"
                  onBlur={handleBlur}
                  error={touched.state && errors.country_id}
                  helperText={touched.country_id && errors.country_id}
                  className={globalClasses.selectInput}
                  variant="outlined"
                >
                  {countries.map((country) => {
                    return (
                      <MenuItem value={country.id}>{country.name}</MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error variant="outlined">
                  {touched.country_id && errors.country_id}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          State Management
        </Typography>
      </Header>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button variant="contained" color="primary" onClick={addTemplate}>
            Add State
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={false}
              getDataList={(pageSize = 10, pageNumber = 1) => {
                dispatch(
                  fetchStates({
                    page: pageNumber,
                    pageSize,
                    active: "Y",
                    search_string: search,
                    pagination_required: true,
                  })
                );
              }}
              tableHeader={tableHeader}
              totalRecords={meta?.total}
            >
              {data.map((state) => {
                return (
                  <TableRow>
                    <TableCell>{state.name}</TableCell>
                    <TableCell>{state.country_name}</TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default StateManagement;
