import React from "react";
import Modal from "../MuiComponent/Modal";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import _ from "lodash";

function HasBusinessDetailsCheck({ status, onClose }) {
  const history = useHistory();

  const ConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            Information
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Modal
      height="220px"
      width="425px"
      buttonAlign="center"
      headerComponent={ConfirmationModalHeader}
      saveBtnText="okay"
      closeBtnText="Add"
      status={status}
      closeHandler={onClose}
      saveHandler={onClose}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        You can’t add products without Business & Account Details
        <br /> please add Details first
      </Box>
    </Modal>
  );
}

export default HasBusinessDetailsCheck;
