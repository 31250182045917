import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import { useMenuCtx } from "../contexts/MenuContext";
import { drawerWidth } from "../config/constants";
import { BiChevronDown } from "react-icons/bi";
import { useAuth } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuPaper: {
    top: "50px!important",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
}));

function Header(props) {
  const menuContext = useMenuCtx();
  const classes = useStyles();
  const [popOptions, setPopOptions] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  const handleOptionsMenu = () => {
    setPopOptions(!popOptions);
  };

  return (
    <>
      <AppBar
        elevation={0}
        style={{ backgroundColor: "white", color: "black" }}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={menuContext.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {props.children}

          <Box display="flex" alignItems="center">
            <Box style={{ paddingRight: "10px" }}>
              <Avatar src={auth.getUser().profile_photo_url} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box>
                <b>{auth.getUser().name}</b>
              </Box>
              <Box>Partner Login</Box>
            </Box>
            <Box>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOptionsMenu}
                color="inherit"
              >
                <BiChevronDown />
              </IconButton>
              <Menu
                classes={{ paper: classes.menuPaper }}
                id="menu-appbar"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={popOptions}
                onClose={handleOptionsMenu}
              >
                <MenuItem
                  onClick={() => {
                    history.push("/profile/personal-profile");
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    auth.logout();
                    handleOptionsMenu();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </>
  );
}

export default Header;
