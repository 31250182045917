import { Box, Grid, IconButton, TextField, useTheme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../MuiComponent/Modal';

const columns = [
    { id: 'name', align: 'left', label: 'Registered Date', minWidth: 170 },
    {
        id: 'population',
        label: 'User Name',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'SPECIALITY/Interest',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'USER TYPE',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'detailed_profile',
        label: 'DETAILED PROFILE',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'density',
        label: 'MANAGE',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles((theme2) => ({
    root: {
        width: '100%',
        overflowX: "auto",
        backgroundColor: "white",
        padding: "10px"

    },
    heading: {
        fontWeight: 600
    },
    value: {
        color: "#63646c",
    },
    container: {
        height: "440px",
        width: "100%"
    },
    activeFilter: {
        backgroundColor: theme2.palette.primary.main,
        color: "white",
        fontWeight: 600
    }
}));


const ModalHeader = () => {
    const classes = useStyles()
    return (
        <>
            <Box display='flex' justifyContent="space-between">
                <Box flexDirection="row" justifyContent="space-between">
                    <Box className={classes.heading} style={{ fontSize: "14px" }}>
                        Client Details
                </Box>
                </Box>
                <Box flexDirection="row" textAlign="right" justifyContent="space-between">
                    <Box style={{ fontSize: "14px" }}>
                        <span className={classes.heading}>Time:</span> <span className={classes.value}>12/06/2020</span>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function DocumentCheckedDatatable({ userType }) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [modal, setModal] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const viewClient = () => {
        setModal(!modal)
    }

    const theme = useTheme()

    return (
        <>
            <Modal
                height="240px"
                width="100%"
                headerComponent={ModalHeader}
                saveBtnText="OKAY"
                saveHandler={() => { setModal(!modal) }}
                status={modal} >

            </Modal>

            <Grid container style={{ padding: theme.spacing(3) }}>
                <Grid style={{ display: "flex", alignItems: "center", padding: "10px 0px" }} item xs={5}>
                    <Box style={{ paddingRight: "20px" }}>Search</Box>
                    <TextField variant="outlined" style={{ width: "100%" }} inputProps={{ style: { padding: "12px", backgroundColor: "white" } }} placeholder="by Name" />
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        {userType}
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow hover role="checkbox" tabIndex={-1} >
                                        <TableCell>
                                            data1
                                        </TableCell>
                                        <TableCell>
                                            data1
                                        </TableCell>
                                        <TableCell>
                                            data1
                                        </TableCell>
                                        <TableCell>
                                            data1
                                        </TableCell>        
                                        <TableCell align="center">
                                            <Link style={{ color: "#2895c4" }} onClick={viewClient}>View</Link>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default DocumentCheckedDatatable
