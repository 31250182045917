import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  DialogContentText,
} from "@material-ui/core";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useFormik } from "formik";
import {
  editSliderValidation,
  addSliderValidation,
  addServiceValidation,
} from "../validations/login-validate";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Datatable from "../MuiComponent/Datatable";
import _ from "lodash";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";
import { useHistory } from "react-router-dom";
import DetailView from "./DetailView";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import moment from "moment";
import Modal from "../MuiComponent/Modal";
import { Autocomplete } from "@material-ui/lab";
import Invoice from "./invoice/Invoice";
import Composition from "./invoice/Composition";
import { useAuth } from "../providers/AuthProvider";
import { currency } from "../utils/utility";

const tableHeader = [
  { id: "name", align: "left", label: "DATE & TIME", minWidth: 170 },
  {
    id: "size",
    label: "NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "TOTAL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "VIEW",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "ACTION",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    // backgroundColor: theme2.palette.primary.main,
    color: "black",
    fontWeight: 600,
  },
}));

function ServiceBill() {
  const classes = useStyles();
  let history = useHistory();
  const auth = useAuth();
  const [page, setPage] = React.useState(0);
  const [modalStatus, setModalStatus] = useState(false);
  const [selectedData, setselectedData] = useState({});
  const [modalDel, setModalDel] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [users, setPOSUserList] = useState([]);
  const [mode, setMode] = useState("add");
  const [view, setView] = useState("false");
  const [rowData, setRowData] = useState({
    services: [],
  });
  const actionApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = React.useState(false);
  const addSliderApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const getBannersApiStatus = useCallbackStatus();
  const [filter, setFilter] = useState("pending");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [orderDetail, setOrderDetail] = useState({});

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
    }
  };

  const deleteData = async () => {
    await actionApiStatus.run(
      httpClient("DELETE", `/partner/bills/${selectedData.id}`, {
        body: {},
      }).then((result) => {
        setModalDel(false);
        fetchData();
        notification.enqueueSnackbar("Service Bill Deleted Successfully", {
          variant: "success",
        });
      })
    );
  };

  const fetchData = async (pageSize = 10, pageNumber = 1) => {
    await getBannersApiStatus.run(
      httpClient(
        "GET",
        `/partner/service-bills?page_size=${pageSize}&type=partner&page=${pageNumber}&search_string=${search}&status=${filter}&pagination_required: true&status=completed&business_id=${auth.userData.business_id}`,
        {
          body: {},
        }
      ).then((result) => {
        console.log("getData List: ", result);

        const {
          data: { data, meta },
        } = result;

        setData(data);
        setTotalPages(meta.total);
      })
    );
  };

  //   const fetchData = async (pageSize = 10, pageNumber = 1) => {
  //     const apiBody = {
  //       page: pageNumber,
  //       pageSize: pageSize,
  //       type: "admin",
  //       search_string: search,
  //     };

  //     if (apiBody.search_string === "") {
  //       delete apiBody.search_string;
  //     }

  //     await addSliderApiStatus.run(
  //       httpClient("POST", "/admin/listServices", {
  //         body: apiBody,
  //       }).then((result) => {
  //         const {
  //           data: { data, total },
  //         } = result;

  //         setPOSUserList(data);
  //         console.log("Result: ", result, data);
  //       })
  //     );
  //   };

  const editBanner = async () => {
    let fd = new FormData();
    fd.append("id", values.id);
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);

    if (typeof image == "object") fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/updateSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const deleteBanner = async () => {
    await addSliderApiStatus.run(
      httpClient("POST", "/admin/deleteSlider", {
        body: {
          id: values.id,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setConfirmation(false);
          //   getSliders();

          notification.enqueueSnackbar("Slider Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addData = async () => {
    await addSliderApiStatus.run(
      httpClient("POST", "/admin/services", {
        body: {
          name: values.name,
          type: values.type,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModalStatus(!modalStatus);

          fetchData();

          notification.enqueueSnackbar("Service Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      status: "active",
    },
    onSubmit: () => {
      //   if (mode == "edit") editBanner();
      //   else if (mode == "delete") deleteBanner();
      // else
      addData();
    },
    validationSchema:
      //   mode == "edit" || mode == "delete"
      //     ? editSliderValidation
      // :
      addServiceValidation,
  });

  //   useEffect(() => {
  //     getCategories();
  //   }, []);

  //   useEffect(() => {
  //     const delayDebounceFn = setTimeout(() => {
  //       fetchData();
  //     }, 300);

  //     return () => clearTimeout(delayDebounceFn);
  //   }, [mobileNumber]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter]);

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Bill Details
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModal(!modal);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  const ModalHeaderDel = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Delete</Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        height="340px"
        width="100%"
        headerComponent={ModalHeader}
        saveBtnText="OKAY"
        saveHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Box className={classes.activeFilter}>Name</Box>
              <Box>{rowData.name}</Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Box className={classes.activeFilter}>Labour</Box>
              <Box>
                {currency({
                  currency: auth.userData.currency,
                  value: rowData.labour,
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Box className={classes.activeFilter}>Contact Number</Box>
              <Box>{rowData.mobile_number}</Box>
            </Box>
          </Grid>
        </Grid>
        <Datatable
          getDataList={fetchData}
          totalRecords={totalPages}
          loading={getBannersApiStatus.isPending}
          tableHeader={[
            { id: "name", align: "left", label: "NAME", minWidth: 170 },
            { id: "amount", align: "left", label: "AMOUNT", minWidth: 170 },
          ]}
        >
          {rowData?.services.map((data, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{data.name}</TableCell>
                <TableCell>
                  {currency({
                    currency: auth.userData.currency,
                    value: data.amount,
                  })}
                </TableCell>
              </TableRow>
            );
          })}
        </Datatable>
      </Modal>

      <Modal
        headerComponent={ModalHeader}
        height="340px"
        // buttonAlign="center"
        width="100%"
        closeBtnText="Cancel"
        saveBtnText="Proceed"
        // loading={actionApiStatus.isPending}
        saveHandler={() => {
          submitForm();
        }}
        closeHandler={() => {
          setModalStatus(!modalStatus);
        }}
        status={modalStatus}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  inputProps={{
                    style: { padding: "12px", backgroundColor: "white" },
                  }}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Status
                </InputLabel>
                <TextField
                  id="status"
                  name="status"
                  error={touched.status && errors.status}
                  helperText={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                  inputProps={{
                    style: { padding: "12px", backgroundColor: "white" },
                  }}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Modal
        headerComponent={ModalHeaderDel}
        height="180px"
        buttonAlign="center"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        loading={actionApiStatus.isPending}
        saveHandler={() => {
          deleteData();
        }}
        closeHandler={() => {
          setModalDel(!modalDel);
        }}
        status={modalDel}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Are You sure you want to delete.</Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this category
      </ConfirmationModal>

      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Service Bills
        </Typography>
      </Header>

      {view == "false" ? (
        <>
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={5}
            >
              <Box style={{ paddingRight: "20px" }}>Search</Box>
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: { padding: "12px", backgroundColor: "white" },
                }}
                placeholder="by order ID, name, email"
              />
            </Grid>
            {/* <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                padding: "10px 0px",
              }}
              item
              xs={7}
            >
              <Button
                onClick={() => {
                  setModalStatus(true);
                }}
                variant="contained"
                color="primary"
              >
                Add Service
              </Button>
            </Grid> */}

            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Datatable
                  getDataList={fetchData}
                  totalRecords={totalPages}
                  loading={getBannersApiStatus.isPending}
                  tableHeader={tableHeader}
                >
                  {data.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          {currency({
                            currency: auth.userData.currency,
                            value: item.total,
                          })}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              setRowData(item);
                              setModal(!modal);
                            }}
                            className={globalClasses.link}
                          >
                            View
                          </a>
                        </TableCell>
                        <TableCell>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton {...bindTrigger(popupState)}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      setselectedData(item);
                                      setModalDel(true);
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Datatable>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Composition data={orderDetail} onBack={() => setView("false")} />
          {/* {JSON.stringify(orderDetail)} */}
          {/* <DetailView data={orderDetail} onBack={() => setView("false")} /> */}
        </>
      )}
    </>
  );
}

export default ServiceBill;
