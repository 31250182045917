import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  makeStyles,
  DialogContentText,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import useGeneralStyle from "../assets/css/general";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "../MuiComponent/Modal";
import { useAuth } from "../providers/AuthProvider";

const useStyles = makeStyles((theme) => ({}));

function POS() {
  const globalClasses = useGeneralStyle();
  const location = useLocation();
  const history = useHistory();
  const { userData } = useAuth();
  const theme = useTheme();
  const classes = useStyles();
  const [productList, setProductsList] = useState([]);
  const [searchProduct, setSearchProductKey] = useState("");
  let [total] = useState(0);
  const getProductsApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [productOptionsToSelect, setProductOptionsToSelect] = useState({
    options: {},
    index: 0,
  });
  const [selectedOptions, setSelectedOptions] = useState({});

  let {
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      customer_name: "",
      discount: 0,
      products: [],
    },
    onSubmit: () => {
      let products = values.products.map((product, index) => {
        return {
          ...product,
          options: selectedOptions[`${product.id}-${index}`],
          pos_price: product.pos_price_including_options,
        };
      });

      // console.log("VALUE:", {
      //   ...values,
      //   products,
      //   mobile_number: location.state.mobile_number,
      // });
      submitData({
        ...values,
        products,
        mobile_number: location.state.mobile_number,
      });
    },
    // validationSchema: addBillValidation,
    // validationSchema:
    //   mode == "edit" || mode == "delete"
    //     ? editBannerValidation
    //     : addBannerValidation,
  });

  useEffect(() => {
    setFieldValue("customer_name", location.state.name);
  }, [location.state.name]);

  const submitData = async (apiBody) => {
    await getProductsApiStatus.run(
      httpClient("POST", "/partner/addSales", {
        body: apiBody,
      }).then((result) => {
        history.push("/point-of-sale");
      })
    );
  };

  const getAllProductsList = async () => {
    const apiBody = {
      search_string: searchProduct,
      business_id: userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getProductsApiStatus.run(
      httpClient("POST", "/partner/getAllProductsData", {
        body: apiBody,
      }).then((result) => {
        console.log("Result All Products: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS All Products: ", total);
        setProductsList(data);
        // setProducts(data);
      })
    );
  };

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      getAllProductsList();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchProduct]);

  const renderError = ({ field, index }) => {
    if (!_.isUndefined(touched?.products) && !_.isUndefined(errors.products)) {
      if (!_.isUndefined(errors?.products[index]))
        return errors?.products[index][field];
    }
  };

  useEffect(() => {
    Object.keys(selectedOptions).map((product_id) => {
      let accumulated = {
        options: [],
        optionsPrice: 0,
      };

      let { optionsPrice, options } = Object.keys(
        selectedOptions[product_id]
      ).reduce((accumulated, optionName) => {
        accumulated = {
          options: [
            ...accumulated.options,
            `${optionName}: ${selectedOptions[product_id][optionName].value}`,
          ],
          optionsPrice:
            accumulated.optionsPrice +
            parseFloat(selectedOptions[product_id][optionName].offer_price),
        };

        return accumulated;
      }, accumulated);

      console.log("productsData: offer name:", optionsPrice);
      let productsData = values.products.map((product, index) => {
        console.log("product HERE:", product.tax);
        if (`${product.id}-${index}` == product_id) {
          product.options_price = optionsPrice;
          product.new_name = `${product.name} [${options.join(", ")}]`;
          let priceIncudingOption =
            parseFloat(product.pos_price) + parseFloat(optionsPrice);
          product.pos_price_including_options =
            priceIncudingOption + (priceIncudingOption * product.tax) / 100;
          // product.pos_price = parseFloat(product.base_pos_price) + optionsPrice;
        }
        return product;
      });

      console.log("productsData:", productsData);
      setFieldValue("products", [...productsData]);
      // console.log("optionsPrice:", optionsPrice);
    });
    // let price = selectedOptions[product_id].reduce((optionPrice, option) => {
    //   optionPrice = optionPrice + parseFloat(option.offer_price);
    //   return optionPrice;
    // }, optionPrice);

    console.log("UPDATED SELECTED OPTION:", selectedOptions);
  }, [selectedOptions]);

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Select Options
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModalStatus(!modalStatus);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Add Bill
        </Typography>
      </Header>
      {/* <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by order ID, name, email"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={12}
        >
          <Button onClick={submitForm} variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid> */}
      <Box
        marginTop="20px"
        style={{
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }}
      >
        <Paper style={{ padding: 20, border: "1px solid #e0e0e0" }}>
          <Grid spacing={2} container>
            <Grid item xs={6}>
              <Box lineHeight={2} fontWeight="bold">
                Customer Name
              </Box>
              <Box>
                <TextField
                  id="customer_name"
                  name="customer_name"
                  error={touched.customer_name && errors.customer_name}
                  helperText={touched.customer_name && errors.customer_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.customer_name}
                  inputProps={{
                    style: { padding: "12px", backgroundColor: "white" },
                  }}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Customer Name"
                  variant="outlined"
                />{" "}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="right">{moment().format("DD MMM YYYY")}</Box>
              <Box textAlign="right">{location.state.mobile_number}</Box>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                // classes={{ inputRoot: classes.inputRootAutoComplete }}
                id="combo-box-demo"
                options={productList}
                getOptionLabel={(option) => option.name}
                style={{ height: "12px" }}
                onChange={(event, newValue) => {
                  console.log("newValue.quantity:", newValue);
                  if (newValue?.quantity > 0) {
                    if (newValue)
                      values.products.push({
                        id: newValue.id,
                        tax: newValue.tax,
                        name: newValue.name,
                        new_name: newValue.name,
                        product_id: newValue.id,
                        discount: 0,
                        rate: 0,
                        max_quantity_allowed: newValue.quantity,
                        quantity: 1,
                        unit_cost: "",
                        options: newValue.options,
                        pos_price: newValue?.pos_price,
                        pos_price_including_options:
                          newValue?.pos_price +
                          (newValue?.pos_price * newValue.tax) / 100,
                      });

                    // selectedOptions[newValue.id] = {};
                    // setSelectedOptions({ ...selectedOptions });
                    // console.log("PRODUCTS ss: ", values.products);
                    setValues({ ...values });
                  } else alert("Not enough Qunatity");
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select Supplier"
                      onChange={(event) => {
                        setSearchProductKey(event.target.value);
                      }}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Discount (%)</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.products.map((row, index) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.new_name}
                          {/* <Box>
                            {Object.keys(selectedOptions).length > 0 &&
                              selectedOptions[`${row.id}-${index}`] &&
                              Object.keys(selectedOptions[`${row.id}-${index}`])
                                .map((key) => {
                                  return `${key}: ${
                                    selectedOptions[`${row.id}-${index}`][key]
                                      .value
                                  }`;
                                })
                                .join(", ")}
                          </Box> */}
                          <Box>
                            <a
                              className={globalClasses.link}
                              style={{ fontSize: "12px" }}
                              onClick={() => {
                                if (
                                  !selectedOptions.hasOwnProperty(
                                    `${row.id}-${index}`
                                  )
                                ) {
                                  selectedOptions[`${row.id}-${index}`] = {};
                                  setSelectedOptions({ ...selectedOptions });
                                }
                                setProductOptionsToSelect({
                                  options: row.options,
                                  index,
                                });
                                setModalStatus(!modalStatus);
                              }}
                            >
                              Select Option
                            </a>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            id="quantity"
                            name="quantity"
                            type="number"
                            error={renderError({
                              field: "quantity",
                              index: index,
                            })}
                            helperText={renderError({
                              field: "quantity",
                              index: index,
                            })}
                            onBlur={handleBlur}
                            variant="outlined"
                            inputProps={{
                              style: {
                                padding: "8px",
                                width: "50px",
                                fontSize: "14px",
                              },
                            }}
                            onChange={(event) => {
                              if (
                                event.target.value <=
                                  row.max_quantity_allowed &&
                                event.target.value > 0
                              ) {
                                setFieldValue(
                                  `products[${index}].quantity`,
                                  event.target.value
                                );
                              }
                            }}
                            value={row.quantity}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            id="pos_price_including_options"
                            name="pos_price_including_options"
                            // computePrice(
                            //   values.products[index].pos_price,
                            //   row.id
                            // )
                            value={
                              values.products[index].pos_price_including_options
                            }
                            error={renderError({
                              field: "pos_price_including_options",
                              index: index,
                            })}
                            helperText={renderError({
                              field: "pos_price_including_options",
                              index: index,
                            })}
                            onBlur={handleBlur}
                            variant="outlined"
                            inputProps={{
                              style: {
                                padding: "8px",
                                width: "50px",
                                fontSize: "14px",
                              },
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                `products[${index}].pos_price_including_options`,
                                event.target.value
                              );
                            }}
                          ></TextField>
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            id="discount"
                            name="discount"
                            value={row.discount}
                            error={renderError({
                              field: "discount",
                              index: index,
                            })}
                            helperText={renderError({
                              field: "discount",
                              index: index,
                            })}
                            onBlur={handleBlur}
                            variant="outlined"
                            inputProps={{
                              style: {
                                padding: "8px",
                                width: "50px",
                                fontSize: "14px",
                              },
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                `products[${index}].discount`,
                                event.target.value
                              );
                            }}
                          ></TextField>
                        </TableCell>
                        <TableCell align="right">
                          {(() => {
                            let discount = values.products[index].discount;
                            let discounted_amount =
                              values.products[index].quantity *
                              values.products[index]
                                .pos_price_including_options *
                              (discount / 100);

                            let price =
                              values.products[index].quantity *
                                parseFloat(values.products[index]
                                  .pos_price_including_options) -
                              discounted_amount;

                            total += price;

                            return price;
                          })()}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              values.products.splice(index, 1);
                              console.log("values.products: ", values.products);
                              setFieldValue("products", [...values.products]);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>Total</TableCell>
                      <TableCell align="right">{total}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} justify="center">
                        <Box fontWeight="bold" lineHeight={2}>
                          Discount (%)
                        </Box>
                        <Box>
                          <TextField
                            id="discount"
                            name="discount"
                            error={touched.discount && errors.discount}
                            helperText={touched.discount && errors.discount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.discount}
                            variant="outlined"
                            placeholder="Discount"
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {((total * values.discount) / 100).toFixed(2)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4}>Round off</TableCell>
                      <TableCell align="right">
                        {(() => {
                          let totalwithoutCeil =
                            total - (total * values.discount) / 100;
                          let totalWithCeil = Math.ceil(
                            total - (total * values.discount) / 100
                          );
                          return (totalWithCeil - totalwithoutCeil).toFixed(2);
                        })()}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} style={{ fontWeight: "bold" }}>
                        Total Payable
                      </TableCell>
                      <TableCell align="right">
                        {Math.ceil(total - (total * values.discount) / 100)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item style={{ textAlign: "right" }} xs={12}>
              <Button onClick={submitForm} variant="contained" color="primary">
                Print
              </Button>
            </Grid>
            {/* <Grid item xs={10}>
              Total
            </Grid>
            <Grid item xs={2}>
              Total
            </Grid>
            <Grid item xs={12}>
              Discount
            </Grid>
            <Grid item xs={12}>
              Total Payable
            </Grid> */}
          </Grid>
          {/* <Box>{location.state.mobile}</Box> */}
        </Paper>
      </Box>
      <Modal
        headerComponent={ModalHeader}
        height="280px"
        // buttonAlign="center"
        width="100%"
        closeBtnText="Cancel"
        saveBtnText="Proceed"
        // loading={actionApiStatus.isPending}
        saveHandler={() => {
          setModalStatus(!modalStatus);
        }}
        closeHandler={() => {
          setModalStatus(!modalStatus);
        }}
        status={modalStatus}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            {!_.isEmpty(productOptionsToSelect.options) &&
              Object.keys(productOptionsToSelect.options).map((key, index) => {
                return (
                  <Grid key={index} item xs={6}>
                    <InputLabel
                    // className={globalClasses.inputLabel}
                    >
                      {_.capitalize(key)}
                    </InputLabel>
                    <Select
                      name={_.capitalize(key)}
                      value={
                        selectedOptions[
                          `${productOptionsToSelect.options[key][0]?.product_id}-${productOptionsToSelect.index}`
                        ][key]?.id
                      }
                      onChange={(e) => {
                        console.log(
                          "productOptionsToSelect[key]:",
                          productOptionsToSelect.options[key]
                        );
                        let optionData = productOptionsToSelect.options[
                          key
                        ].find((option) => option.id === e.target.value);

                        console.log("OPTIOTN ADTA:", optionData);
                        selectedOptions[
                          `${optionData.product_id}-${productOptionsToSelect.index}`
                        ][optionData.name] = optionData;
                        setSelectedOptions({ ...selectedOptions });
                      }}
                      // className={globalClasses.selectInput}
                      fullWidth
                      defaultValue="none"
                      variant="outlined"
                    >
                      <MenuItem disabled value="none">
                        <em>Select</em>
                      </MenuItem>
                      {productOptionsToSelect.options[key].map(
                        (value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {_.capitalize(value.value)}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContentText>
      </Modal>
    </>
  );
}

export default POS;
