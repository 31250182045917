import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  IconButton,
  DialogContent,
  InputLabel,
  DialogContentText,
} from "@material-ui/core";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import { useFormik } from "formik";
import {
  editSliderValidation,
  addSliderValidation,
} from "../validations/login-validate";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Datatable from "../MuiComponent/Datatable";
import _ from "lodash";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";
import { useHistory } from "react-router-dom";
import DetailView from "./DetailView";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import moment from "moment";
import Modal from "../MuiComponent/Modal";
import { Autocomplete } from "@material-ui/lab";
import Invoice from "./invoice/Invoice";
import Composition from "./invoice/Composition";
import { Link } from "@material-ui/icons";
import HasBusinessDetailsCheck from "../components/HasBusinessDetailsCheck";
import { useSelector } from "react-redux";
import { useAuth } from "../providers/AuthProvider";
import { currency } from "../utils/utility";

const tableHeader = [
  { id: "name", align: "left", label: "DATE & TIME", minWidth: 170 },
  {
    id: "size",
    label: "CART ID",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "TOTAL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "size",
  //   label: "STATUS",
  //   minWidth: 170,
  //   align: "left",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function PointofSale() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const auth = useAuth();
  const [modalStatus, setModalStatus] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [users, setPOSUserList] = useState([]);
  const [mode, setMode] = useState("add");
  const [view, setView] = useState("false");
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = React.useState(false);
  const addSliderApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const getBannersApiStatus = useCallbackStatus();
  const [filter, setFilter] = useState("pending");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const [businessCheck, setBusinessCheck] = useState(false);
  const { userData } = useSelector((state) => state.general);

  const globalClasses = useGeneralStyle();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [orderDetail, setOrderDetail] = useState({});

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
    }
  };

  //   const getCategories = async () => {
  //     const apiBody = {
  //       pagination_required: true,
  //     };

  //     await getCategoryListStatus.run(
  //       httpClient("POST", "/common/category", {
  //         body: apiBody,
  //       }).then((result) => {
  //         console.log("Result: ", result);

  //         const { data } = result;

  //         setCategoriesList(data);
  //       })
  //     );
  //   };

  const getOrderList = async (pageSize = 10, pageNumber = 1) => {
    await getBannersApiStatus.run(
      httpClient(
        "GET",
        `/partner/listSales?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&status=${filter}&pagination_required: true&status=completed&business_id=${auth.userData.business_id}`,
        {
          body: {},
        }
      ).then((result) => {
        console.log("getData List: ", result);

        const {
          data: { data, meta },
        } = result;

        setData(data);
        setTotalPages(meta.total);
      })
    );
  };

  const getPOSUserList = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
      user_type: "walkin",
      business_id: auth.userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await addSliderApiStatus.run(
      httpClient("POST", "/partner/getUsers", {
        body: apiBody,
      }).then((result) => {
        const {
          data: { data, total },
        } = result;

        setPOSUserList(data);
        console.log("Result: ", result, data);
      })
    );
  };

  const editBanner = async () => {
    let fd = new FormData();
    fd.append("id", values.id);
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);

    if (typeof image == "object") fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/updateSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const deleteBanner = async () => {
    await addSliderApiStatus.run(
      httpClient("POST", "/admin/deleteSlider", {
        body: {
          id: values.id,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setConfirmation(false);
          //   getSliders();

          notification.enqueueSnackbar("Slider Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addSlider = async () => {
    let fd = new FormData();
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);
    fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/createSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      //   category_id: "",
      link: "",
      type: "App",
      image: "",
      active: "Y",
    },
    onSubmit: () => {
      if (mode == "edit") editBanner();
      else if (mode == "delete") deleteBanner();
      else addSlider();
    },
    validationSchema:
      mode == "edit" || mode == "delete"
        ? editSliderValidation
        : addSliderValidation,
  });

  //   useEffect(() => {
  //     getCategories();
  //   }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPOSUserList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [mobileNumber]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getOrderList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter]);

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Add Bill
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModalStatus(!modalStatus);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        headerComponent={ModalHeader}
        height="280px"
        // buttonAlign="center"
        width="100%"
        closeBtnText="Cancel"
        saveBtnText="Proceed"
        // loading={actionApiStatus.isPending}
        saveHandler={() => {
          history.replace("/point-of-sale/add", mobileNumber);
        }}
        closeHandler={() => {
          setModalStatus(!modalStatus);
        }}
        status={modalStatus}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Mobile Number
                </InputLabel>

                <Autocomplete
                  freeSolo
                  disablePortal
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    if (event.keyCode !== 13) {
                      setMobileNumber(newValue);
                    } else {
                      setMobileNumber(newValue);
                    }
                  }}
                  options={users}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) =>
                    option["mobile_number"] ? option["mobile_number"] : option
                  }
                  renderInput={(params) => (
                    <TextField
                      onChange={(event) => {
                        console.log("TextField Box: ", event.target.value);
                        if (event.target.value) {
                          setMobileNumber({
                            name: "",
                            mobile_number: event.target.value,
                          });
                        }

                        // dispatch(getBriks(filters));

                        // searchBooking(filters).then((result) => {
                        //   console.log("SEARCHING RESULTS: ", [...result]);
                        //   setSearchResults(result);
                        // });
                      }}
                      // value={mobileNumber}
                      // onChange={(event) => {
                      //   setMobileNumber(event.target.value);
                      // }}
                      variant="outlined"
                      placeholder="Mobile Number"
                      inputProps={{
                        style: { padding: "12px", backgroundColor: "white" },
                      }}
                      {...params}
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <>
                        <div>
                          {option.mobile_number} - ({option.name})
                        </div>
                      </>
                    );
                  }}
                />
                {/* <TextField
                    id="name"
                    name="name"
                    // error={touched.name && errors.name}
                    // helperText={touched.name && errors.name}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // value={values.name}
                    inputProps={{
                      style: { padding: "12px", backgroundColor: "white" },
                    }}
                    FormHelperTextProps={{ style: { fontSize: "10px" } }}
                    placeholder="write here"
                    variant="outlined"
                  /> */}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this category
      </ConfirmationModal>

      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Point of Sale
        </Typography>
      </Header>

      <HasBusinessDetailsCheck
        status={businessCheck}
        onClose={() => {
          if (_.isNull(userData?.businessDetails?.account_details))
            history.push("/profile/account-details");
          else history.push("/profile/business-details");
        }}
      />

      {/* <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => setFilter("pending")}
            className={clsx({
              [classes.activeFilter]: filter === "pending",
            })}
            variant="contained"
          >
            PENDING
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({ [classes.activeFilter]: filter === "approved" })}
            onClick={() => setFilter("approved")}
          >
            APPROVED
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({ [classes.activeFilter]: filter === "rejected" })}
            onClick={() => setFilter("rejected")}
          >
            REJECTED
          </Button>
        </Box>
      </Box> */}
      {view == "false" ? (
        <>
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={5}
            >
              <Box style={{ paddingRight: "20px" }}>Search</Box>
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: { padding: "12px", backgroundColor: "white" },
                }}
                placeholder="by order ID, name, email"
              />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                padding: "10px 0px",
              }}
              item
              xs={7}
            >
              <Button
                onClick={() => {
                  if (
                    _.isNull(userData.businessDetails) ||
                    _.isNull(userData?.businessDetails?.account_details)
                  )
                    setBusinessCheck(true);
                  else {
                    setModalStatus(true);
                  }
                }}
                variant="contained"
                color="primary"
              >
                Add Sale
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Datatable
                  getDataList={getOrderList}
                  totalRecords={totalPages}
                  loading={getBannersApiStatus.isPending}
                  tableHeader={tableHeader}
                >
                  {data.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {moment(item.created_at).format(
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </TableCell>
                        <TableCell>{item.cart_id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          {currency({
                            currency: userData.currency,
                            value: item.total,
                          })}
                        </TableCell>
                        <TableCell>
                          <a
                            className={globalClasses.link}
                            onClick={() => {
                              setOrderDetail(item);
                              setView("true");
                            }}
                          >
                            View
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Datatable>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Composition data={orderDetail} onBack={() => setView("false")} />
          {/* {JSON.stringify(orderDetail)} */}
          {/* <DetailView data={orderDetail} onBack={() => setView("false")} /> */}
        </>
      )}
    </>
  );
}

export default PointofSale;
