import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  Menu,
  Typography,
  TableRow,
  TableCell,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  FormHelperText,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Modal from "../MuiComponent/Modal";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import Datatable from "../MuiComponent/Datatable";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { httpClient } from "../config/httpClient";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useFormik } from "formik";
import { addCityValidation } from "../validations/login-validate";
import useGeneralStyle from "../assets/css/general";
import { useSnackbar } from "notistack";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";

const tableHeader = [
  {
    id: "population",
    label: "NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "COUNTRY",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MANAGE",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    marginTop: "25px",
    width: "165vh",
    overflowX: "auto",
    backgroundColor: "white",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function CityManagement({ userType }) {
  const classes = useStyles();
  const globalClasses = useGeneralStyle();
  const [mode, setMode] = useState("add");
  const [confirmation, setConfirmation] = React.useState(false);
  const getStatesApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const addCityApiStatus = useCallbackStatus();
  const [page, setPage] = React.useState(0);
  const [search, setSearchKey] = useState();
  const [stateList, setStateList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modal, setModal] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getCities = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
      pagination_required: true,
      active: "Y",
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getStatesApiStatus.run(
      httpClient("POST", "/admin/addUpdateGetCity", {
        body: apiBody,
      }).then((result) => {
        const {
          data: {
            data,
            meta: { total },
          },
        } = result;
        console.log("Get Cities Result: ", data);

        setCitiesList(data);
        setTotalPages(total);
      })
    );
  };

  const getStates = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
      pagination_required: false,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getStatesApiStatus.run(
      httpClient("POST", "/common/getStates", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);

        const {
          data: { data },
        } = result;

        setStateList(data);
      })
    );
  };

  const deleteCity = async () => {
    await addCityApiStatus.run(
      httpClient("POST", "/admin/deleteCity", {
        body: {
          id: values.id,
        },
      })
        .then((result) => {
          console.log("City Deleted Result: ", result);
          resetForm();
          setConfirmation(false);
          getCities();

          notification.enqueueSnackbar("City Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addCity = async () => {
    let apiBody = {
      ...values,
      active: "Y",
    };

    await addCityApiStatus.run(
      httpClient("POST", "/admin/addUpdateGetCity", {
        body: apiBody,
      })
        .then((result) => {
          console.log("City Added Result: ", result);
          resetForm();
          setModal(false);

          getCities();

          notification.enqueueSnackbar("Category Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getCities();
  }, [search]);

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      state_id: "",
    },
    onSubmit: () => {
      if (mode == "delete") deleteCity();
      else addCity();
    },
    validationSchema: addCityValidation,
  });

  const theme = useTheme();

  return (
    <>
      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this category
      </ConfirmationModal>

      <Modal
        title="Add City"
        height="300px"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={submitForm}
        closeHandler={() => {
          resetForm();
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={classes.inputLabel}>
                  City Name*
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  placeholder="write here"
                />

                <InputLabel className={classes.inputLabel}>State*</InputLabel>
                <Select
                  id="state_id"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.state && errors.state_id}
                  helperText={touched.state_id && errors.state_id}
                  className={globalClasses.selectInput}
                  variant="outlined"
                >
                  {stateList.map((state) => {
                    return (
                      <MenuItem value={state.id}>
                        {state.name} ({state.country_name})
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error variant="outlined">
                  {touched.state_id && errors.state_id}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          City Management
        </Typography>
      </Header>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMode("add");
              resetForm();
              setModal(!modal);
            }}
          >
            Add City
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getStatesApiStatus.isPending}
              getDataList={getStates}
              totalPages={totalPages}
              tableHeader={tableHeader}
            >
              {citiesList.map((city) => {
                return (
                  <TableRow>
                    <TableCell>{city.name}</TableCell>
                    <TableCell>
                      {city.state} ({city.country})
                    </TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: city.id,
                                    name: city.name,
                                    state_id: city.state_id,
                                  });
                                  setMode("edit");
                                  setModal(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: city.id,
                                    name: city.name,
                                    state_id: city.state_id,
                                  });
                                  setMode("delete");
                                  setConfirmation(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default CityManagement;
