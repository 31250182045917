export const SET_USER = "SET_USER";
export const IS_COMPONENT_BUSY = "IS_COMPONENT_BUSY";

export const SET_PARENT_CATEGORIES = "SET_PARENT_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GENERAL_API_CALL= "GENERAL_API_CALL";
export const FETCH_STATE_DATA="FETCH_STATE_DATA";
export const SET_REDUCER_DATA="SET_REDUCER_DATA";
export const GENERAL_API_CALL_DATA="GENERAL_API_CALL_DATA";
export const FETCH_CITIES_DATA="FETCH_CITIES_DATA";
export const FETCH_COUNTRY_DATA="FETCH_COUNTRY_DATA";

export const PERMISSIONS = {
  USER_MANAGEMENT: "User Management",
  TASK_MANAGEMENT: "Task Management",
  SERVICE_BILLS: "Service Bills",
  SERVICE_MANAGEMENT: "Service Management",
  ROLE_ACCESS: "Role Access",
  CATEGORY_MANAGEMENT: "Category Management",
  PRODUCT_MANAGEMENT: "Product Management",
  POINT_OF_SALE: "Point of Sale",
  REVIEW_RATING: "Reviews & Ratings",
  NEWSLETTER_INQUIREY: "Newsletters & Inquiries",
  PURCHASE_MANAGEMENT: "Purchase Management",
  BANNER_MANAGEMENT: "Banner Management",
  SLIDER_MANAGEMENT: "Slider Management",
  ORDER_MANAGEMENT: "Order Management",
  TRANSACTION_MANAGEMENT: "Transaction Management",
  CMS_MANAGEMENT: "CMS Management",
  COUPON_MANAGEMENT: "Coupon Management",
  SETTINGS: "Settings",
  STAFF_MANAGEMENT: "Service Staff Management",
  DASHBOARD: "Dashboard",
};
