import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import useGeneralStyle from "../../assets/css/general";
import Datatable from "../../MuiComponent/Datatable";
import Modal from "../../MuiComponent/Modal";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { suplliersValidation } from "../../validations/login-validate";
import moment from "moment";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useAuth } from "../../providers/AuthProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { selectCities, selectCountries, selectStates } from "../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchStates } from "../../store/actions";
import TextField from "../../MuiComponent/TextField";

const tableHeader = [
  {
    id: "date",
    label: "Registered Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "User Name",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "emaill",
    label: "EMAIL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "profile",
    label: "Detailed Profile",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "MANAGE",
    label: "Manage",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  inputRootAutoComplete: {
    height: "43px",
    padding: "0px 8px !important",
  },
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  formControl: {
    margin: theme2.spacing(1),
    minWidth: 240,
  },
  formControlMenu: {
    margin: theme2.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme2.spacing(2),
  },
  input: {
    padding: "11px 14px",
  },
}));

function SuppliersDatatable() {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const countries = useSelector(selectCountries);
  const statesList = useSelector(selectStates);
  const citiesList = useSelector(selectCities);

  const globalClasses = useGeneralStyle();
  const suplliersApiStatus = useCallbackStatus();
  const getSupllierApiStatus = useCallbackStatus();

  const dispatch = useDispatch();
  const [search, setSearchKey] = useState(null);
  const [modal, setModal] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [mode, setMode] = useState("add");

  let {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    setValues,
    submitForm,
  } = useFormik({
    initialValues: {
      business_name: "",
      name: "",
      contact_number: "",
      alternate_number: "",
      gst_number: "",
      address: "",
      city_id: "",
      state_id: "",
      country_id: "",
    },
    onSubmit: () => {
      if (mode === "edit") {
        updatesupllier();
        //   } else if (mode == "delete") {
        //     deleteCategory();
      } else addSupplier();
    },
    validationSchema: suplliersValidation,
  });

  const addSupplier = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/partner/addSupplier", {
        body: values,
      })
        .then((result) => {
          fetchsuppliers();
          setModal(false);
          console.log("result", result);
        })
        .catch((er) => {
          console.log("Error", errors);
        })
    );
  };

  const fetchsuppliers = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        pagination_required: true,
        search_string: search,
        business_id: auth.userData.business_id,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await getSupllierApiStatus.run(
        httpClient("POST", "/partner/fetchSuppliers", {
          body: apiBody,
        })
          .then((result) => {
            console.log("Result", result);

            const {
              data: { data, meta },
            } = result;

            setSupplierData(data);
            setTotalPages(meta.total);
          })
          .catch((er) => {
            console.log("Error", er);
          })
      );
    },
    [getSupllierApiStatus, search]
  );

  const updatesupllier = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/partner/updateSupplier", {
        body: values,
      })
        .then((result) => {
          console.log("Result", result);
          fetchsuppliers();
          setModal(false);
        })
        .catch((er) => {
          console.log("Error", er);
        })
    );
  };

  const addNewCategory = () => {
    setMode("add");
    setModal(false);
  };

  useEffect(() => {
    if (search != null) fetchsuppliers();
  }, [search]);

  const ModalHeader = () => {
    return <Box padding={1}>{mode === "edit" ? "Edit" : "Add"} Suppliers</Box>;
  };

  return (
    <>
      <Modal
        height="425px"
        width="650px"
        loading={suplliersApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Save"
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(false);
        }}
        saveHandler={() => {
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Business Name
                </InputLabel>
                <TextField
                  id="business_name"
                  name="business_name"
                  error={touched.business_name && errors.business_name}
                  helperText={touched.business_name && errors.business_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.business_name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Business Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Contact Number
                </InputLabel>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  error={touched.contact_number && errors.contact_number}
                  helperText={touched.contact_number && errors.contact_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Contact Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Alternate Number
                </InputLabel>
                <TextField
                  id="alternate_number"
                  name="alternate_number"
                  error={touched.alternate_number && errors.alternate_number}
                  helperText={
                    touched.alternate_number && errors.alternate_number
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.alternate_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Alternate Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  GST Number
                </InputLabel>
                <TextField
                  id="gst_number"
                  name="gst_number"
                  error={touched.gst_number && errors.gst_number}
                  helperText={touched.gst_number && errors.gst_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gst_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="GST Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Address
                </InputLabel>
                <TextField
                  id="address"
                  name="address"
                  error={touched.address && errors.address}
                  helperText={touched.address && errors.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Address"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Country
                </InputLabel>
                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={countries.find((country) => country?.id == values?.country_id)}
                  value={countries.find((country) => country?.id == values?.country_id)}
                  options={countries}
                  onChange={(event, newValue) => {
                    setFieldValue("country_id", newValue?.id);
                    dispatch(
                      fetchStates({
                        country_id: newValue?.id,
                      })
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select Country"
                        error={touched?.country_id && errors?.country_id}
                        helperText={touched?.country_id && errors?.country_id}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  State
                </InputLabel>
                {/* {values.state && ( */}
                  <Autocomplete
                    classes={{ inputRoot: classes.inputRootAutoComplete }}
                    id="combo-box-demo"
                    defaultValue={statesList.find((state) => state.id == values.state_id)}
                    value={statesList.find((state) => state.id == values.state_id)}
                    options={statesList}
                    onChange={(event, newValue) => {
                      setFieldValue("state_id", newValue?.id);
                      dispatch(
                        fetchCities({
                          state_id: newValue?.id,
                        })
                      );
                    }}
                    getOptionLabel={(option) => option.name}
                    style={{ height: "50px" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Select State"
                          error={touched.state_id && errors.state_id}
                          helperText={touched.state_id && errors.state_id}
                          onChange={(event) => {
                            //setSearchKey(event.target.value);
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                {/* )} */}
              </Box>
              {/* <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  State
                </InputLabel>
                <TextField
                  id="state_id"
                  name="state_id"
                  error={touched.state_id && errors.state_id}
                  helperText={touched.state_id && errors.state_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state_id}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="state_id"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box> */}
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  City
                </InputLabel>
                {/* {values.city !== null && ( */}
                  <Autocomplete
                    classes={{ inputRoot: classes.inputRootAutoComplete }}
                    id="combo-box-demo"
                    defaultValue={citiesList.find((city) => city.id == values?.city_id)}
                    value={citiesList.find((city) => city.id == values?.city_id)}
                    options={citiesList}
                    onChange={(event, newValue) => {
                      setFieldValue("city_id", newValue?.id);
                    }}
                    getOptionLabel={(option) => option.name}
                    style={{ height: "50px" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Select State"
                          error={touched.city_id && errors?.city_id}
                          helperText={touched.city_id && errors?.city_id}
                          onChange={(event) => {
                            //setSearchKey(event.target.value);
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                {/* )} */}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={6}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
            style={{ width: "53%" }}
            placeholder="by Name"
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
          />
        </Grid>

        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={6}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ width: "30%" }}
            onClick={() => {
              addNewCategory();
              setModal(true);
              setMode("add");
            }}
          >
            Add Supplier
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getSupllierApiStatus.isPending}
              getDataList={fetchsuppliers}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {supplierData.map((data) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>
                        {moment(data.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{data.business_name}</TableCell>
                      <TableCell>{data.contact_number}</TableCell>
                      <TableCell>{data.address}</TableCell>
                      <TableCell>
                        {" "}
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    setValues({
                                      id: data.id,
                                      business_name: data.business_name,
                                      name: data.name,
                                      contact_number: data.contact_number,
                                      alternate_number: data.alternate_number,
                                      gst_number: data.gst_number,
                                      address: data.address,
                                      city_id: data.city_id,
                                      state_id: data.state_id,
                                      country_id: data.country_id,
                                    });
                                    setMode("edit");
                                    setModal(true);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setValues({
                                      //   email: userData.email,
                                      // role: userData.role,
                                    });
                                    // setMode("delete");
                                    // setConfirmation(true);
                                    // popupState.setOpen(false);
                                  }}
                                >
                                  {/* Revoke */}
                                  Delete
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default SuppliersDatatable;
