import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Chip,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  useTheme,
  Select,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import useGeneralStyle from "../../assets/css/general";
import Datatable from "../../MuiComponent/Datatable";
import Modal from "../../MuiComponent/Modal";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import moment from "moment";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useAuth } from "../../providers/AuthProvider";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  selectCities,
  selectCountries,
  selectStates,
} from "../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchStates } from "../../store/actions";
import TextField from "../../MuiComponent/TextField";
import {
  inviteAdminValidation,
  managePolicyValidate,
  partnersValidation,
} from "../../validations/login-validate";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import clsx from "clsx";
import ConfirmationModal from "../../MuiComponent/ConfirmationModal";

const tableHeader = [
  {
    id: "date",
    label: "Registered Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Email",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "emaill",
    label: "Role",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MANAGE",
    label: "Manage",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  inputRootAutoComplete: {
    height: "43px",
    padding: "0px 8px !important",
  },
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  formControl: {
    margin: theme2.spacing(1),
    minWidth: 240,
  },
  formControlMenu: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme2.spacing(2),
  },
  input: {
    padding: "11px 14px",
  },
}));

function PartnersDatatable() {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const countries = useSelector(selectCountries);
  const statesList = useSelector(selectStates);
  const citiesList = useSelector(selectCities);
  const managePolicyApiStatus = useCallbackStatus();
  const policyListApiStatus = useCallbackStatus();
  const notification = useSnackbar();

  const globalClasses = useGeneralStyle();
  const suplliersApiStatus = useCallbackStatus();
  const getSupllierApiStatus = useCallbackStatus();
  const [confirmation, setConfirmation] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearchKey] = useState(null);
  const [modal, setModal] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [filter, setFilter] = React.useState("pending");

  const [mode, setMode] = useState("add");
  const [policiesModal, setPoliciesModal] = useState(false);
  const [policiesList, setPoliciesList] = useState([]);
  const [rolesList, setRoleAccessList] = useState([]);
  const [userObj, setUserObject] = useState({});

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      role: "none",
    },
    onSubmit: () => {
      adminInvite();
    },
    validationSchema: inviteAdminValidation,
  });

  const managePolicy = async () => {
    let newArr = manageFormik.values.policy.map((item) => item.id);
    console.log("userObj:", userObj);
    let apiBody = {
      user_id: userObj.id,
      type: "partner",
      permissions: newArr,
      roles: manageFormik.values.roles.map((item) => item.name),
    };

    await managePolicyApiStatus.run(
      httpClient("POST", "admin/syncPemissionAndRoleToUser", {
        body: apiBody,
      })
        .then((result) => {
          fetchData();
          setPoliciesModal(false);
        })
        .catch((err) => {
          // if (err && err.code === 403) {
          //   auth.logout();
          // } else {
          notification.enqueueSnackbar(err.message, {
            variant: "err",
            autoHideDuration: 2000,
          });
          // }
        })
    );
  };

  const addEntry = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/partner/register", {
        body: values,
      })
        .then((result) => {
          fetchData();
          setModal(false);
          console.log("result", result);
        })
        .catch((er) => {
          console.log("Error", errors);
        })
    );
  };

  const adminResend = async (apiBody) => {
    await suplliersApiStatus.run(
      httpClient("POST", "/admin/invite-partner", {
        body: apiBody,
      })
        .then((result) => {
          console.log("admin Invite", result);
        })
        .catch((er) => {
          console.log("ERROR Invite", er);
        })
    );
  };

  const getRoleAccessList = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        pagination_required: false,
        search_string: search,
        guard: "partner",
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await policyListApiStatus.run(
        httpClient("POST", "/admin/getAllRoles", {
          body: apiBody,
        }).then((result) => {
          console.log("Result: ", result);

          const {
            data: { data, meta },
          } = result;

          setRoleAccessList(data);
        })
      );
    },
    [search]
  );

  let getPoliciesList = useCallback(async () => {
    await policyListApiStatus.run(
      httpClient("POST", "admin/getAllPermissions", {
        body: {
          guard: "partner",
        },
      })
        .then((result) => {
          const { data } = result;

          setPoliciesList(data);
        })
        .catch((error) => {
          notification.enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  }, [notification, policyListApiStatus]);

  const fetchData = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        pagination_required: true,
        search_string: search,
        business_id: auth.userData.business_id,
        active: filter,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await getSupllierApiStatus.run(
        httpClient(
          "POST",
          `/${process.env.REACT_APP_ACCESS_MODE}/fetchPartners`,
          {
            body: apiBody,
          }
        )
          .then((result) => {
            console.log("Result", result);

            const {
              data: { data, meta },
            } = result;

            setSupplierData(data);
            setTotalPages(meta.total);
          })
          .catch((er) => {
            console.log("Error", er);
          })
      );
    },
    [getSupllierApiStatus, search]
  );

  const onDeletePolicy = (item) => {
    manageFormik.setFieldValue(
      "policy",
      manageFormik.values.policy.filter((policy) => policy.id != item.id)
    );
  };

  const updatesupllier = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/partner/updateSupplier", {
        body: values,
      })
        .then((result) => {
          console.log("Result", result);
          fetchData();
          setModal(false);
        })
        .catch((er) => {
          console.log("Error", er);
        })
    );
  };

  const addRecord = () => {
    setMode("add");
    setModal(false);
  };

  useEffect(() => {
    fetchData();
  }, [search, filter]);

  useEffect(() => {
    getRoleAccessList();
    getPoliciesList();
  }, []);

  const ModalHeader = () => {
    return <Box padding={1}>{mode === "edit" ? "Edit" : "Add"} Partner</Box>;
  };

  const PoliciesModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Manage Policies
          </Box>
          <Button
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setPoliciesModal(!policiesModal);
              manageFormik.setValues(null);
              manageFormik.resetForm();
            }}
          >
            <CloseIcon size="large" style={{ color: "white" }} />
          </Button>
        </Box>
      </>
    );
  };

  const adminInvite = async () => {
    const apiBody = {
      ...values,
      type: "new_invitation",
    };
    await suplliersApiStatus.run(
      httpClient("POST", "/admin/invite-partner", {
        body: apiBody,
      })
        .then((result) => {
          setModal(false);
          fetchData();
        })
        .catch((er) => {
          setErrors(er.errors);
        })
    );
  };

  const handleStateChange = (_, newValue) => {
    manageFormik.setFieldValue("policy", newValue);
  };

  const manageFormik = useFormik({
    initialValues: {
      roles: [],
      policy: [],
    },
    validationSchema: managePolicyValidate,

    onSubmit: () => {
      // manageStatus("add", values.title);
      managePolicy();
      manageFormik.resetForm();
    },
  });

  const adminRevoke = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/admin/revokeInvitation", {
        body: {
          ...values,
          type: "partner",
        },
      })
        .then((result) => {
          setConfirmation(false);
          fetchData();
          console.log("admin Revoke", result);
          notification.enqueueSnackbar("Revoke Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("ERROR Revoke", er);
        })
    );
  };

  return (
    <>
      <ConfirmationModal
        loading={suplliersApiStatus.isPending}
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Revoke"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => adminRevoke()}
      >
        Are you sure want to revoke this invitation
      </ConfirmationModal>

      <Modal
        height="400px"
        width="100%"
        headerComponent={PoliciesModalHeader}
        saveBtnText="Save"
        loading={managePolicyApiStatus.isPending}
        closeBtnText="Discard"
        closeHandler={() => {
          setPoliciesModal(!policiesModal);
          manageFormik.resetForm();
        }}
        saveHandler={() => {
          manageFormik.submitForm();
        }}
        status={policiesModal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                {/* <InputLabel className={globalClasses.inputLabel}>
                  Role Title
                </InputLabel>
                <TextField
                  id="role"
                  name="role"
                  // error={manageFormik.touched.role && manageFormik.errors.role}
                  // helperText={
                  //   manageFormik.touched.role && manageFormik.errors.role
                  // }
                  // defaultValue={userObj.name}
                  value={userObj.name}
                  // onChange={manageFormik.handleChange}
                  // onBlur={manageFormik.handleBlur}
                  // value={manageFormik.values.role}
                  // FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  // placeholder="Write here"
                  variant="outlined"
                /> */}
                <InputLabel
                  className={globalClasses.inputLabel}
                  style={{ marginTop: "20px" }}
                >
                  Roles
                </InputLabel>
                <div>
                  <Autocomplete
                    multiple
                    // getOptionDisabled={(options) =>
                    //   values.policy.length == 3 ? true : false
                    // }
                    noOptionsText={<Box>No results found</Box>}
                    defaultValue={manageFormik.values.roles}
                    value={manageFormik.values.roles}
                    id="roles"
                    name="roles"
                    // id="tags-outlined"
                    // limitTags={3}`
                    options={rolesList.filter(
                      (elem) =>
                        !manageFormik.values.roles.find(
                          ({ id }) => elem.id === id
                        )
                    )}
                    onChange={(_, newValue) => {
                      manageFormik.setFieldValue("roles", newValue);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    // classes={{
                    //   popupIndicator: classes.popupIndicator,
                    //   clearIndicator: classes.clearIndicator,
                    //   tag: classes.tag,
                    //   input: classes.inputRoot,
                    // }}
                    ChipProps={{
                      size: "small",
                      className: classes.languageChip,
                    }}
                    renderTags={() => {}}
                    size="small"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Box>{option.name}</Box>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        helperText={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        variant="outlined"
                        className="hoverhide"
                        // style={{
                        //   backgroundColor: "#303652",
                        //   color: "white",
                        //   height: "40px",
                        //   // background: "#212543",
                        // }}
                        placeholder="Select Roles"
                      />
                    )}
                  />
                  <Box>
                    {manageFormik.values.roles.map((item, index) => (
                      <Chip
                        style={{
                          color: "black",
                          marginRight: "8px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "6px",
                          // border: "1px solid #E27851",
                          // backgroundColor: "#251E40",
                          color: "black",
                        }}
                        // style={{
                        //   height: "30px",
                        //   margin: "5px",
                        //   cursor: "default",
                        // }}
                        variant="outlined"
                        onDelete={() => {
                          manageFormik.setFieldValue(
                            "roles",
                            manageFormik.values.roles.filter(
                              (role) => role.id != item.id
                            )
                          );
                        }}
                        label={item.name}
                        deleteIcon={<CloseIcon style={{ height: "11px" }} />}
                      />
                    ))}
                  </Box>
                </div>

                <InputLabel
                  className={globalClasses.inputLabel}
                  style={{ marginTop: "20px" }}
                >
                  Policies
                </InputLabel>
                <div>
                  <Autocomplete
                    multiple
                    // getOptionDisabled={(options) =>
                    //   values.policy.length == 3 ? true : false
                    // }
                    noOptionsText={<Box>No results found</Box>}
                    defaultValue={manageFormik.values.policy}
                    value={manageFormik.values.policy}
                    id="policy"
                    name="policy"
                    // id="tags-outlined"
                    // limitTags={3}`
                    options={policiesList.filter(
                      (elem) =>
                        !manageFormik.values.policy.find(
                          ({ id }) => elem.id === id
                        )
                    )}
                    onChange={handleStateChange}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    // classes={{
                    //   popupIndicator: classes.popupIndicator,
                    //   clearIndicator: classes.clearIndicator,
                    //   tag: classes.tag,
                    //   input: classes.inputRoot,
                    // }}
                    ChipProps={{
                      size: "small",
                      className: classes.languageChip,
                    }}
                    renderTags={() => {}}
                    size="small"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Box>{option.name}</Box>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        helperText={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        variant="outlined"
                        className="hoverhide"
                        // style={{
                        //   backgroundColor: "#303652",
                        //   color: "white",
                        //   height: "40px",
                        //   // background: "#212543",
                        // }}
                        placeholder="Select Policy"
                      />
                    )}
                  />
                  <Box>
                    {manageFormik.values.policy.map((item, index) => (
                      <Chip
                        style={{
                          color: "black",
                          marginRight: "8px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "6px",
                          // border: "1px solid #E27851",
                          // backgroundColor: "#251E40",
                          color: "black",
                        }}
                        // style={{
                        //   height: "30px",
                        //   margin: "5px",
                        //   cursor: "default",
                        // }}
                        variant="outlined"
                        onDelete={() => {
                          onDeletePolicy(item);
                        }}
                        label={item.name}
                        deleteIcon={<CloseIcon style={{ height: "11px" }} />}
                      />
                    ))}
                  </Box>
                </div>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {/* <Modal
        height="425px"
        width="650px"
        loading={suplliersApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Save"
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(false);
        }}
        saveHandler={() => {
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Lastname
                </InputLabel>
                <TextField
                  id="last_name"
                  name="last_name"
                  error={touched.last_name && errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Contact Number
                </InputLabel>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  error={touched.contact_number && errors.contact_number}
                  helperText={touched.contact_number && errors.contact_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Contact Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Email
                </InputLabel>
                <TextField
                  id="email"
                  name="email"
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Contact Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Password
                </InputLabel>
                <TextField
                  id="password"
                  name="password"
                  error={touched.password && errors.password}
                  helperText={touched.password && errors.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Contact Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Address
                </InputLabel>
                <TextField
                  id="address"
                  name="address"
                  error={touched.address && errors.address}
                  helperText={touched.address && errors.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Address"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Country
                </InputLabel>
                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={countries.find(
                    (country) => country?.id == values?.country
                  )}
                  value={countries.find(
                    (country) => country?.id == values?.country
                  )}
                  options={countries}
                  onChange={(event, newValue) => {
                    setFieldValue("country", newValue?.id);
                    dispatch(
                      fetchStates({
                        country: newValue?.id,
                      })
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select Country"
                        error={touched?.country && errors?.country}
                        helperText={touched?.country && errors?.country}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  State
                </InputLabel>
                
                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={statesList.find(
                    (state) => state.id == values.state
                  )}
                  value={statesList.find((state) => state.id == values.state)}
                  options={statesList}
                  onChange={(event, newValue) => {
                    setFieldValue("state", newValue?.id);
                    dispatch(
                      fetchCities({
                        state: newValue?.id,
                      })
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select State"
                        error={touched.state && errors.state}
                        helperText={touched.state && errors.state}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />
               
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  City
                </InputLabel>
            
                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={citiesList.find(
                    (city) => city.id == values?.city
                  )}
                  value={citiesList.find((city) => city.id == values?.city)}
                  options={citiesList}
                  onChange={(event, newValue) => {
                    setFieldValue("city", newValue?.id);
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select State"
                        error={touched.city && errors?.city}
                        helperText={touched.city && errors?.city}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal> */}

      <Modal
        height="350px"
        width="100%"
        loading={suplliersApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Save"
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(false);
        }}
        saveHandler={() => {
          // adminInvite();
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Email
                </InputLabel>
                <TextField
                  id="email"
                  name="email"
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{ paddingRight: "20px" }}
                className={globalClasses.inputLabel}
              >
                Role
              </Box>
              <FormControl
                variant="outlined"
                className={classes.formControlMenu}
              >
                <Select
                  input={<OutlinedInput classes={{ input: classes.input }} />}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.role}
                  onChange={(event) => {
                    setFieldValue("role", event.target.value);
                  }}
                  label="selected"
                >
                  <MenuItem value="none">
                    <em>Select</em>
                  </MenuItem>
                  {rolesList.map((role) => {
                    return <MenuItem value={role.name}>{role.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              // setTab("pending");
              setFilter("pending");
            }}
            className={clsx({
              [classes.activeFilter]: filter === "pending",
            })}
          >
            PENDING
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              // setTab("register");
              setFilter("active");
            }}
            className={clsx({
              [classes.activeFilter]: filter === "active",
            })}
          >
            REGISTERED
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              // setTab("suspended");
              setFilter("inactive");
            }}
            className={clsx({
              [classes.activeFilter]: filter === "inactive",
            })}
          >
            SUSPENDED
          </Button>
        </Box>
      </Box>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={6}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
            style={{ width: "53%" }}
            placeholder="by Name"
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
          />
        </Grid>

        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={6}
        >
          {/* <Button
            variant="contained"
            color="primary"
            style={{ width: "30%" }}
            onClick={() => {
              addRecord();
              setModal(true);
              setMode("add");
            }}
          >
            Add Partner
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            style={{ width: "30%" }}
            onClick={() => {
              setMode("add");
              setModal(!modal);
              resetForm();
            }}
          >
            INVITE
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getSupllierApiStatus.isPending}
              getDataList={fetchData}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {supplierData.map((data) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>
                        {moment(data.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell>{data.role}</TableCell>
                      {filter == "pending" ? (
                        <TableCell>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton {...bindTrigger(popupState)}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      adminResend({
                                        email: data.email,
                                        role: data.role,
                                        type: "resend_invitation",
                                      });
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Resend
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setValues({
                                        email: data.email,
                                        // role: userData.role,
                                      });
                                      setMode("delete");
                                      setConfirmation(true);
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Revoke
                                  </MenuItem>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton {...bindTrigger(popupState)}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  {/* <MenuItem
                                    onClick={() => {
                                      setValues({
                                        id: data.id,
                                        business_name: data.business_name,
                                        name: data.name,
                                        last_name: data.last_name,
                                        contact_number: data.contact_number,
                                        email: data.email,
                                        alternate_number: data.alternate_number,
                                        gst_number: data.gst_number,
                                        address: data.address,
                                        city: data.city,
                                        state: data.state,
                                        country: data.country,
                                      });
                                      setMode("edit");
                                      setModal(true);
                                    }}
                                  >
                                    Edit
                                  </MenuItem> */}
                                  <MenuItem
                                    onClick={() => {
                                      setValues({
                                        //   email: userData.email,
                                        // role: userData.role,
                                      });
                                      // setMode("delete");
                                      // setConfirmation(true);
                                      // popupState.setOpen(false);
                                    }}
                                  >
                                    {data.status == "active"
                                      ? "Suspend"
                                      : "Unsuspend"}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setUserObject(data);

                                      manageFormik.setFieldValue(
                                        "policy",
                                        data.direct_permissions
                                      );

                                      manageFormik.setFieldValue(
                                        "roles",
                                        data.roles
                                      );

                                      setPoliciesModal(true);
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Roles & Permissions
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      // setUserObject(data);

                                      // manageFormik.setFieldValue(
                                      //   "policy",
                                      //   data.direct_permissions
                                      // );

                                      // manageFormik.setFieldValue(
                                      //   "roles",
                                      //   data.roles
                                      // );

                                      // setPoliciesModal(true);
                                      if (data.business_id) {
                                        history.push(
                                          `/users/partners/${data.business_id}/form`
                                        );
                                      } else {
                                        notification.enqueueSnackbar(
                                          "Business is not registered by partner",
                                          {
                                            variant: "error",
                                            autoHideDuration: 2000,
                                          }
                                        );
                                      }

                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Customised Form
                                  </MenuItem>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default PartnersDatatable;

// import {
//   Box,
//   Button,
//   DialogContentText,
//   Grid,
//   IconButton,
//   InputLabel,
//   Menu,
//   MenuItem,
//   OutlinedInput,
//   Select,
//   TableCell,
//   TableRow,
//   useTheme,
// } from "@material-ui/core";
// import FormControl from "@material-ui/core/FormControl";
// import Paper from "@material-ui/core/Paper";
// import { makeStyles } from "@material-ui/core/styles";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import clsx from "clsx";
// import { useFormik } from "formik";
// import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
// import moment from "moment";
// import { useSnackbar } from "notistack";
// import React, { useCallback, useEffect, useState } from "react";
// import ConfirmationModal from "../../MuiComponent/ConfirmationModal";
// import Datatable from "../../MuiComponent/Datatable";
// import Modal from "../../MuiComponent/Modal";
// import TextField from "../../MuiComponent/TextField";
// import useGeneralStyle from "../../assets/css/general";
// import { httpClient } from "../../config/httpClient";
// import useCallbackStatus from "../../hooks/useCallbackStatus";
// import { inviteAdminValidation } from "../../validations/login-validate";

// const tableHeader = [
//   {
//     id: "population",
//     label: "DATE INVITED",
//     minWidth: 170,
//     align: "left",
//     format: (value) => value.toLocaleString("en-US"),
//   },
//   {
//     id: "size",
//     label: "ROLE",
//     minWidth: 170,
//     align: "left",
//     format: (value) => value.toLocaleString("en-US"),
//   },
//   {
//     id: "emaill",
//     label: "EMAIL ADDRESS",
//     minWidth: 170,
//     align: "left",
//     format: (value) => value.toLocaleString("en-US"),
//   },
//   {
//     id: "MANAGE",
//     label: "MANAGE",
//     minWidth: 170,
//     align: "left",
//     format: (value) => value.toFixed(2),
//   },
// ];

// const useStyles = makeStyles((theme2) => ({
//   root: {
//     width: "100%",
//     marginTop: "25px",
//     overflowX: "auto",
//     backgroundColor: "white",
//     border: "1px solid #caced5",
//   },
//   selectInput: {
//     marginLeft: "20px",
//     backgroundColor: "white",
//     "& .MuiOutlinedInput-input": {
//       padding: "12.5px 14px",
//       width: "150px",
//     },
//   },
//   container: {
//     height: "440px",
//     width: "100%",
//   },
//   activeFilter: {
//     backgroundColor: theme2.palette.primary.main,
//     color: "white",
//     fontWeight: 600,
//   },
//   formControl: {
//     margin: theme2.spacing(1),
//     minWidth: 240,
//   },
//   formControlMenu: {
//     margin: theme2.spacing(1),
//     width: "100%",
//   },
//   selectEmpty: {
//     marginTop: theme2.spacing(2),
//   },
//   input: {
//     padding: "11px 14px",
//   },
// }));

// function PartnersDatatable() {
//   const classes = useStyles();
//   const globalClasses = useGeneralStyle();
//   const roleAccessApiStatus = useCallbackStatus();
//   const adminInviteApiStatus = useCallbackStatus();
//   const getAdminUserApiStatus = useCallbackStatus();
//   const adminRevokeApiStatus = useCallbackStatus();
//   const [mode, setMode] = useState("add");
//   const [search, setSearchKey] = useState("");
//   const [totalPages, setTotalPages] = React.useState(10);
//   const [modal, setModal] = React.useState(false);
//   const [confirmation, setConfirmation] = React.useState(false);
//   // const getCategoryListStatus = useCallbackStatus();
//   const notification = useSnackbar();
//   const theme = useTheme();
//   const [roleAccessList, setRoleAccessList] = useState([]);
//   const [selected, setSelect] = React.useState("all");
//   const [user, setUser] = React.useState([]);
//   const [tab, setTab] = useState("pending");
//   const [filter, setFilter] = React.useState("pending");

//   let {
//     values,
//     touched,
//     errors,
//     setValues,
//     resetForm,
//     handleBlur,
//     submitForm,
//     handleChange,
//     setErrors,
//     setFieldValue,
//   } = useFormik({
//     initialValues: {
//       email: "",
//       role: "none",
//     },
//     onSubmit: () => {
//       adminInvite();
//     },
//     validationSchema: inviteAdminValidation,
//   });

//   const getRoleAccessList = useCallback(
//     async (pageSize = 10, pageNumber = 1) => {
//       const apiBody = {
//         page: pageNumber,
//         page_size: pageSize,
//         pagination_required: true,
//         search_string: search,
//         guard:"partner"
//       };

//       if (apiBody.search_string === "") {
//         delete apiBody.search_string;
//       }

//       await roleAccessApiStatus.run(
//         httpClient("POST", "/admin/getAllRoles", {
//           body: apiBody,
//         }).then((result) => {
//           console.log("Result: ", result);

//           const {
//             data: { data, meta },
//           } = result;

//           setRoleAccessList(data);
//           setTotalPages(meta.total);
//         })
//       );
//     },
//     [roleAccessApiStatus, search]
//   );

//   const adminInvite = async () => {
//     const apiBody = {
//       ...values,
//       type: "new_invitation",
//     };
//     await adminInviteApiStatus.run(
//       httpClient("POST", "/admin/invite-partner", {
//         body: apiBody,
//       })
//         .then((result) => {
//           setModal(false);
//           getAdminUser();
//         })
//         .catch((er) => {
//           setErrors(er.errors);
//         })
//     );
//   };

//   const adminRevoke = async () => {
//     await adminRevokeApiStatus.run(
//       httpClient("POST", "/admin/revokeInvitation", {
//         body: values,
//       })
//         .then((result) => {
//           setConfirmation(false);
//           getAdminUser();
//           console.log("admin Revoke", result);
//           notification.enqueueSnackbar("Revoke Successfully", {
//             variant: "success",
//           });
//         })
//         .catch((er) => {
//           console.log("ERROR Revoke", er);
//         })
//     );
//   };

//   const adminResend = async (apiBody) => {
//     await adminInviteApiStatus.run(
//       httpClient("POST", "/admin/invite", {
//         body: apiBody,
//       })
//         .then((result) => {
//           console.log("admin Invite", result);
//         })
//         .catch((er) => {
//           console.log("ERROR Invite", er);
//         })
//     );
//   };

//   const getAdminUser = useCallback(async () => {
//     const apiBody = {};
//     if (tab === "pending") {
//       apiBody.user_type = "invited_partners";
//       apiBody.is_registered = "N";
//       apiBody.role = selected;
//     } else if (tab === "register") {
//       apiBody.user_type = "invited_registered_list";
//       apiBody.role = selected;
//       apiBody.is_registered = "Y";
//     } else if (tab === "suspended") {
//       apiBody.user_type = "partner";
//       apiBody.active = "inactive";
//     }

//     await getAdminUserApiStatus.run(
//       httpClient("POST", "/admin/getUsers", {
//         body: apiBody,
//       })
//         .then((result) => {
//           console.log("get User", result);
//           const {
//             data: { data },
//           } = result;
//           setUser(data);
//           console.log("getUser", data);
//         })
//         .catch((er) => {
//           console.log("ERROR:", er);
//         })
//     );
//   }, [getAdminUserApiStatus, selected, tab]);

//   useEffect(() => {
//     getRoleAccessList();
//     getAdminUser();
//     // adminInvite();
//   }, [tab]);

//   const ModalHeader = () => {
//     return (
//       <>
//         <Box textAlign="center" padding={1}>
//           Invite Partner
//         </Box>
//       </>
//     );
//   };

//   return (
//     <>
//       {/* <TabPanel value={filter} index="categories">
//       <PendingDatatable />
//     </TabPanel> */}
//       <ConfirmationModal
//         loading={adminRevokeApiStatus.isPending}
//         height="150px"
//         width="auto"
//         title="Are you sure"
//         status={confirmation}
//         closeBtnText="cancel"
//         saveBtnText="Revoke"
//         closeHandler={() => setConfirmation(!confirmation)}
//         saveHandler={() => adminRevoke()}
//       >
//         Are you sure want to revoke this invitation
//       </ConfirmationModal>

//       <Modal
//         height="300px"
//         width="100%"
//         loading={adminInviteApiStatus.isPending}
//         headerComponent={ModalHeader}
//         saveBtnText="Save"
//         closeBtnText="Cancel"
//         closeHandler={() => {
//           resetForm();
//           setModal(false);
//         }}
//         saveHandler={() => {
//           // adminInvite();
//           submitForm();
//         }}
//         status={modal}
//       >
//         <DialogContentText id="alert-dialog-description">
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <Box display="flex" flexDirection="column">
//                 <InputLabel className={globalClasses.inputLabel}>
//                   Email
//                 </InputLabel>
//                 <TextField
//                   id="email"
//                   name="email"
//                   error={touched.email && errors.email}
//                   helperText={touched.email && errors.email}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   value={values.email}
//                   FormHelperTextProps={{ style: { fontSize: "10px" } }}
//                   placeholder="write here"
//                   variant="outlined"
//                 />
//               </Box>
//             </Grid>
//             <Grid item xs={12}>
//               <Box
//                 style={{ paddingRight: "20px" }}
//                 className={globalClasses.inputLabel}
//               >
//                 Role
//               </Box>
//               <FormControl
//                 variant="outlined"
//                 className={classes.formControlMenu}
//               >
//                 <Select
//                   input={<OutlinedInput classes={{ input: classes.input }} />}
//                   labelId="demo-simple-select-outlined-label"
//                   id="demo-simple-select-outlined"
//                   value={values.role}
//                   onChange={(event) => {
//                     setFieldValue("role", event.target.value);
//                   }}
//                   label="selected"
//                 >
//                   <MenuItem value="none">
//                     <em>Select</em>
//                   </MenuItem>
//                   {roleAccessList.map((role) => {
//                     return <MenuItem value={role.name}>{role.name}</MenuItem>;
//                   })}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>
//         </DialogContentText>
//       </Modal>
//       <Box display="flex" style={{ backgroundColor: "white" }}>
//         <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
//           <Button
//             disableElevation
//             variant="contained"
//             onClick={() => {
//               setTab("pending");
//               setFilter("pending");
//             }}
//             className={clsx({
//               [classes.activeFilter]: filter === "pending",
//             })}
//           >
//             PENDING
//           </Button>
//         </Box>
//         <Box style={{ margin: "10px 10px" }}>
//           <Button
//             disableElevation
//             variant="contained"
//             onClick={() => {
//               setTab("register");
//               setFilter("register");
//             }}
//             className={clsx({
//               [classes.activeFilter]: filter === "register",
//             })}
//           >
//             REGISTERED
//           </Button>
//         </Box>
//         <Box style={{ margin: "10px 10px" }}>
//           <Button
//             disableElevation
//             variant="contained"
//             onClick={() => {
//               setTab("suspended");
//               setFilter("suspended");
//             }}
//             className={clsx({
//               [classes.activeFilter]: filter === "suspended",
//             })}
//           >
//             SUSPENDED
//           </Button>
//         </Box>
//       </Box>
//       <Grid container style={{ padding: theme.spacing(3) }}>
//         <Grid
//           style={{
//             display: "flex",
//             alignItems: "center",
//             padding: "10px 0px",
//           }}
//           item
//           xs={4}
//         >
//           <Box style={{ paddingRight: "20px" }}>Search</Box>
//           <TextField
//             onChange={(e) => setSearchKey(e.target.value)}
//             variant="outlined"
//             style={{ width: "100%" }}
//             inputProps={{
//               style: { padding: "12px", backgroundColor: "white" },
//             }}
//             placeholder="by Name"
//           />
//         </Grid>
//         <Grid
//           style={{
//             display: "flex",
//             alignItems: "center",
//             padding: "10px 20px",
//           }}
//           item
//           xs={4}
//         >
//           <Box style={{ paddingRight: "20px" }}>Role</Box>
//           <FormControl variant="outlined" className={classes.formControl}>
//             {/* <InputLabel id="demo-simple-select-outlined-label">
//         Select
//       </InputLabel> */}
//             <Select
//               input={<OutlinedInput classes={{ input: classes.input }} />}
//               labelId="demo-simple-select-outlined-label"
//               id="demo-simple-select-outlined"
//               value={selected}
//               onChange={(event) => {
//                 console.log(event.target.value);
//                 setSelect(event.target.value);
//               }}
//               // label="selected"
//             >
//               <MenuItem value="all">All</MenuItem>
//               {roleAccessList.map((role) => {
//                 return <MenuItem value={role.id}>{role.name}</MenuItem>;
//               })}
//               {/* <MenuItem value={30}>HR</MenuItem>
//         <MenuItem value={20}>User</MenuItem> */}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid
//           style={{
//             display: "flex",
//             alignItems: "center",
//             flexDirection: "row-reverse",
//             padding: "10px 0px",
//           }}
//           item
//           xs={4}
//         >
//           <Button
//             variant="contained"
//             color="primary"
//             style={{ width: "55%" }}
//             onClick={() => {
//               setMode("add");
//               setModal(!modal);
//               resetForm();
//             }}
//           >
//             INVITE
//           </Button>
//         </Grid>
//         <Grid item xs={12}>
//           <Paper className={classes.root}>
//             <Datatable
//               loading={getAdminUserApiStatus.isPending}
//               getDataList={getAdminUser}
//               totalRecords={totalPages}
//               tableHeader={tableHeader}
//             >
//               {user.map((userData) => {
//                 return (
//                   <>
//                     <TableRow>
//                       <TableCell>
//                         {moment(userData.created_at).format("DD/MM/YYYY")}
//                       </TableCell>
//                       <TableCell>{userData.role}</TableCell>
//                       <TableCell>{userData.email}</TableCell>
//                       <TableCell>
//                         <PopupState variant="popover" popupId="demo-popup-menu">
//                           {(popupState) => (
//                             <React.Fragment>
//                               <IconButton {...bindTrigger(popupState)}>
//                                 <MoreHorizIcon />
//                               </IconButton>
//                               <Menu {...bindMenu(popupState)}>
//                                 <MenuItem
//                                   onClick={() => {
//                                     adminResend({
//                                       email: userData.email,
//                                       role: userData.role,
//                                       type: "resend_invitation",
//                                     });
//                                     popupState.setOpen(false);
//                                   }}
//                                 >
//                                   Resend
//                                 </MenuItem>
//                                 <MenuItem
//                                   onClick={() => {
//                                     setValues({
//                                       email: userData.email,
//                                       // role: userData.role,
//                                     });
//                                     setMode("delete");
//                                     setConfirmation(true);
//                                     popupState.setOpen(false);
//                                   }}
//                                 >
//                                   Revoke
//                                 </MenuItem>
//                               </Menu>
//                             </React.Fragment>
//                           )}
//                         </PopupState>
//                       </TableCell>
//                     </TableRow>
//                   </>
//                 );
//               })}
//             </Datatable>
//           </Paper>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

// export default PartnersDatatable;
