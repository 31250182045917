import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Header from "../layouts/Header";
import clsx from "clsx";
import TabPanel from "../MuiComponent/TabPanel";
import InquireyDatatable from "./newsletter/InquireyDatatable";
import SubscriptionDatatable from "./newsletter/SubscriptionDatatable";

const useStyles = makeStyles((theme2) => ({
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function NewsletterAndInquireyManagment() {
  const [filter, setFilter] = React.useState("subscription");
  const classes = useStyles();

  return (
    <div>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Newsletter & Inquirey
        </Typography>
      </Header>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => setFilter("subscription")}
            className={clsx({
              [classes.activeFilter]: filter === "subscription",
            })}
            variant="contained"
          >
            Subscriptions
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({ [classes.activeFilter]: filter === "inquirey" })}
            onClick={() => setFilter("inquirey")}
          >
            Inquiries
          </Button>
        </Box>
      </Box>

      <TabPanel value={filter} index="subscription">
        <SubscriptionDatatable />
      </TabPanel>

      <TabPanel value={filter} index="inquirey">
        <InquireyDatatable />
      </TabPanel>
    </div>
  );
}

export default NewsletterAndInquireyManagment;
