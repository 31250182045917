import { createReducer } from "@reduxjs/toolkit";
import { setGeneralApiData, setReducerData } from "./actions";
import { setFormStructure } from "../actions";

const { SET_USER, IS_COMPONENT_BUSY } = require("../constants");

let initialState = {
  userData: {
    permissions: [],
  },
  states: {
    data:[],
    links:{},
    meta:{}
  },
  cities: [],
  countries: [],
  isComponentBusy: false,
  form_structures: {},
};

// const GeneralReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_USER:
//       return { ...state, userData: action.payload };
//     case IS_COMPONENT_BUSY:
//       return { ...state, isComponentBusy: action.payload };
//     default:
//       return state;
//   }
// };

const GeneralReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_USER, (state, action) => {
      state.userData = action.payload;
    })
    .addCase(IS_COMPONENT_BUSY, (state, action) => {
      state.isComponentBusy = action.payload;
    })
    .addCase(setGeneralApiData, (state, action) => {
      let { data, key } = action.payload;
      state[key] = data;
    })
    .addCase(setReducerData, (state, action) => {
      let { data, property } = action.payload;
      state[property] = data;
    })
    .addCase(setFormStructure, (state, action) => {
      let data = action.payload;
      console.log("SET FORMDATA :", data);
      state.form_structures = data;
    })
    .addDefaultCase((state) => state);
});

export default GeneralReducer;
