import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useTheme,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import LockIcon from "../../assets/images/drawer/password.svg";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { useAuth } from "../../providers/AuthProvider";
import { changePasswordValidate } from "../../validations/login-validate";

function ChangePassword() {
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const {
    values,
    touched,
    errors,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordValidate,
    onSubmit: () => {
      changePassword();
    },
  });

  const apiSource = useCancelRequest();
  const changePasswordApiStatus = useCallbackStatus();
  const auth = useAuth();
  const notification = useSnackbar();

  const changePassword = async () => {
    await changePasswordApiStatus.run(
      httpClient("POST", "/partner/changePassword", {
        body: {
          user_id: auth.getUserId(),
          old_password: values.old_password,
          new_password: values.new_password,
        },
      })
        .then(() => {
          resetForm();

          notification.enqueueSnackbar("Password Change Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error ChangePassword.js: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  return (
    <Box style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Current Password
            </InputLabel>
            <TextField
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              id="old_password"
              name="old_password"
              error={touched.old_password && errors.old_password}
              helperText={touched.old_password && errors.old_password}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.old_password}
              placeholder="write here"
              variant="outlined"
              type={showCurrentPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="lockicon" src={LockIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() =>
                      setCurrentShowPassword((prevValue) => !prevValue)
                    }
                  >
                    {showCurrentPassword ? (
                      <VisibilityOff
                        fontSize="small"
                        className={globalClasses.icon}
                      />
                    ) : (
                      <Visibility
                        fontSize="small"
                        className={globalClasses.icon}
                      />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              New Password
            </InputLabel>
            <TextField
              id="new_password"
              name="new_password"
              error={touched.new_password && errors.new_password}
              helperText={touched.new_password && errors.new_password}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.new_password}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="lockicon" src={LockIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() =>
                      setConfirmShowPassword((prevValue) => !prevValue)
                    }
                  >
                    {showConfirmPassword ? (
                      <VisibilityOff
                        fontSize="small"
                        className={globalClasses.icon}
                      />
                    ) : (
                      <Visibility
                        fontSize="small"
                        className={globalClasses.icon}
                      />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Typography
              variant="caption"
              style={{
                paddingTop: "8px",
                fontSize: "11px",
                fontWeight: 450,
              }}
            >
              Your Password must be atleast 8 characters long.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Confirm Password
            </InputLabel>
            <TextField
              id="confirm_password"
              name="confirm_password"
              error={touched.confirm_password && errors.confirm_password}
              helperText={touched.confirm_password && errors.confirm_password}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="lockicon" src={LockIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() =>
                      setNewShowPassword((prevValue) => !prevValue)
                    }
                  >
                    {showNewPassword ? (
                      <VisibilityOff
                        fontSize="small"
                        className={globalClasses.icon}
                      />
                    ) : (
                      <Visibility
                        fontSize="small"
                        className={globalClasses.icon}
                      />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={changePasswordApiStatus.isPending ? true : false}
            onClick={submitForm}
          >
            {changePasswordValidate.isPending ? (
              <CircularProgress size={23} />
            ) : (
              "Update"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ChangePassword;
