import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import TextField from "../../MuiComponent/TextField";
import LockIcon from "../../assets/images/drawer/password.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  accountDetailsValidation,
  changePasswordValidate,
} from "../../validations/login-validate";
import { useFormik } from "formik";
import useCancelRequest from "../../hooks/useCancelToken";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { apiClient } from "../../config/apiClient";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { httpClient } from "../../config/httpClient";
import PeopleIcon from "../../assets/images/icons/people.svg";
import { useDispatch, useSelector } from "react-redux";
import { setUser as setUserGlobal } from "../../store/actions";

function AccountDetails({ data, refresh, loading }) {
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const dispatch = useDispatch();

  const {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    resetForm,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      bank_name: "",
      account_holder_name: "",
      account_number: "",
      ifsc_code: "",
    },
    validationSchema: accountDetailsValidation,
    onSubmit: () => {
      updateProfile();
    },
  });

  useEffect(() => {
    rest.setValues({ ...data?.account_details });
  }, [data]);

  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  const changePasswordApiStatus = useCallbackStatus();
  const auth = useAuth();
  const notification = useSnackbar();

  const updateProfile = async () => {
    await changePasswordApiStatus.run(
      httpClient("POST", "/partner/updateAccountDetails", {
        body: {
          business_id: businessDetails.id,
          ...values,
        },
      })
        .then(() => {
          refresh();

          httpClient("GET", `/${process.env.REACT_APP_ACCESS_MODE}/user`, {
            body: {},
          })
            .then((result) => {
              dispatch(setUserGlobal(result));
            })
            .catch((er) => {
              notification.enqueueSnackbar(er.message, {
                variant: "error",
              });
            });

          notification.enqueueSnackbar("Profile Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error ChangePassword.js: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  return (
    <Box style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Bank Name
            </InputLabel>
            <TextField
              id="bank_name"
              name="bank_name"
              error={touched.bank_name && errors.bank_name}
              helperText={touched.bank_name && errors.bank_name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bank_name}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Account Holder Name
            </InputLabel>
            <TextField
              id="account_holder_name"
              name="account_holder_name"
              error={touched.account_holder_name && errors.account_holder_name}
              helperText={
                touched.account_holder_name && errors.account_holder_name
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.account_holder_name}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Account Number
            </InputLabel>
            <TextField
              id="account_number"
              name="account_number"
              error={touched.account_number && errors.account_number}
              helperText={touched.account_number && errors.account_number}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.account_number}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Bank Code (IFSC / IBAN)
            </InputLabel>
            <TextField
              id="ifsc_code"
              name="ifsc_code"
              error={touched.ifsc_code && errors.ifsc_code}
              helperText={touched.ifsc_code && errors.ifsc_code}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ifsc_code}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={changePasswordApiStatus.isPending ? true : false}
            onClick={submitForm}
          >
            {changePasswordValidate.isPending ? (
              <CircularProgress size={23} />
            ) : (
              "Update"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AccountDetails;
