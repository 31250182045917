import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { Route } from "react-router-dom";
import Header from "../layouts/Header";

import clsx from "clsx";
import AddBill from "./purchase/AddBill";
import PurchaseDatatable from "./purchase/PurchaseDatatable";

const useStyles = makeStyles((theme2) => ({
  hidden: {
    display: "none",
  },
}));

function PurchaseManagement() {
  const classes = useStyles();
  const [pageName, setPageName] = useState("datatable");
  const datatableRef = useRef();
  const pageNameHandler = (type) => {
    setPageName(type);
    if (type === "datatable") datatableRef.current.getAllProductsList();
  };

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Purchase Management
        </Typography>
      </Header>

      {/* <Box className={clsx({ [classes.hidden]: pageName !== "editRecord" })}> */}
      <Route exact path="/purchase/:id/edit">
        <AddBill editMode={true} actionHandler={pageNameHandler} />
      </Route>
      {/* </Box> */}
      {/* <Box className={clsx({ [classes.hidden]: pageName !== "addRecord" })}> */}
      <Route exact path="/purchase/add">
        <AddBill actionHandler={pageNameHandler} />
      </Route>
      {/* </Box> */}
      {/* <Box className={clsx({ [classes.hidden]: pageName !== "datatable" })}> */}
      <Route exact path="/purchase">
        <PurchaseDatatable ref={datatableRef} actionHandler={pageNameHandler} />
      </Route>
      {/* </Box> */}
    </>
  );
}

export default PurchaseManagement;
