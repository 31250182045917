import _ from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const QuillEditor = forwardRef(({ value, onChange }, ref) => {
  const [quill, setQuill] = useState({});

  const setContent = (content) => {
    quill.setContents(content);
  };

  useImperativeHandle(
    ref,
    () => ({
      quill: quill,
    }),
    [quill]
  );

  useEffect(() => {
    if (!_.isEmpty(quill)) {
      setContent(JSON.parse(value));
    }
  }, [quill,setContent,value]);

  useEffect(() => {
    let { Quill } = window;
    let quill = new Quill("#editor-container", {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image"],
        ],
      },
      placeholder: "Compose an epic...",
      theme: "snow", // or 'bubble'
    });

    quill.on("text-change", function (delta, oldDelta, source) {
      if (source == "api") {
        // console.log("An API call triggered this change.");
        onChange(quill.getContents(), delta, oldDelta, source);
      } else if (source == "user") {
        // console.log("A user action triggered this change.");
        onChange(quill.getContents(), delta, oldDelta, source);
      }
    });

    setQuill(quill);
  }, []);

  // const getContent = () => {
  //   console.log(JSON.stringify(quill.getContents()));
  // };

  const getFocus = () => {
    quill.focus();
  };
  const updateContent = (content) => {
    quill.updateContents(content);
  };

  // const getSelection = () => {
  //   let result = quill.getSelection(true);
  //   console.log(result);
  // };

  // const setSelection = () => {
  //   quill.setSelection(25, 2);
  // };

  // const removeFormat = () => {
  //   quill.removeFormat(25, 1);
  // };

  console.log("ref: ", ref);
  return (
    <>
      <div id="editor-container"></div>
      {/* <button onClick={() => getContent()}>Quill Content</button>
      <button onClick={() => setContent()}>Set Quill Content</button>
      <button onClick={() => removeFormat()}>Remove Format</button>
      <button onClick={() => getSelection()}>Retrieve Selection</button>
      <button onClick={() => setSelection()}>Set Selection</button> */}
      {/* <button onClick={() => getFocus()}>Focus</button> */}
    </>
  );
});

export default QuillEditor;
