import {
  Box,
  Button,
  Typography,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Menu,
  Select,
  useTheme,
  Paper,
  Chip,
} from "@material-ui/core";
import Modal from "../MuiComponent/Modal";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { couponValidation } from "../validations/login-validate";
import Datatable from "../MuiComponent/Datatable";
import Table from "@material-ui/core/Table";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Checkbox from "@material-ui/core/Checkbox";
import { useSnackbar } from "notistack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import { httpClient } from "../config/httpClient";
import { result } from "lodash";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme2) => ({
  root: {
    marginTop: "25px",
    width: "165vh",
    overflowX: "auto",
    backgroundColor: "white",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  selectPadding: {
    // padding: "13px",
  },
}));

const columns = [
  { id: "name", align: "left", label: "Name", minWidth: 170 },
  { id: "code", align: "left", label: "Code", minWidth: 170 },
  {
    id: "detailed_profile",
    label: "Discount",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "size",
    label: "Expiry Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function CouponManagment() {
  const theme = useTheme();
  const [search, setSearchKey] = React.useState("");
  const classes = useStyles();
  const notification = useSnackbar();
  const couponManagmentApiStatus = useCallbackStatus();
  const getCountriesApiStatus = useCallbackStatus();
  const getAdminUserApiStatus = useCallbackStatus();
  const coupanListApiStatus = useCallbackStatus();
  const [modal, setModal] = React.useState(true);
  const [coupanData, setCoupanData] = React.useState([]);
  const [countryList, setCountryList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dateValueIn, setDateValueIn] = React.useState(moment());
  const [dateValueOut, setDateValueOut] = React.useState(moment());

  const [selected, setSelect] = React.useState("none");
  const [userList, setUserList] = useState([]);
  const [emailSearch, setEmailSearch] = useState("");

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    setFieldValue,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      code: "",
      type: "",
      discount: "",
      start_date: moment().format("YYYY-MM-DD hh:mm:ss a"),
      end_date: moment().format("YYYY-MM-DD hh:mm:ss a"),
      min_spend: "",
      total_applicable_limit: "",
      per_user_limit: "",
      email: [],
      country: "",
      status: "",
      only_for_first_transaction: "N",
    },
    onSubmit: () => {
      //   alert("Ok");
      //   console.log("values", values);
      //   couponManagment();
    },
    validationSchema: couponValidation,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchUserList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [emailSearch]);

  const couponManagment = async () => {
    await couponManagmentApiStatus.run(
      httpClient("POST", "/admin/coupon", {
        body: values,
      })
        .then((result) => {
          console.log("couponManagment :", result);

          notification.enqueueSnackbar("Coupon Added Succefully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("coupon ERROR :", er);
        })
    );
  };

  const coupanList = async (pageSize = 10, pageNumber = 1) => {
    await coupanListApiStatus.run(
      httpClient(
        "GET",
        `/admin/coupon?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&pagination_required: true`,
        {
          body: {},
        }
      )
        .then((result) => {
          const {
            data: { data, meta },
          } = result;

          setCoupanData(data);
          setTotalRecords(meta.total);
          console.log("CopanList", data);
        })
        .catch((er) => {
          console.log("Error", er);
        })
    );
  };

  const fetchUserList = async () => {
    await getAdminUserApiStatus.run(
      httpClient("POST", "/admin/getUsers", {
        body: {
          user_type: "user",
          active: "Y",
          search_string: emailSearch,
        },
      })
        .then((result) => {
          console.log("get User", result);

          const {
            data: { data, total },
          } = result;

          setUserList(data);
          console.log("getUser", data);
        })
        .catch((er) => {
          console.log("ERROR:", er);
        })
    );
  };

  const getCountries = async () => {
    await getCountriesApiStatus.run(
      httpClient("POST", "/common/getCountry", {
        body: {},
      }).then((result) => {
        console.log("Result: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS: ", total);
        setCountryList(data);
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      coupanList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleStateChange = (_, newValue) => {
    setFieldValue(
      "email",
      newValue.map((data) => data.email)
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  const onDeleteEmail = (item) => {
    setFieldValue(
      "email",
      values.email.filter((email) => email != item)
    );
  };

  return (
    <>
      <Box>
        <Header>
          <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
            Coupon Management
          </Typography>
        </Header>

        {modal ? (
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={5}
            >
              <Box style={{ paddingRight: "20px" }}>Search</Box>
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: { padding: "12px", backgroundColor: "white" },
                }}
                placeholder="by Name"
              />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                padding: "10px 0px",
              }}
              item
              xs={7}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // resetForm();
                  // setMode("add");
                  setModal(false);
                }}
              >
                Add Coupon
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Datatable
                  getDataList={coupanList}
                  totalRecords={totalRecords}
                  loading={coupanListApiStatus.isPending}
                  tableHeader={columns}
                >
                  {coupanData.map((data) => (
                    <TableRow hover tabIndex={-1} key={data.id}>
                      <TableCell component="th" scope="row">
                        {data.name}
                      </TableCell>
                      <TableCell>{data.code}</TableCell>

                      <TableCell>
                        {data.discount} {data.type == "amount" ? "Rs " : "%"}
                      </TableCell>
                      <TableCell>
                        {moment(data.end_date).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                  ))}
                </Datatable>
                {/* <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell >Calories</TableCell>
                        <TableCell >Fat&nbsp;(g)</TableCell>
                        <TableCell >Carbs&nbsp;(g)</TableCell>
                        <TableCell >Protein&nbsp;(g)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody> */}

                {/* </TableBody>
                  </Table>
                </TableContainer> */}
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Box
            style={{
              paddingRight: theme.spacing(3),
              paddingLeft: theme.spacing(3),
            }}
          >
            <Paper style={{ border: "1px solid #e0e0e0" }}>
              <Grid
                container
                spacing={2}
                style={{ padding: "15px", width: "100%" }}
              >
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      onClick={() => {
                        //   addMeetingStatus("datatable");
                        //   history.push("/products");
                        setModal(true);
                      }}
                    >
                      <ArrowBackIosIcon /> Back
                    </Button>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          // addMeetingStatus()
                          couponManagment();
                          console.log("values", values);
                          submitForm();
                          resetForm();
                        }}
                      >
                        {/* {editMode ? "Update" : "Add"} */}
                        Add
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Name*
                    </InputLabel>
                    <TextField
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      placeholder="Name"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Code*
                    </InputLabel>
                    <TextField
                      id="code"
                      name="code"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.code && errors.code}
                      helperText={touched.code && errors.code}
                      variant="outlined"
                      placeholder="code"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Type*
                    </InputLabel>
                    <Select
                      //   className={classes.selectPadding}
                      //   multiple
                      fullWidth
                      id="type"
                      name="type"
                      value={selected}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setSelect(event.target.value);
                      }}
                      onBlur={handleBlur}
                      // error={touched.type && errors.type}
                      // helperText={touched.type && errors.type}
                      variant="outlined"
                      placeholder="type"
                      style={{ height: "45px" }}
                      // label="selected"
                    >
                      <MenuItem disabled value="none">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                      <MenuItem value="amount">amount</MenuItem>
                    </Select>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Discount*
                    </InputLabel>
                    <TextField
                      id="discount"
                      name="discount"
                      type="number"
                      value={values.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.discount && errors.discount}
                      helperText={touched.discount && errors.discount}
                      variant="outlined"
                      placeholder="Discount"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box>Start Date</Box>

                      <KeyboardDatePicker
                        inputVariant="outlined"
                        fullWidth
                        disablePast
                        className={classes.inputTextField}
                        id="date-picker-dialog"
                        // disablePast
                        // label="Date picker dialog"
                        format="YYYY-MMM-DD"
                        TextFieldComponent={(props) => {
                          return (
                            <TextField
                              {...props}
                              value={moment(values.start_date).format(
                                "YYYY-MM-DD"
                              )}
                              InputProps={{
                                ...props.InputProps,
                                className: classes.inputTextField,
                              }}
                            />
                          );
                        }}
                        // value={values.start_date}
                        onChange={(date) => {
                          console.log(
                            "DOB DATE SELECTED: ",
                            moment(date).format("YYYY-MM-DD hh:mm:ss a")
                          );
                          setFieldValue(
                            "start_date",
                            moment(date).format("YYYY-MM-DD hh:mm:ss a")
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          id: `datePicker1`,
                          // style: { color: "black" },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box>End Date*</Box>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      fullWidth
                      disablePast
                      id="date-picker-dialog"
                      disablePast
                      className={classes.inputTextField}
                      format="YYYY-MMM-DD"
                      value={values.end_date}
                      minDate={values.start_date}
                      TextFieldComponent={(props) => {
                        return (
                          <TextField
                            {...props}
                            value={moment(values.end_date).format("YYYY-MM-DD")}
                            InputProps={{
                              ...props.InputProps,
                              className: classes.inputTextField,
                            }}
                          />
                        );
                      }}
                      onChange={(date) => {
                        console.log(
                          "DOB DATE SELECTED: ",
                          moment(date).format("YYYY-MM-DD hh:mm:ss a")
                        );
                        setFieldValue(
                          "end_date",
                          moment(date).format("YYYY-MM-DD hh:mm:ss a")
                        );
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        id: `datePicker1`,
                        // style: { color: "white" },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Min Spend*
                    </InputLabel>
                    <TextField
                      id="min_spend"
                      name="min_spend"
                      type="number"
                      value={values.min_spend}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.min_spend && errors.min_spend}
                      helperText={touched.min_spend && errors.min_spend}
                      variant="outlined"
                      placeholder="min spend"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Checkbox
                        // defaultChecked
                        size="small"
                        value={values.only_for_first_transaction}
                        inputProps={{
                          "aria-label": "checkbox with small size",
                        }}
                      />
                      {console.log("values", values)}
                    </Box>
                    <Box>Only allow For 1st Transaction / Payment</Box>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Uses Limit*
                    </InputLabel>
                    <TextField
                      id="total_applicable_limit"
                      name="total_applicable_limit"
                      type="number"
                      value={values.total_applicable_limit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.total_applicable_limit &&
                        errors.total_applicable_limit
                      }
                      helperText={
                        touched.total_applicable_limit &&
                        errors.total_applicable_limit
                      }
                      variant="outlined"
                      placeholder="Uses Limit"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Uses Limit / Per User*
                    </InputLabel>
                    <TextField
                      id="per_user_limit"
                      name="per_user_limit"
                      type="number"
                      value={values.per_user_limit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.per_user_limit && errors.per_user_limit}
                      helperText={
                        touched.per_user_limit && errors.per_user_limit
                      }
                      variant="outlined"
                      placeholder="limit per user"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Allowed Emails*
                    </InputLabel>
                    {/* <TextField
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                      variant="outlined"
                      placeholder="Email"
                    /> */}

                    <div>
                      <Autocomplete
                        multiple
                        noOptionsText={<Box>No results found</Box>}
                        // defaultValue={manageFormik.values.email}
                        // value={manageFormik.values.email}
                        id="email"
                        name="email"
                        options={userList.filter(
                          (elem) =>
                            !values.email.find((email) => elem.email === email)
                        )}
                        onChange={handleStateChange}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.email}
                        filterSelectedOptions
                        ChipProps={{
                          size: "small",
                          className: classes.languageChip,
                        }}
                        renderTags={() => {}}
                        size="small"
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Box>{option.email}</Box>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(event) => {
                              setEmailSearch(event.target.value);
                            }}
                            // error={
                            //   manageFormik.touched.email &&
                            //   manageFormik.errors.email
                            // }
                            // helperText={
                            //   manageFormik.touched.email &&
                            //   manageFormik.errors.email
                            // }
                            variant="outlined"
                            className="hoverhide"
                            placeholder="Select email"
                          />
                        )}
                      />

                      <Box>
                        {values.email.map((item, index) => (
                          <Chip
                            style={{
                              color: "white",
                              marginRight: "8px",
                              borderRadius: "4px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "6px",
                              color: "black",
                            }}
                            variant="outlined"
                            onDelete={() => {
                              onDeleteEmail(item);
                            }}
                            label={item}
                            deleteIcon={
                              <CloseIcon style={{ height: "11px" }} />
                            }
                          />
                        ))}
                      </Box>
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Allowed Countries*
                    </InputLabel>
                    <Select
                      fullWidth
                      id="country"
                      name="country"
                      value={selected}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setSelect(event.target.value);
                      }}
                      onBlur={handleBlur}
                      // error={touched.country && errors.country}
                      // helperText={touched.country && errors.country}
                      variant="outlined"
                      placeholder="country"
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="none">
                        <em>Select</em>
                      </MenuItem>
                      {countryList.map((list) => {
                        return <MenuItem value={list.id}>{list.name}</MenuItem>;
                      })}
                    </Select>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <InputLabel className={classes.inputLabel}>
                      Status*
                    </InputLabel>
                    <Select
                      fullWidth
                      id="status"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.status && errors.status}
                      helperText={touched.status && errors.status}
                      variant="outlined"
                      placeholder="status"
                      style={{ height: "45px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Y">Yes</MenuItem>
                      <MenuItem value="N">No</MenuItem>
                    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
}

export default CouponManagment;
