import { useFormik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Chip,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import Datatable from "../MuiComponent/Datatable";
import Modal from "../MuiComponent/Modal";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import { useAuth } from "../providers/AuthProvider";
import {
  managePolicyValidate,
  titleValidate,
} from "../validations/login-validate";
import { PERMISSIONS } from "../store/constants";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const tableHeader = [
  { id: "date", align: "left", label: "LAST UPDATED", minWidth: 200 },
  {
    id: "role_name",
    label: "ROLE NAME",
    minWidth: 170,
    align: "left",
  },
  {
    id: "number",
    label: "NO. OF USERS",
    minWidth: 140,
    align: "left",
  },
  {
    id: "policies",
    label: "NO. OF POLICIES",
    minWidth: 160,
    align: "left",
  },
  {
    id: "policies",
    label: "ATTACHED POLICIES",
    minWidth: 220,
    align: "left",
  },
  {
    id: "manage",
    label: "MANAGE",
    minWidth: 130,
    align: "left",
  },
];

const useStyles = makeStyles((theme) => ({
  inputOutlined: {
    padding: "10.5px 14px",
  },
  icon: {
    fill: "#FFFFFF",
  },
  button: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingRight: "20px",
  },
  popupIndicator: {
    color: "white",
  },
  clearIndicator: {
    color: "white",
  },
  languageChip: {
    marginLeft: "10px",
    marginBottom: "5px",
    marginTop: "5px",
  },
  inputRoot: {
    backgroundColor: "inherit",
  },
  tag: {
    color: "white",
  },
}));

function RoleManagement() {
  const classes = useStyles();
  const globalClasses = useGeneralStyle();
  const roleAccessApiStatus = useCallbackStatus();
  const policyListApiStatus = useCallbackStatus();
  const manageApiStatus = useCallbackStatus();
  const managePolicyApiStatus = useCallbackStatus();
  const { guard_name } = useParams();
  const auth = useAuth();
  const history = useHistory();
  const notification = useSnackbar();
  const [search, setSearchKey] = useState("");
  const [roleAccessList, setRoleAccessList] = useState([]);
  const [userObj, setUserObject] = useState({});
  const [modal, setModal] = useState(false);
  const [policiesModal, setPoliciesModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [guard, setGuard] = useState(guard_name);
  const [totalPages, setTotalPages] = useState(0);
  const [policiesList, setPoliciesList] = useState([]);

  const addFormik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: titleValidate,

    onSubmit: () => {
      manageStatus("add", addFormik.values.title);

      addFormik.resetForm();
    },
  });

  const manageFormik = useFormik({
    initialValues: {
      // role: "",
      policy: [],
    },
    validationSchema: managePolicyValidate,

    onSubmit: () => {
      // manageStatus("add", values.title);
      managePolicy();
      manageFormik.resetForm();
    },
  });

  let getPoliciesList = useCallback(async () => {
    await policyListApiStatus.run(
      httpClient("POST", "admin/getAllPermissions", {
        body: {
          guard,
        },
      })
        .then((result) => {
          const { data } = result;
          console.log("getAllPermissions: ", data);

          setPoliciesList(data);
        })
        .catch((error) => {
          notification.enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  }, [notification, policyListApiStatus]);

  const getRoleAccessList = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        pagination_required: true,
        search_string: search,
        guard,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await roleAccessApiStatus.run(
        httpClient("POST", "/admin/getAllRoles", {
          body: apiBody,
        }).then((result) => {
          console.log("Result: ", result);

          const {
            data: { data, meta },
          } = result;

          setRoleAccessList(data);
          setTotalPages(meta.total);
        })
      );
    },
    [roleAccessApiStatus, search]
  );
  //   let getRoleAccessList = async (token, refresh = false) => {
  //     let apiBody = {
  //       user_id: localStorage.getItem("userId"),
  //       user_type: "admin",
  //       search_string: search,
  //       next_token: token,
  //       pagination_required: true,
  //     };

  //     if (apiBody.search_string === "") {
  //       delete apiBody.search_string;
  //     }

  //     await roleAccessApiStatus.run(
  //       apiClient("POST", "setting", "getpageaccessroles", {
  //         body: apiBody,
  //         shouldUseDefaultToken: false,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //         .then((result) => {
  //           const {
  //             content: { data, has_more, next_token },
  //           } = result;

  //           setHasMore(has_more);
  //           setTotalPages(next_token);

  //           console.log("-----Table-----", data);

  //           if (refresh) {
  //             setRoleAccessList([...data]);
  //           } else {
  //             setRoleAccessList([...roleAccessList, ...data]);
  //           }
  //         })
  //         .catch((error) => {
  //           notification.enqueueSnackbar(error.message, {
  //             variant: "error",
  //           });
  //         })
  //     );
  //   };

  const manageStatus = async (action, role = "", roleID = "") => {
    let apiBody = {};
    if (action === "add") {
      apiBody = {
        action: action,
        guard,
        name: role,
      };
    }

    if (action === "delete") {
      apiBody = {
        action: action,
        guard,
        role_id: roleID,
      };
    }

    await manageApiStatus.run(
      httpClient("POST", "admin/manageRole", {
        body: apiBody,
      })
        .then((result) => {
          getRoleAccessList();
          if (action === "add") {
            setModal(false);
          }
          if (action === "update") {
            setPoliciesModal(false);
          }
          if (action === "delete") {
            setOpenModal(false);
          }
        })
        .catch((err) => {
          if (err && err.code === 403) {
            auth.logout();
          } else {
            notification.enqueueSnackbar(err.message, {
              variant: "err",
              autoHideDuration: 2000,
            });
          }
        })
    );
  };

  const managePolicy = async () => {
    let newArr = manageFormik.values.policy.map((item) => item.id);
    let apiBody = {
      role: userObj.name,
      permissions: newArr,
      guard,
    };

    await managePolicyApiStatus.run(
      httpClient("POST", "admin/addPemissionToRole", {
        body: apiBody,
      })
        .then((result) => {
          getRoleAccessList();
          setPoliciesModal(false);
        })
        .catch((err) => {
          // if (err && err.code === 403) {
          //   auth.logout();
          // } else {
          notification.enqueueSnackbar(err.message, {
            variant: "err",
            autoHideDuration: 2000,
          });
          // }
        })
    );
  };

  useEffect(() => {
    // getRoleAccessList(null, true);
    getPoliciesList();
  }, [guard]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getRoleAccessList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, guard]);

  const handleStateChange = (_, newValue) => {
    manageFormik.setFieldValue("policy", newValue);
  };

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Add New Role
          </Box>
          <Button
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModal(!modal);
              addFormik.setValues(null);
              addFormik.resetForm();
            }}
          >
            <CloseIcon size="large" style={{ color: "white" }} />
          </Button>
        </Box>
      </>
    );
  };

  const PoliciesModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Manage Policies
          </Box>
          <Button
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setPoliciesModal(!policiesModal);
              manageFormik.setValues(null);
              manageFormik.resetForm();
            }}
          >
            <CloseIcon size="large" style={{ color: "white" }} />
          </Button>
        </Box>
      </>
    );
  };

  const ConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            You can’t delete this role
          </Box>
        </Box>
      </>
    );
  };

  const NewConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            Confirm delete
          </Box>
        </Box>
      </>
    );
  };

  const SmartBtn = ({ data }) => {
    let [showLess, setShowLess] = useState(true);

    if (data.length < 4) {
      return (
        <>
          {data.map((item, index) => {
            return (
              <Button
                key={index}
                variant="outlined"
                disableRipple
                style={{
                  borderRadius: "6px",
                  height: "30px",
                  margin: "5px",
                  color: "black",
                  cursor: "default",
                  textTransform: "none",
                }}
              >
                {item.name}
              </Button>
            );
          })}
        </>
      );
    }

    return (
      <>
        {showLess ? (
          <>
            {data.map((item, index) => {
              if (index < 3) {
                return (
                  <Button
                    key={index}
                    variant="outlined"
                    disableRipple
                    style={{
                      borderRadius: "6px",
                      height: "30px",
                      margin: "5px",
                      color: "black",
                      cursor: "default",
                      textTransform: "none",
                    }}
                  >
                    {item.name}
                  </Button>
                );
              }
            })}
            <Button
              variant="outlined"
              disableRipple
              onClick={() => {
                setShowLess(!showLess);
              }}
              style={{
                color: "black",
                borderRadius: "6px",
                height: "30px",
                margin: "5px",
                color: "black",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              <KeyboardArrowDownIcon /> more
            </Button>
          </>
        ) : (
          <>
            {data.map((item, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  disableRipple
                  style={{
                    borderRadius: "6px",
                    color: "black",
                    height: "30px",
                    margin: "5px",
                    cursor: "default",
                    textTransform: "none",
                  }}
                >
                  {item.name}
                </Button>
              );
            })}
            <Button
              variant="outlined"
              disableRipple
              onClick={() => {
                setShowLess(!showLess);
              }}
              style={{
                borderRadius: "6px",
                color: "black",
                height: "30px",
                margin: "5px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              <KeyboardArrowUpIcon /> less
            </Button>
          </>
        )}
      </>
    );
  };

  const onDeletePolicy = (item) => {
    manageFormik.setFieldValue(
      "policy",
      manageFormik.values.policy.filter((policy) => policy.id != item.id)
    );
  };

  return (
    <>
      <Header>
        <Box flexGrow={1}>
          <InputLabel
            className={globalClasses.filterLabel}
            style={{ fontSize: "10px", color: "#6f6d6d" }}
          >
            Select Role:
          </InputLabel>
          <Select
            style={{ fontSize: "14px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={guard}
            onChange={(e) => {
              setGuard(e.target.value);
              history.replace(`/roles/${e.target.value}`);
            }}
          >
            {/* <MenuItem value="all">All Users</MenuItem>
            <MenuItem value="admins">All Admins</MenuItem> */}
            <MenuItem value="admin">Admin Portal</MenuItem>
            <MenuItem value="partner">Partner Portal</MenuItem>
            <MenuItem value="service">Service Portal</MenuItem>
            {/* <MenuItem value="staff">All Delivery Staff</MenuItem>*/}
          </Select>
        </Box>
      </Header>

      <Grid container item xs={12} style={{ padding: "20px" }}>
        <Grid item xs={12}>
          {/* ---------- Search and Add Button --------- */}
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={5}
            >
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                variant="outlined"
                inputProps={{
                  style: { padding: "12px" },
                }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      variant="filled"
                      fontSize="small"
                      //   style={{ backgroundColor: "#303652", color: "white" }}
                    />
                  ),
                }}
                placeholder="Search"
              />
            </Grid>

            <Grid className={classes.button} item xs={4}>
              <Button
                onClick={() => {
                  setModal(!modal);
                }}
                color="primary"
                variant="contained"
                style={{ width: "50%" }}
              >
                ADD NEW ROLE
              </Button>
            </Grid>
          </Grid>

          {/* ---------- Table View --------- */}

          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Datatable
                loading={roleAccessApiStatus.isPending}
                getDataList={getRoleAccessList}
                totalRecords={totalPages}
                tableHeader={tableHeader}
              >
                {roleAccessList.map((row, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell>
                        {moment(row.updated_at).format("Do MMM YYYY, h:mm a")}
                      </TableCell>

                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.no_of_users}</TableCell>
                      <TableCell>{row.no_of_modules}</TableCell>
                      <TableCell>
                        {row.module_names.length === 0 ? (
                          <Button
                            variant="outlined"
                            disableRipple
                            style={{
                              borderRadius: "6px",
                              height: "30px",
                              margin: "5px",
                              color: "black",
                              cursor: "default",
                              textTransform: "none",
                            }}
                          >
                            + Add
                          </Button>
                        ) : (
                          <>
                            <SmartBtn data={row.module_names} />
                          </>
                        )}
                      </TableCell>

                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-menu"
                            >
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    {...bindTrigger(popupState)}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu {...bindMenu(popupState)}>
                                    <MenuItem
                                      onClick={() => {
                                        setUserObject(row);
                                        manageFormik.setFieldValue(
                                          "policy",
                                          row.module_names
                                        );

                                        setPoliciesModal(true);
                                        popupState.setOpen(false);
                                      }}
                                    >
                                      Manage Policies
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        if (row.no_of_users !== 0) {
                                          setConfirmation(true);
                                        } else {
                                          setUserObject(row);
                                          setOpenModal(true);
                                          // manageStatus("delete", "", row.id);
                                        }
                                        popupState.setOpen(false);
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Datatable>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* ---------- Manage Policies Modal --------- */}

      <Modal
        height="400px"
        width="100%"
        headerComponent={PoliciesModalHeader}
        saveBtnText="Save"
        loading={managePolicyApiStatus.isPending}
        closeBtnText="Discard"
        closeHandler={() => {
          setPoliciesModal(!policiesModal);
          manageFormik.resetForm();
        }}
        saveHandler={() => {
          manageFormik.submitForm();
        }}
        status={policiesModal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Role Title
                </InputLabel>
                <TextField
                  id="role"
                  name="role"
                  // error={manageFormik.touched.role && manageFormik.errors.role}
                  // helperText={
                  //   manageFormik.touched.role && manageFormik.errors.role
                  // }
                  // defaultValue={userObj.name}
                  value={userObj.name}
                  // onChange={manageFormik.handleChange}
                  // onBlur={manageFormik.handleBlur}
                  // value={manageFormik.values.role}
                  // FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  // placeholder="Write here"
                  variant="outlined"
                />

                <InputLabel
                  className={globalClasses.inputLabel}
                  style={{ marginTop: "20px" }}
                >
                  Policies
                </InputLabel>
                <div>
                  <Autocomplete
                    multiple
                    // getOptionDisabled={(options) =>
                    //   values.policy.length == 3 ? true : false
                    // }
                    noOptionsText={<Box>No results found</Box>}
                    defaultValue={manageFormik.values.policy}
                    value={manageFormik.values.policy}
                    id="policy"
                    name="policy"
                    // id="tags-outlined"
                    // limitTags={3}`
                    options={policiesList.filter(
                      (elem) =>
                        !manageFormik.values.policy.find(
                          ({ id }) => elem.id === id
                        )
                    )}
                    onChange={handleStateChange}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    // classes={{
                    //   popupIndicator: classes.popupIndicator,
                    //   clearIndicator: classes.clearIndicator,
                    //   tag: classes.tag,
                    //   input: classes.inputRoot,
                    // }}
                    ChipProps={{
                      size: "small",
                      className: classes.languageChip,
                    }}
                    renderTags={() => {}}
                    size="small"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Box>{option.name}</Box>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        helperText={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        variant="outlined"
                        className="hoverhide"
                        // style={{
                        //   backgroundColor: "#303652",
                        //   color: "white",
                        //   height: "40px",
                        //   // background: "#212543",
                        // }}
                        placeholder="Select Policy"
                      />
                    )}
                  />

                  <Box>
                    {manageFormik.values.policy.map((item, index) => (
                      <Chip
                        style={{
                          color: "black",
                          marginRight: "8px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "6px",
                          // border: "1px solid #E27851",
                          // backgroundColor: "#251E40",
                          color: "black",
                        }}
                        // style={{
                        //   height: "30px",
                        //   margin: "5px",
                        //   cursor: "default",
                        // }}
                        variant="outlined"
                        onDelete={() => {
                          onDeletePolicy(item);
                        }}
                        label={item.name}
                        deleteIcon={<CloseIcon style={{ height: "11px" }} />}
                      />
                    ))}
                  </Box>
                </div>
                {/* <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  value={values.add_faq_category}
                  className={globalClasses.selectInput}
                  variant="outlined"
                  id="add_faq_category"
                  name="add_faq_category"
                  error={touched.add_faq_category && errors.add_faq_category}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                >
                  <MenuItem value="none" disabled>
                    Select Policy
                  </MenuItem>
                  {policiesList &&
                    modal &&
                    policiesList.map((elem) => (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    ))} 
                </Select> */}
                {/* {touched.add_faq_category && errors.add_faq_category && (
                  <FormHelperText error variant="outlined">
                    {touched.add_faq_category && errors.add_faq_category}
                  </FormHelperText>
                )} */}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {/* ---------- Add Role Modal --------- */}

      <Modal
        headerComponent={ModalHeader}
        status={modal}
        loading={manageApiStatus.isPending}
        height="254px"
        width="550px"
        closeBtnText="Cancel"
        saveBtnText="Add"
        saveHandler={addFormik.submitForm}
        closeHandler={() => {
          setModal(!modal);
          addFormik.setValues(null);
          addFormik.resetForm();
        }}
      >
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <>
                  <InputLabel className={globalClasses.inputLabel}>
                    Title(Role Name):
                  </InputLabel>
                  <TextField
                    id="title"
                    name="title"
                    error={addFormik.touched.title && addFormik.errors.title}
                    onChange={addFormik.handleChange}
                    onBlur={addFormik.handleBlur}
                    value={addFormik.values.title}
                    variant="outlined"
                    placeholder="Write here"
                    className={globalClasses.selectInput}
                  />
                  {addFormik.touched.title && addFormik.errors.title && (
                    <FormHelperText error variant="outlined">
                      {addFormik.touched.title && addFormik.errors.title}
                    </FormHelperText>
                  )}
                </>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {/* ---------- Delete Role Modal  (Warning) --------- */}

      <Modal
        height="192px"
        width="425px"
        buttonAlign="center"
        headerComponent={ConfirmationModalHeader}
        saveBtnText="okay"
        status={confirmation}
        closeHandler={() => {
          setConfirmation(!confirmation);
        }}
        saveHandler={() => {
          setConfirmation(!confirmation);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Yo can’t delete this role because it has active accounts,
          <br /> please shift to the other roles
        </Box>
      </Modal>

      {/* Popup to delete the role  */}
      <Modal
        height="200px"
        width="100%"
        headerComponent={NewConfirmationModalHeader}
        status={openModal}
        loading={manageApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Yes"
        // saveHandler={addFormik.submitForm}
        closeHandler={() => {
          setOpenModal(!openModal);
        }}
        saveHandler={() => {
          manageStatus("delete", "", userObj.id);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Are you sure you want to delete this role ?
        </Box>
      </Modal>
    </>
  );
}

export default RoleManagement;
