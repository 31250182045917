import {
  Box,
  Grid,
  Paper,
  useTheme,
  Menu,
  MenuItem,
  Select,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import TextField from "../../MuiComponent/TextField";
import React, { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datatable from "../../MuiComponent/Datatable";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import ConfirmationModal from "../../MuiComponent/ConfirmationModal";

const tableHeader = [
  {
    id: "population",
    label: "Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Email",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MANAGE",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function SubscriptionDatatable() {
  const [search, setSearchKey] = useState("");
  const [subscriptionList, setsubscriptionList] = useState([]);
  const [type, setType] = useState("subscribe");
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const classes = useStyles();
  const subscriptionApiStatus = useCallbackStatus();
  const [confirmation, setConfirmation] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(10);

  let getsubscription = async (pageSize = 10, pageNumber = 1) => {
    let apiBody = {
      status: type,
      search_string: search,
    };

    if (search == "") {
      delete apiBody.search_string;
    }

    await subscriptionApiStatus.run(
      httpClient(
        "POST",
        `/admin/listSubscriptions?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&status=${type}&pagination_required=true`,
        {
          body: {},
        }
      )
        .then((result) => {
          console.log("RESULT Subscribe", result);

          const {
            data: { data, meta },
          } = result;

          setsubscriptionList(data);
          setTotalPages(meta.total);
        })
        .catch((er) => {
          console.log("ERROR subscribe", er);
        })
    );
  };

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      getsubscription();
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [type, search]);

  return (
    <div>
      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="NO"
        saveBtnText="YES"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => alert("unsubscribe")}
      >
        Are you sure want to unsuibscribe this E-mail
      </ConfirmationModal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          item
          xs={6}
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <Box style={{ paddingLeft: "20px" }}>Type</Box>
            <Select
              className={globalClasses.selectInput}
              style={{ marginLeft: "20px", paddingRight: "20px" }}
              labelId="demo-simple-select-outlined-label"
              value={type}
              onChange={(e) => setType(e.target.value)}
              variant="outlined"
            >
              <MenuItem value={"subscribe"}>Subscribed</MenuItem>
              <MenuItem value={"unsubscribe"}>UnSubscribed</MenuItem>
            </Select>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={subscriptionApiStatus.isPending}
              getDataList={getsubscription}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {subscriptionList.map((category, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(category.created_at).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell>{category.email}</TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  setConfirmation(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Unsubscribe
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default SubscriptionDatatable;
