import React from 'react';
import './App.css';
import AppProvider from './layouts/AppProvider';
import RouteList from './routes/RouteList';

function App() {
  return (
    <AppProvider>
        <RouteList/>
    </AppProvider>
  );
}

export default App;
