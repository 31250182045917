import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import { useFormik } from "formik";
import { cmsTermsValidation } from "../validations/login-validate";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { useSnackbar } from "notistack";
import { httpClient } from "../config/httpClient";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "14px",
    fontWeight: 600,
  },
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  root: {
    border: "1px solid #ccc",
    margin: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ccc",
    padding: "1rem",
  },
  action: {
    color: theme.palette.primary.main_secondary,
    fontSize: "20px",
    textTransform: "none",
  },
  content: {
    padding: theme.spacing(4),
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 3),
  },
}));

function Terms() {
  const classes = useStyles();
  const [tab, setTab] = useState("client");
  const globalClasses = useGeneralStyle();
  const getCmsApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [isEditMode, setEditMode] = useState(false);

  let {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    handleChange,
    setValues,
    ...rest
  } = useFormik({
    initialValues: {
      terms: "",
    },
    onSubmit: () => {
      updatedCmsPages();
    },
    validationSchema: cmsTermsValidation,
  });

  const updatedCmsPages = async () => {
    console.log("Update CMS apiBody: ", {
      type: "terms",
      data: values.terms,
    });

    await getCmsApiStatus.run(
      httpClient("POST", "/admin/updateCmsPages", {
        body: {
          type: "terms",
          data: values.terms,
        },
      })
        .then((result) => {
          console.log("Result: ", result);

          notification.enqueueSnackbar("Details Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  const getCmsPages = async () => {
    await getCmsApiStatus.run(
      httpClient("POST", "/common/getCmsPages", {
        body: {
          type: "terms",
        },
      })
        .then((result) => {
          const {
            data: { content },
          } = result;
          console.log("Result: ", content);
          setValues({ terms: content });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  useEffect(() => {
    getCmsPages();
  }, []);

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Company info
        </Typography>
      </Header>
      <Box style={{ padding: "20px 0px 0px 20px", fontWeight: 600 }}>
        Terms of Use
      </Box>
      <Paper elevation={0} className={classes.root}>
        <Grid container>
          <Grid style={{ borderBottom: "1px solid #c9c9c9" }} item xs={12}>
            <Box display="flex">
              {/* <Box style={{ margin: "10px 10px", marginLeft: "10px" }}>
                                <Button disableElevation onClick={() => setTab('client')}
                                    className={clsx({ [classes.activeFilter]: tab === 'client' })}
                                    variant="contained">Client</Button>
                            </Box>
                            <Box style={{ margin: "10px 10px", marginLeft: "10px" }}>
                                <Button disableElevation onClick={() => setTab('expert')}
                                    className={clsx({ [classes.activeFilter]: tab === 'expert' })}
                                    variant="contained">Expert</Button>
                            </Box> */}
              <Box
                flexGrow={1}
                alignSelf="center"
                textAlign="end"
                style={{ margin: "10px 10px", marginLeft: "10px" }}
              >
                {isEditMode ? (
                  <a
                    className={globalClasses.link}
                    onClick={() => {
                      submitForm();
                      setEditMode(false);
                    }}
                  >
                    Save
                  </a>
                ) : (
                  <a
                    className={globalClasses.link}
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </a>
                )}
              </Box>
            </Box>
          </Grid>
          {tab === "client" ? (
            <>
              <Grid
                item
                xs={12}
                style={{ borderRight: "1px solid #c9c9c9", padding: "10px" }}
              >
                <TextField
                  id="terms"
                  inputProps={{ style: { padding: "0px" } }}
                  name="terms"
                  error={touched.terms && errors.terms}
                  helperText={touched.terms && errors.terms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.terms}
                  disabled={!isEditMode}
                  placeholder="about"
                  multiline
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                container
                xs={6}
                style={{ borderRight: "1px solid #c9c9c9", padding: "10px" }}
              >
                <Grid className={classes.heading} item xs={12}>
                  Note
                </Grid>
                <TextField
                  disabled={!isEditMode}
                  multiline
                  variant="outlined"
                />
              </Grid>
              <Grid
                container
                item
                xs={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <Box
                  className={classes.heading}
                  style={{ marginBottom: "10px" }}
                >
                  Contact and Email
                </Box>
                <Box>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Email
                    </Grid>
                    <Grid item xs={9}>
                      <TextField disabled={!isEditMode} variant="outlined" />
                    </Grid>
                  </Grid>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Contact No
                    </Grid>
                    <Grid item xs={9}>
                      <TextField disabled={!isEditMode} variant="outlined" />
                    </Grid>
                  </Grid>
                </Box>
                {/* <Grid className={classes.heading} style={{ marginBottom: "10px" }} item xs={12}>
                                        Contact and Email
                                    </Grid>
                                    <Grid alignItems='center' style={{ padding: "10px" }} container item xs={12}>
                                        <Grid item xs={3}>
                                            Email
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid alignItems='center' style={{ padding: "10px" }} container item xs={12}>
                                        <Grid item xs={3}>
                                            Contact No
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField variant="outlined" />
                                        </Grid>
                                    </Grid> */}
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
}

export default Terms;
