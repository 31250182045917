import { Typography, Box } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
var converter = require("number-to-words");

{
  /* <style>
.page-break {
    page-break-after: always;
}
table{
    border-collapse: collapse;
    width: 100%;
}
</style> */
}

function Invoice() {
  const history = useHistory();
  const location = useLocation();
  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  let {
    state: { data },
  } = location;

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Invoice
        </Typography>
      </Header>
      <Box style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          {console.log(data)}
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        .page-break {\n            page-break-after: always;\n        }\n        table{\n            border-collapse: collapse;\n            width: 100%;\n        }\n",
            }}
          />
          <div style={{ textAlign: "center" }}>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <div style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}>
                TAX INVOICE
              </div>
              <div>(ORIGINAL FOR RECIPIENT)</div>
            </div>
          </div>
          <table border={1}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  {_.upperCase(businessDetails.name)}
                  <div>{businessDetails.address}</div>
                  {/* 156, B-Block Sec-14 Udaipur (Raj.) */}
                  <div>
                    {businessDetails.city +
                      ", " +
                      businessDetails.state +
                      " (" +
                      businessDetails.pincode +
                      ")"}
                  </div>
                  <div>Mo: {businessDetails.contact_number}</div>
                  <div>Email: {businessDetails.email}</div>
                </td>
                <td rowSpan={2} colSpan={4}>
                  <table width="100%" height="200px">
                    <tbody>
                      <tr style={{ borderBottom: "1px solid" }}>
                        <td style={{ borderRight: "1px solid" }} width="50%">
                          Order ID
                        </td>
                        <td style={{ textAlign: "left" }}>{data.id}</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid" }}>
                        <td style={{ borderRight: "1px solid" }} width="50%">
                          Date
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {moment(data.updated_at).format("DD MMM YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "1px solid" }} width="50%">
                          Mode
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {_.capitalize(data.payment_mode)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Buyer (Bill to)</div>
                  <Box fontWeight="600">{data.billing_address.name}</Box>
                  <Box>{data.billing_address.line1}</Box>
                  <Box>{data.billing_address.city}</Box>
                  <Box>Mo: {data.billing_address.mobile}</Box>

                  {/* <div>156, B-Block Sec-14 Udaipur (Raj.)</div>
                  <div>313001</div>
                  <div>Mo: 9460970462</div>
                  <div>Email: jainelectricals2022@gmail.com</div> */}
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <table>
                    <tbody>
                      <tr style={{ borderBottom: "1px solid" }}>
                        <td width="70px">S.No</td>
                        <td>Description of Goods</td>
                        <td>Quantity</td>
                        <td>Rate</td>
                        <td>GST</td>
                        <td>{/* Tax */}</td>
                        <td>Amount (₹)</td>
                      </tr>
                      {data.cart.items.map((item, index) => (
                        <tr>
                          <td width="70px">{index + 1}</td>
                          <td>
                            {" "}
                            {item.name + " "}
                            {(() => {
                              if (Array.isArray(item.options) == false) {
                                let string = [];

                                Object.keys(item.options).map((key) => {
                                  string.push(
                                    `${key}: ${item.options[key][0].value}, `
                                  );
                                });

                                return `[ ${string.join(", ")}]`;
                              }
                            })()}
                          </td>
                          <td>{item.quantity}</td>
                          <td>
                            {item.total_price -
                              item.total_price * (item.tax / 100)}
                          </td>
                          <td>
                            {item.total_price * (item.tax / 100)} ({item.tax}%)
                          </td>
                          <td>{/* 01 */}</td>
                          <td>{item.total_price}</td>
                        </tr>
                      ))}

                      {/* <tr style={{ padding: 5 }}>
                        <td colSpan={6} style={{ textAlign: "right" }}></td>
                        <td> {data.cart.cart_total} </td>
                      </tr>
                      <tr>
                        <td
                          style={{ textAlign: "right", paddingRight: 30 }}
                          colSpan={2}
                        >
                          CGST
                        </td>
                        <td colSpan={4}></td>
                        <td>0</td>
                      </tr> */}
                      {/* <tr>
                        <td
                          style={{ textAlign: "right", paddingRight: 30 }}
                          colSpan={2}
                        >
                          SGST
                        </td>
                        <td colSpan={4}></td>
                        <td>0</td>
                      </tr> */}
                      <tr style={{ borderTop: "1px solid" }}>
                        <td
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                            paddingRight: 30,
                          }}
                          colSpan={2}
                        >
                          Total
                        </td>
                        <td>{/* 03 */}</td>
                        <td colSpan={2}></td>
                        <td>{/* 03 */}</td>
                        <td style={{ fontWeight: "bold" }}>
                          {data.cart.cart_total}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid" }}>
                <td style={{ paddingRight: 30 }}>
                  Amount Chargeable (in words)
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid" }}>
                <td style={{ paddingRight: 30, fontWeight: "bold" }}>
                  INR {_.startCase(converter.toWords(data.cart.cart_total))}{" "}
                  Only
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid" }}>
                <td
                  style={{
                    paddingRight: 30,
                    borderRight: "1px solid",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 100,
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      Company PAN: {businessDetails.pancard}
                    </div>
                    <div>
                      <div>Declaration:</div>
                      <div style={{ fontStyle: "italic" }}>
                        We declare that this invoice shows the actual price of
                        the goods described and that all particulars are true
                        and correct.
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ paddingRight: 30 }} colSpan={2}>
                  <div>Company's Bank Details</div>
                  <div>
                    A/C Holder Name:{" "}
                    {businessDetails?.account_details?.account_holder_name}
                  </div>
                  <div>
                    Bank Name: {businessDetails?.account_details?.bank_name}
                  </div>
                  <div>
                    A/C No.: {businessDetails?.account_details?.account_number}
                  </div>
                  <div>
                    IFS Code: {businessDetails?.account_details?.ifsc_code}
                  </div>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid", height: 100 }}>
                <td
                  style={{
                    paddingRight: 30,
                    borderRight: "1px solid",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 100,
                      flexDirection: "column",
                    }}
                  >
                    <div>Customer's Seal &amp; Signature</div>
                  </div>
                </td>
                <td
                  style={{ paddingRight: 30, textAlign: "right" }}
                  colSpan={2}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 100,
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ flex: 1 }}>For Jain Electricals</div>
                    <div>Authorised Signatory</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>{" "}
    </>
  );
}

export default Invoice;
