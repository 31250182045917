import {
  Typography,
  Grid,
  useTheme,
  Box,
  Button,
  Paper,
  makeStyles,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import Header from "../layouts/Header";
import { Route } from "react-router-dom";
import clsx from "clsx";
import ProductDatatable from "./product/ProductDatatable";
import AddProduct from "./product/AddProduct";

const useStyles = makeStyles((theme2) => ({
  hidden: {
    display: "none",
  },
}));

function ProductManagement() {
  const theme = useTheme();
  const classes = useStyles();
  const [pageName, setPageName] = useState("datatable");
  const datatableRef = useRef();

  const pageNameHandler = (type) => {
    setPageName(type);
    if (type == "datatable") datatableRef.current.getAllProductsList();
  };

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Product Management
        </Typography>
      </Header>

      {/* <Box className={clsx({ [classes.hidden]: pageName != "editRecord" })}> */}
        <Route exact path="/products/:id/edit">
          <AddProduct editMode={true} addMeetingStatus={pageNameHandler} />
        </Route>
      {/* </Box> */}
      {/* <Box className={clsx({ [classes.hidden]: pageName != "addRecord" })}> */}
        <Route exact path="/products/add">
          <AddProduct addMeetingStatus={pageNameHandler} />
        </Route>
      {/* </Box> */}
      {/* <Box className={clsx({ [classes.hidden]: pageName != "datatable" })}> */}
        <Route exact path="/products">
          <ProductDatatable
            ref={datatableRef}
            addMeetingStatus={pageNameHandler}
          />
        </Route>
      {/* </Box> */}
    </>
  );
}

export default ProductManagement;
