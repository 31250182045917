import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";

const useStyles = makeStyles((theme2) => ({
  container: {
    height: "70vh",
    // width: "80vw",
  },
}));

function Datatable(props) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  let [page, setPage] = useState(searchParams.page ? searchParams.page - 1 : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    searchParams.page_size ? searchParams.page_size : 10
  );
  const classes = useStyles();
  const { getDataList } = props;
  let [page_size, setPageSize] = useState(10);

  const handleChangePage = (event, newPage) => {
    if (newPage >= 0) {
      sessionStorage.setItem(`page_${location.key}`, newPage);
      searchParams.page = newPage + 1;
      history.replace(
        location.pathname + "?" + queryString.stringify(searchParams)
      );

      getDataList(rowsPerPage, newPage);
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem(`page_size${location.key}`, event.target.value);
    console.log("location.search:", searchParams);
    searchParams.page = 1;
    searchParams.page_size = event.target.value;
    history.replace(
      location.pathname + "?" + queryString.stringify(searchParams)
    );

    getDataList(parseInt(event.target.value, 10), 1);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    console.log("searchParams:", searchParams);
    if (searchParams.page_size) {
      page_size = searchParams.page_size;
      setPageSize(searchParams.page_size);
    }
    if (searchParams.page) {
      page = searchParams.page - 1;
      setPage(searchParams.page - 1);
    }
  }, [searchParams, page, page_size]);

  useEffect(() => {
    getDataList(page_size, page + 1);
  }, [page_size, page]);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.tableHeader.map((header, i) => (
                <TableCell
                  align={header.align}
                  style={{
                    minWidth: header.minWidth,
                    width: header.width,
                    fontWeight: 700,
                    backgroundColor: "white",
                  }}
                  key={i}
                  className={classes.tableHeadCell}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.loading ? (
              <>
                <TableRow>
                  {props.tableHeader.map((header, i) => (
                    <TableCell
                      align={header.align}
                      style={{ minWidth: header.minWidth, fontWeight: 600 }}
                      key={i}
                      className={classes.tableHeadCell}
                    >
                      <Skeleton variant="rect" width={100} height={15} />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {props.tableHeader.map((header, i) => (
                    <TableCell
                      align={header.align}
                      style={{ minWidth: header.minWidth, fontWeight: 600 }}
                      key={i}
                      className={classes.tableHeadCell}
                    >
                      <Skeleton variant="rect" width={100} height={15} />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {props.tableHeader.map((header, i) => (
                    <TableCell
                      align={header.align}
                      style={{ minWidth: header.minWidth, fontWeight: 600 }}
                      key={i}
                      className={classes.tableHeadCell}
                    >
                      <Skeleton variant="rect" width={100} height={15} />
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ) : (
              <>{props.children}</>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default Datatable;
