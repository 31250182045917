import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import { useFormik } from "formik";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal from "../../MuiComponent/ConfirmationModal";
import Datatable from "../../MuiComponent/Datatable";
import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { inviteAdminValidation } from "../../validations/login-validate";

const tableHeader = [
  {
    id: "population",
    label: "DATE INVITED",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "ROLE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "emaill",
    label: "EMAIL ADDRESS",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MANAGE",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  formControl: {
    margin: theme2.spacing(1),
    minWidth: 240,
  },
  formControlMenu: {
    margin: theme2.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme2.spacing(2),
  },
  input: {
    padding: "11px 14px",
  },
}));

function AdminDatatable() {
  const classes = useStyles();
  const globalClasses = useGeneralStyle();
  const roleAccessApiStatus = useCallbackStatus();
  const adminInviteApiStatus = useCallbackStatus();
  const getAdminUserApiStatus = useCallbackStatus();
  const adminRevokeApiStatus = useCallbackStatus();
  const [mode, setMode] = useState("add");
  const [search, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  // const getCategoryListStatus = useCallbackStatus();
  const notification = useSnackbar();
  const theme = useTheme();
  const [roleAccessList, setRoleAccessList] = useState([]);
  const [selected, setSelect] = React.useState("all");
  const [user, setUser] = React.useState([]);
  const [tab, setTab] = useState("pending");
  const [filter, setFilter] = React.useState("pending");

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      role: "none",
    },
    onSubmit: () => {
      adminInvite();
    },
    validationSchema: inviteAdminValidation,
  });

  const getRoleAccessList = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        pagination_required: true,
        search_string: search,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await roleAccessApiStatus.run(
        httpClient("POST", "/admin/getAllRoles", {
          body: apiBody,
        }).then((result) => {
          console.log("Result: ", result);

          const {
            data: { data, meta },
          } = result;

          setRoleAccessList(data);
          setTotalPages(meta.total);
        })
      );
    },
    [roleAccessApiStatus, search]
  );

  const adminInvite = async () => {
    const apiBody = {
      ...values,
      type: "new_invitation",
    };
    await adminInviteApiStatus.run(
      httpClient("POST", "/admin/invite", {
        body: apiBody,
      })
        .then((result) => {
          setModal(false);
          getAdminUser();
        })
        .catch((er) => {
          setErrors(er.errors);
        })
    );
  };

  const adminRevoke = async () => {
    await adminRevokeApiStatus.run(
      httpClient("POST", "/admin/revokeInvitation", {
        body: values,
      })
        .then((result) => {
          setConfirmation(false);
          getAdminUser();
          console.log("admin Revoke", result);
          notification.enqueueSnackbar("Revoke Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("ERROR Revoke", er);
        })
    );
  };

  const adminResend = async (apiBody) => {
    await adminInviteApiStatus.run(
      httpClient("POST", "/admin/invite", {
        body: apiBody,
      })
        .then((result) => {
          console.log("admin Invite", result);
        })
        .catch((er) => {
          console.log("ERROR Invite", er);
        })
    );
  };

  const getAdminUser = useCallback(async () => {
    const apiBody = {};
    if (tab === "pending") {
      apiBody.user_type = "invited_list";
      apiBody.is_registered = "N";
      apiBody.role = selected;
    } else if (tab === "register") {
      apiBody.user_type = "invited_list";
      apiBody.role = selected;
      apiBody.is_registered = "Y";
    } else if (tab === "suspended") {
      apiBody.user_type = "admin";
      apiBody.active = "N";
    }

    await getAdminUserApiStatus.run(
      httpClient("POST", "/admin/getUsers", {
        body: apiBody,
      })
        .then((result) => {
          console.log("get User", result);
          const {
            data: { data },
          } = result;
          setUser(data);
          console.log("getUser", data);
        })
        .catch((er) => {
          console.log("ERROR:", er);
        })
    );
  }, [getAdminUserApiStatus, selected, tab]);

  useEffect(() => {
    getRoleAccessList();
    getAdminUser();
    // adminInvite();
  }, [tab]);

  const ModalHeader = () => {
    return (
      <>
        <Box textAlign="center" padding={1}>
          Invite Admin
        </Box>
      </>
    );
  };

  return (
    <>
      {/* <TabPanel value={filter} index="categories">
      <PendingDatatable />
    </TabPanel> */}
      <ConfirmationModal
        loading={adminRevokeApiStatus.isPending}
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Revoke"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => adminRevoke()}
      >
        Are you sure want to revoke this invitation
      </ConfirmationModal>

      <Modal
        height="300px"
        width="100%"
        loading={adminInviteApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Save"
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(false);
        }}
        saveHandler={() => {
          // adminInvite();
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Email
                </InputLabel>
                <TextField
                  id="email"
                  name="email"
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{ paddingRight: "20px" }}
                className={globalClasses.inputLabel}
              >
                Role
              </Box>
              <FormControl
                variant="outlined"
                className={classes.formControlMenu}
              >
                <Select
                  input={<OutlinedInput classes={{ input: classes.input }} />}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.role}
                  onChange={(event) => {
                    setFieldValue("role", event.target.value);
                  }}
                  label="selected"
                >
                  <MenuItem value="none">
                    <em>Select</em>
                  </MenuItem>
                  {roleAccessList.map((role) => {
                    return <MenuItem value={role.name}>{role.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setTab("pending");
                setFilter("pending");
              }}
              className={clsx({
                [classes.activeFilter]: filter === "pending",
              })}
            >
              PENDING
            </Button>
          </Box>
          <Box style={{ padding: "0 8px" }}>
            <Button
              variant="contained"
              onClick={() => {
                setTab("register");
                setFilter("register");
              }}
              className={clsx({
                [classes.activeFilter]: filter === "register",
              })}
            >
              REGISTERED
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setTab("suspended");
                setFilter("suspended");
              }}
              className={clsx({
                [classes.activeFilter]: filter === "suspended",
              })}
            >
              SUSPENDED
            </Button>
          </Box>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={4}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
          }}
          item
          xs={4}
        >
          <Box style={{ paddingRight: "20px" }}>Role</Box>
          <FormControl variant="outlined" className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-outlined-label">
        Select
      </InputLabel> */}
            <Select
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selected}
              onChange={(event) => {
                console.log(event.target.value);
                setSelect(event.target.value);
              }}
              // label="selected"
            >
              <MenuItem value="all">All</MenuItem>
              {roleAccessList.map((role) => {
                return <MenuItem value={role.id}>{role.name}</MenuItem>;
              })}
              {/* <MenuItem value={30}>HR</MenuItem>
        <MenuItem value={20}>User</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={4}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ width: "55%" }}
            onClick={() => {
              setMode("add");
              setModal(!modal);
              resetForm();
            }}
          >
            INVITE
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getAdminUserApiStatus.isPending}
              getDataList={getAdminUser}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {user.map((userData) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>
                        {moment(userData.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{userData.role}</TableCell>
                      <TableCell>{userData.email}</TableCell>
                      <TableCell>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    adminResend({
                                      email: userData.email,
                                      role: userData.role,
                                      type: "resend_invitation",
                                    });
                                    popupState.setOpen(false);
                                  }}
                                >
                                  Resend
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setValues({
                                      email: userData.email,
                                      // role: userData.role,
                                    });
                                    setMode("delete");
                                    setConfirmation(true);
                                    popupState.setOpen(false);
                                  }}
                                >
                                  Revoke
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default AdminDatatable;
