import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useFormik } from "formik";
import _, { valuesIn } from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../../MuiComponent/Datatable";
import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import AttachmentIcon from "../../assets/images/icons/attachment-clip.svg";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { setCategory, setParentCategory } from "../../store/actions";
import FilePicker from "../../utils/FilePicker";
import { fileupload, S3_ASSETS_PATH } from "../../utils/utility";
import { addCategoryValidation } from "../../validations/login-validate";
import assets from "../../assets";
import { useAuth } from "../../providers/AuthProvider";
import HasBusinessDetailsCheck from "../../components/HasBusinessDetailsCheck";
import { useHistory } from "react-router-dom";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const tableHeader = [
  { id: "name", align: "left", label: "NAME", minWidth: 170 },
  {
    id: "population",
    label: "HAS PARENT CATEGORY",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "PARENT CATEGORY",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function CategoryList() {
  const notification = useSnackbar();
  const classes = useStyles();
  const auth = useAuth();
  const [page, setPage] = React.useState(0);
  const [mode, setMode] = useState("add");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalDel, setModalDel] = React.useState(false);
  const [modalDelConfirm, setModalDelConfirm] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const addCategoryApiStatus = useCallbackStatus();
  const getParentCategoryListStatus = useCallbackStatus();
  const [search, setSearchKey] = useState(null);
  const [topCategory, setTopCategory] = useState(false);
  const getCategoryListStatus = useCallbackStatus();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.general);
  const history = useHistory();

  const { parentCategories, categoryList, totalPages } = useSelector(
    (state) => {
      return {
        parentCategories: state.category.parent_categories,
        categoryList: state.category.categories.data,
        totalPages: state.category.categories.total,
      };
    }
  );

  let {
    values,
    touched,
    errors,
    resetForm,
    handleBlur,
    setValues,
    submitForm,
    setFieldError,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      parent_category_id: "select category",
      is_in_top: "N",
      image_name: "",
      image: "",
    },
    onSubmit: () => {
      if (mode == "edit") {
        updateCategory();
      } else if (mode == "delete") {
        deleteCategory();
      } else addCategory();
      // login()
    },
    validationSchema: addCategoryValidation,
  });

  const updateCategory = async () => {
    let apiBody = {
      name: values.name,
      parent_category_id: values.parent_category_id,
      has_subcategories: "N",
      is_in_top: values.is_in_top,
    };

    if (apiBody.parent_category_id == "select category")
      delete apiBody.parent_category_id;

    if (typeof values.image == "object") {
      setIsFileUploading(true);

      let result = await fileupload({
        file: values.image,
        folder: S3_ASSETS_PATH("category", userData),
        disk: "s3_private",
      });
      apiBody.image = result.path;
    }

    await addCategoryApiStatus.run(
      httpClient("PUT", `/partner/category/${values.id}`, {
        body: apiBody,
      }).then((result) => {
        setIsFileUploading(false);
        setModal(false);
        getCategoryApiCall();
        resetForm();
        const {
          data: { data, total },
        } = result;
        notification.enqueueSnackbar("Update Successfully", {
          variant: "success",
        });
      })
    );
  };

  const deleteCategory = async () => {
    await addCategoryApiStatus.run(
      httpClient("DELETE", `/partner/category/${values.id}`, {
        body: {},
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModalDel(false);
          getCategoryApiCall();
          notification.enqueueSnackbar("Category Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);
          notification.enqueueSnackbar(er.errors, {
            variant: "error",
          });
        })
    );
  };

  const addCategory = async () => {
    let apiBody = {
      name: values.name,
      is_in_top: values.is_in_top,
      has_subcategories: "N",
    };

    if (values.parent_category_id != "select category")
      apiBody.parent_category_id = values.parent_category_id;

    setIsFileUploading(true);

    let result = await fileupload({
      file: values.image,
      folder: S3_ASSETS_PATH("category", userData),
      disk: "s3_private",
    });

    apiBody.image = result.path;

    await addCategoryApiStatus.run(
      httpClient("POST", "/partner/category", {
        body: apiBody,
      }).then((result) => {
        setIsFileUploading(false);
        console.log("Category Added: ", result);
        setModal(false);
        getCategoryApiCall();
        resetForm();
      })
    );
  };

  const getCategoryApiCall = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      page_size: pageSize,
      pagination_required: true,
      search_string: search,
      top_categories: topCategory,
      business_id: auth.userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getCategoryListStatus.run(
      httpClient("POST", "/common/category", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS: ", total, data);
        dispatch(setCategory(result.data));

        // setCategoriesList(data)
        // setTotalPages(total)
      })
    );
  };

  const getParentCategorories = async (pageSize = 10, pageNumber = 1) => {
    await getParentCategoryListStatus.run(
      httpClient("POST", "/common/get-parent-categories", {
        body: {},
      }).then((result) => {
        console.log("Result All Parent Cateogries: ", result);
        dispatch(setParentCategory(result.data));
      })
    );
  };

  useEffect(() => {
    if (parentCategories.length == 0) getParentCategorories();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search != null) getCategoryApiCall();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, topCategory]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addNewCategory = () => {
    if (
      _.isNull(userData.businessDetails) ||
      _.isNull(userData?.businessDetails?.account_details)
    )
      setConfirmation(true);
    else {
      setMode("add");
      setModal(!modal);
    }
  };

  const ModalHeader = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{mode == "edit" ? "Edit" : "Add"} Category</Box>
          <Box textAlign="right">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item style={{ fontSize: 16 }}>
                Top Category
              </Grid>
              <Grid item>
                <AntSwitch
                  checked={values.is_in_top == "Y" ? true : false}
                  onChange={() => {
                    values.is_in_top = values.is_in_top == "Y" ? "N" : "Y";
                    console.log("CHECBOX VALUE: ", values);
                    setValues({ ...values });
                  }}
                  name="checkedC"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };
  const ModalHeaderDel = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{mode == "edit" ? "Edit" : "Add"} Delete</Box>
        </Box>
      </>
    );
  };

  const ModalHeaderDelConfirmation = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>You can;t delete this role</Box>
        </Box>
      </>
    );
  };

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      // validateHeightWidth({ file, height: 200, width: 200 })
      //   .then(() => {
      //     console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
      // })
      // .catch((er) => {
      //   setFieldError("image_name", er);
      // });
    }
  };

  const theme = useTheme();

  return (
    <div>
      <HasBusinessDetailsCheck
        status={confirmation}
        onClose={() => {
          if (_.isNull(userData?.businessDetails?.account_details))
            history.push("/profile/account-details");
          else history.push("/profile/business-details");
        }}
      />
      <Modal
        loading={addCategoryApiStatus.isPending || isFileUploading}
        headerComponent={ModalHeader}
        height="370px"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={() => {
          submitForm();
        }}
        closeHandler={() => {
          setModal(!modal);
          resetForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Category Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Image*
                </InputLabel>
                <FilePicker
                  fileHandler={fileHandler}
                  type="component"
                  filetype="image"
                >
                  {typeof values.image == "object" ? (
                    <img
                      style={{ width: "100px" }}
                      src={URL.createObjectURL(values.image)}
                    />
                  ) : (
                    <>
                      <img
                        style={{ width: "100px" }}
                        src={
                          values.image || assets.images.CATEGORY_DEFAULT_IMAGE
                        }
                      />
                    </>
                  )}
                </FilePicker>
                <div style={{ fontSize: "12px", color: "red" }}>
                  {errors.image}
                </div>
                {/* <TextField
                  id="image"
                  name="image"
                  variant="outlined"
                  placeholder="upload file"
                  value={values.image_name}
                  helperText={errors.image_name}
                  error={errors.image_name}
                  //   onChange={handleChange}
                  onBlur={handleBlur}
                  //   disabled={values && values.image ? true : false}
                  InputProps={{
                    endAdornment: (
                      <FilePicker
                        fileHandler={fileHandler}
                        type="iconButton"
                        icon={AttachmentIcon}
                        filetype="image"
                      />
                    ),
                  }}
                /> */}
              </Box>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Select Sub-Category
                </InputLabel>
                <Select
                  id="parent_category_id"
                  name="parent_category_id"
                  error={
                    touched.parent_category_id && errors.parent_category_id
                  }
                  helperText={
                    touched.parent_category_id && errors.parent_category_id
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.parent_category_id}
                  variant="outlined"
                  className={globalClasses.selectInput}
                >
                  <MenuItem disabled value="select category">
                    Select Category
                  </MenuItem>
                  {parentCategories.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Modal
        headerComponent={ModalHeaderDel}
        height="180px"
        buttonAlign="center"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        loading={addCategoryApiStatus.isPending}
        saveHandler={() => {
          submitForm();
        }}
        closeHandler={() => {
          setModalDel(!modalDel);
          // resetForm();
        }}
        status={modalDel}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Are You sure you want to delete.</Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Modal
        headerComponent={ModalHeaderDelConfirmation}
        height="160px"
        width="100%"
        closeBtnText="OKAY"
        // saveBtnText="OKAY"
        saveHandler={() => {
          submitForm();
        }}
        closeHandler={() => {
          setModalDelConfirm(!modalDelConfirm);
          // resetForm();
        }}
        status={modalDelConfirm}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                You can't delete this category because it products listed,
                please shift to the other categories
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
          <Select
            className={globalClasses.selectInput}
            style={{ marginLeft: "20px", paddingRight: "20px" }}
            labelId="demo-simple-select-outlined-label"
            value={topCategory}
            onChange={(e) => setTopCategory(e.target.value)}
            variant="outlined"
          >
            <MenuItem value={false}>All Category</MenuItem>
            <MenuItem value={true}>Top Category</MenuItem>
          </Select>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button variant="contained" color="primary" onClick={addNewCategory}>
            Add Category
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getCategoryListStatus.isPending}
              getDataList={getCategoryApiCall}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {categoryList.map((category, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>
                      {category.has_subcategories == "Y" ? "YES" : "NO"}
                    </TableCell>
                    <TableCell>
                      {_.isNull(category.parent_category)
                        ? "-"
                        : category.parent_category.name}
                    </TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: category.id,
                                    name: category.name,
                                    image_name: category.image_name,
                                    image: category.image,
                                    is_in_top: category.is_in_top,
                                    parent_category_id: _.isNull(
                                      category.parent_category_id
                                    )
                                      ? "select category"
                                      : category.parent_category_id,
                                  });
                                  setMode("edit");
                                  setModal(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: category.id,
                                    name: category.name,
                                  });
                                  setMode("delete");

                                  if (category.product_count > 0)
                                    setModalDelConfirm(true);
                                  else {
                                    setValues(category);
                                    setModalDel(true);
                                  }
                                  popupState.setOpen(false);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default CategoryList;
