const { SET_CATEGORIES, SET_PARENT_CATEGORIES } = require("../constants")

let initialState = {
    categories: {
        data:[],
        total:0
    },
    parent_categories: []
}

const CategoryReducer = (state = initialState, action) => {
    let { payload } = action

    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: payload
            }
        case SET_PARENT_CATEGORIES:
            return {
                ...state,
                parent_categories: payload
            }
        default:
            return state
    }
}

export default CategoryReducer;