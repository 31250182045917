import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Email, Phone } from "@material-ui/icons";
import clsx from "clsx";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import {
  cmsAboutTabValidation,
  cmsContactTabValidation,
} from "../validations/login-validate";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "14px",
    fontWeight: 600,
  },
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  root: {
    border: "1px solid #ccc",
    margin: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ccc",
    padding: "1rem",
  },
  action: {
    color: theme.palette.primary.main_secondary,
    fontSize: "20px",
    textTransform: "none",
  },
  content: {
    padding: theme.spacing(4),
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 3),
  },
  icon: {
    color: "#C3C3C3",
  },
}));

function AboutUs() {
  const classes = useStyles();
  const [tab, setTab] = useState("about");
  const [isEditMode, setEditMode] = useState(false);
  const globalClasses = useGeneralStyle();
  const notification = useSnackbar();
  const getCmsApiStatus = useCallbackStatus();

  let {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    handleChange,
    setValues,
    ...rest
  } = useFormik({
    initialValues: {
      phone: "",
      email: "",
      note: "",
    },
    onSubmit: () => {
      updatedCmsPages();
    },
    validationSchema: cmsContactTabValidation,
  });

  let aboutformFormik = useFormik({
    initialValues: {
      about: "",
    },
    onSubmit: () => {
      updatedCmsPages();
    },
    validationSchema: cmsAboutTabValidation,
  });

  const updatedCmsPages = async () => {
    let apiBody = {};

    apiBody = {
      ...values,
      ...aboutformFormik.values,
    };

    console.log("Update CMS apiBody: ", {
      type: "about_contact",
      data: apiBody,
    });

    await getCmsApiStatus.run(
      httpClient("POST", "/admin/updateCmsPages", {
        body: {
          type: "about_contact",
          data: apiBody,
        },
      })
        .then((result) => {
          // console.log("Result: ",result)

          notification.enqueueSnackbar("Details Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  const getCmsPages = async () => {
    await getCmsApiStatus.run(
      httpClient("POST", "/common/getCmsPages", {
        body: {
          type: "about_contact",
        },
      })
        .then((result) => {
          const {
            data: { content },
          } = result;
          console.log("Result: ", content);
          setValues(content);
          aboutformFormik.setValues({ about: content.about });
          // auth.setUser(result.data)

          // notification.enqueueSnackbar('Login Successfully', {
          //     variant: 'success',
          // });

          // history.replace(from);
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  useEffect(() => {
    getCmsPages();
  }, []);

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Company info
        </Typography>
      </Header>

      <Paper elevation={0} className={classes.root}>
        <Grid container>
          <Grid style={{ borderBottom: "1px solid #c9c9c9" }} item xs={12}>
            <Box display="flex">
              <Box style={{ margin: "10px 10px", marginLeft: "10px" }}>
                <Button
                  disableElevation
                  onClick={() => {
                    setTab("about");
                    setEditMode(false);
                  }}
                  className={clsx({ [classes.activeFilter]: tab === "about" })}
                  variant="contained"
                >
                  About us
                </Button>
              </Box>
              <Box style={{ margin: "10px 10px", marginLeft: "10px" }}>
                <Button
                  disableElevation
                  onClick={() => {
                    setTab("contact");
                    setEditMode(false);
                  }}
                  className={clsx({
                    [classes.activeFilter]: tab === "contact",
                  })}
                  variant="contained"
                >
                  Contact us
                </Button>
              </Box>
              <Box
                flexGrow={1}
                alignSelf="center"
                textAlign="end"
                style={{ margin: "10px 10px", marginLeft: "10px" }}
              >
                {isEditMode ? (
                  <>
                    <Link
                      className={globalClasses.link}
                      style={{ paddingRight: "15px", color: "black" }}
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Link>
                    <Link
                      className={globalClasses.link}
                      onClick={() => {
                        if (tab == "about") aboutformFormik.submitForm();
                        else submitForm();

                        setEditMode(false);
                      }}
                    >
                      Save
                    </Link>
                  </>
                ) : (
                  <a
                    className={globalClasses.link}
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </a>
                )}
              </Box>
            </Box>
          </Grid>
          {tab === "about" ? (
            <>
              <Grid
                item
                xs={12}
                style={{ borderRight: "1px solid #c9c9c9", padding: "10px" }}
              >
                <TextField
                  id="about"
                  name="about"
                  error={
                    aboutformFormik.touched.about &&
                    aboutformFormik.errors.about
                  }
                  helperText={
                    aboutformFormik.touched.about &&
                    aboutformFormik.errors.about
                  }
                  onChange={aboutformFormik.handleChange}
                  onBlur={aboutformFormik.handleBlur}
                  value={aboutformFormik.values.about}
                  inputProps={{ style: { padding: "0px" } }}
                  disabled={!isEditMode}
                  placeholder="about"
                  multiline
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                container
                xs={6}
                style={{ borderRight: "1px solid #c9c9c9", padding: "10px" }}
              >
                <Grid className={classes.heading} item xs={12}>
                  Note
                </Grid>
                <TextField
                  id="note"
                  inputProps={{ style: { padding: "0px" } }}
                  name="note"
                  error={touched.note && errors.note}
                  helperText={touched.note && errors.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.note}
                  disabled={!isEditMode}
                  multiline
                  variant="outlined"
                />
              </Grid>
              <Grid
                container
                item
                xs={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <Box
                  className={classes.heading}
                  style={{ marginBottom: "10px" }}
                >
                  Contact and Email
                </Box>
                <Box>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Email
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        id="email"
                        name="email"
                        error={touched.email && errors.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={!isEditMode}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email
                                fontSize="small"
                                className={classes.icon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Contact No
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        id="phone"
                        name="phone"
                        error={touched.phone && errors.phone}
                        helperText={touched.phone && errors.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        disabled={!isEditMode}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone
                                fontSize="small"
                                className={classes.icon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* <Grid className={classes.heading} style={{ marginBottom: "10px" }} item xs={12}>
                                        Contact and Email
                                    </Grid>
                                    <Grid alignItems='center' style={{ padding: "10px" }} container item xs={12}>
                                        <Grid item xs={3}>
                                            Email
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid alignItems='center' style={{ padding: "10px" }} container item xs={12}>
                                        <Grid item xs={3}>
                                            Contact No
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField variant="outlined" />
                                        </Grid>
                                    </Grid> */}
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
}

export default AboutUs;
