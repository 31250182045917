import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useFormik } from "formik";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";
import Datatable from "../MuiComponent/Datatable";
import Modal from "../MuiComponent/Modal";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import AttachmentIcon from "../assets/images/icons/attachment-clip.svg";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import FilePicker from "../utils/FilePicker";
import {
  addSliderValidation,
  editSliderValidation
} from "../validations/login-validate";

const tableHeader = [
  { id: "name", align: "left", label: "NAME", minWidth: 170 },
  {
    id: "size",
    label: "TYPE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "ACTIVE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "IMAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function SliderManagement({ userType }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [mode, setMode] = useState("add");
  //   const [categoryList, setCategoriesList] = useState([]);
  //   const getCategoryListStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = React.useState(false);
  const addSliderApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const getBannersApiStatus = useCallbackStatus();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [bannerList, setBannersList] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
    }
  };

  //   const getCategories = async () => {
  //     const apiBody = {
  //       pagination_required: true,
  //     };

  //     await getCategoryListStatus.run(
  //       httpClient("POST", "/common/category", {
  //         body: apiBody,
  //       }).then((result) => {
  //         console.log("Result: ", result);

  //         const { data } = result;

  //         setCategoriesList(data);
  //       })
  //     );
  //   };

  const getSliders = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
      pagination_required: true,
    };

    await getBannersApiStatus.run(
      httpClient("POST", "/common/getSliders", {
        body: apiBody,
      }).then((result) => {
        console.log("getSLiders List: ", result);

        const {
          data: { data, total },
        } = result;

        setBannersList(data);
        setTotalPages(total);
      })
    );
  };

  const editBanner = async () => {
    let fd = new FormData();
    fd.append("id", values.id);
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);

    if (typeof image == "object") fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/updateSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          getSliders();

          notification.enqueueSnackbar("Slider Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const deleteBanner = async () => {
    await addSliderApiStatus.run(
      httpClient("POST", "/admin/deleteSlider", {
        body: {
          id: values.id,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setConfirmation(false);
          getSliders();

          notification.enqueueSnackbar("Slider Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addSlider = async () => {
    let fd = new FormData();
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);
    fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/createSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          getSliders();

          notification.enqueueSnackbar("Slider Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      //   category_id: "",
      link: "",
      type: "App",
      image: "",
      active: "Y",
    },
    onSubmit: () => {
      if (mode == "edit") editBanner();
      else if (mode == "delete") deleteBanner();
      else addSlider();
    },
    validationSchema:
      mode == "edit" || mode == "delete"
        ? editSliderValidation
        : addSliderValidation,
  });

  //   useEffect(() => {
  //     getCategories();
  //   }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getSliders();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <>
      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        loading={addSliderApiStatus.isPending}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this category
      </ConfirmationModal>

      <Modal
        title={mode == "add" ? "Add Slider" : "Edit Slider"}
        height="440px"
        maxWidth="md"
        width="100%"
        closeBtnText="close"
        loading={addSliderApiStatus.isPending}
        saveBtnText="save"
        saveHandler={submitForm}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Title*
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  variant="outlined"
                  placeholder="write here"
                />

                {/* <InputLabel className={globalClasses.inputLabel}>
                  Category*
                </InputLabel>
                <Select
                  id="category_id"
                  name="category_id"
                  error={touched.category_id && errors.category_id}
                  helperText={touched.category_id && errors.category_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.category_id}
                  className={globalClasses.selectInput}
                  variant="outlined"
                >
                  {categoryList.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <InputLabel className={globalClasses.inputLabel}>
                  Link*
                </InputLabel>
                <TextField
                  id="link"
                  name="link"
                  error={touched.link && errors.link}
                  helperText={touched.link && errors.link}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.link}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  variant="outlined"
                />
              </Box>

              <InputLabel className={globalClasses.inputLabel}>
                Image*
              </InputLabel>
              <TextField
                id="image"
                name="image"
                helperText="*Template format pdf/docx and size should not be more than 2 MB."
                variant="outlined"
                placeholder="upload file"
                value={values.image_name}
                //   onChange={handleChange}
                onBlur={handleBlur}
                //   disabled={values && values.image ? true : false}
                InputProps={{
                  endAdornment: (
                    <FilePicker
                      fileHandler={fileHandler}
                      type="iconButton"
                      icon={AttachmentIcon}
                      filetype="image"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Active*
                </InputLabel>
                <Select
                  id="active"
                  name="active"
                  error={touched.active && errors.active}
                  helperText={touched.active && errors.active}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.active}
                  className={globalClasses.selectInput}
                  variant="outlined"
                >
                  <MenuItem value="Y">Yes</MenuItem>
                  <MenuItem value="N">No</MenuItem>
                </Select>

                <InputLabel className={globalClasses.inputLabel}>
                  Type*
                </InputLabel>
                <Select
                  id="type"
                  name="type"
                  error={touched.type && errors.type}
                  helperText={touched.type && errors.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.type}
                  className={globalClasses.selectInput}
                  variant="outlined"
                >
                  <MenuItem value="App">App</MenuItem>
                  <MenuItem value="Web">Web</MenuItem>
                </Select>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Slider Management
        </Typography>
      </Header>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resetForm();
              setMode("add");
              setModal(true);
            }}
          >
            Add Slider
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              getDataList={getSliders}
              totalRecords={totalPages}
              loading={getBannersApiStatus.isPending}
              tableHeader={tableHeader}
            >
              {bannerList.map((banner, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{banner.name}</TableCell>
                    <TableCell>{banner.type}</TableCell>
                    <TableCell>{banner.active == "Y" ? "Yes" : "No"}</TableCell>
                    <TableCell>{banner.image_name}</TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: banner.id,
                                    name: banner.name,
                                    link: banner.link,
                                    active: banner.active,
                                    type: banner.type,
                                    image_name: banner.image_name,
                                  });
                                  setMode("edit");
                                  setModal(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: banner.id,
                                    name: banner.name,
                                    link: banner.link,
                                    active: banner.active,
                                    type: banner.type,
                                    image_name: banner.image_name,
                                  });
                                  setMode("delete");
                                  setConfirmation(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default SliderManagement;
