import React, { useEffect, useState } from "react";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { httpClient } from "../../config/httpClient";
import { useAuth } from "../../providers/AuthProvider";
import useGeneralStyle from "../../assets/css/general";
import Datatable from "../../MuiComponent/Datatable";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Modal from "../../MuiComponent/Modal";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import TextField from "../../MuiComponent/TextField";
import _ from "lodash";

const useStyles = makeStyles((theme2) => ({
  hidden: {
    display: "none",
  },
}));

const META_PROPERTY = {
  DATE_FORMAT: "date_format",
  SUFFIX: "suffix",
  PREFIX: "prefix",
};

function TasksByService() {
  const classes = useStyles();
  const theme = useTheme();
  const fetchDataApiStatus = useCallbackStatus();
  const globalClasses = useGeneralStyle();
  const [dataHeaders, setDataHeaders] = useState([]);
  const [response, setData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [tableHeaders, setTableHeaders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalPages, setTotalPages] = useState(10);
  const auth = useAuth();
  const history = useHistory();
  const [searchable, setSearchableKey] = useState("");
  const [service] = useState(history.location.state?.service);
  const { service_id } = useParams();

  const renderData = ({ value, key }) => {
    let meta = service?.datatable_meta ? service?.datatable_meta[key] : {};

    return meta
      ? Object.keys(meta).reduce((string, property) => {
          console.log("PROPERTY:", property);
          switch (property) {
            case META_PROPERTY.DATE_FORMAT:
              string = string
                ? moment.unix(string).format(meta[META_PROPERTY.DATE_FORMAT])
                : "-";
              break;
            case META_PROPERTY.SUFFIX:
              string = `${string}${meta[META_PROPERTY.SUFFIX]}`;
              break;
            case META_PROPERTY.PREFIX:
              string = `${string}${meta[META_PROPERTY.PREFIX]}`;
            default:
              string = _.capitalize(string);
          }
          return string;
        }, value)
      : value;
  };

  const fetchData = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      page_size: pageSize,
      search_string: searchable,
      business_id: auth.userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await fetchDataApiStatus.run(
      httpClient("POST", `/partner/tasksByService/${service_id}`, {
        body: apiBody,
      }).then((result) => {
        console.log("Result All Products: ", result);

        const {
          data: { data, meta },
        } = result;

        let dataTableHeaders = [];

        let metaData = service?.datatable_meta ? service?.datatable_meta : {};

        const dataArray = Object.entries(metaData);

        // Sort the array based on the "sequence" attribute
        dataArray.sort((a, b) => a[1].sequence - b[1].sequence);

        // Convert the sorted array back to an object
        const sortedData = Object.fromEntries(dataArray);

        dataTableHeaders = Object.keys(sortedData).reduce(
          (dataTableHeaders, key) => {
            let column = service?.form_structure?.fields.find(
              (column) => column.name === key
            );

            let meta = service?.datatable_meta
              ? service?.datatable_meta[key]
              : {};

            if (!meta?.hasOwnProperty("hide")) {
              if (!dataHeaders.includes(key)) dataHeaders.push(key);
              dataTableHeaders.push({
                id: key,
                align: "left",
                label: key.replace(/_/g, " ").toUpperCase(),
                minWidth: 130,
              });
            }
            return dataTableHeaders;
          },
          []
        );

        dataTableHeaders.push(
          {
            id: "created_at",
            align: "left",
            label: "CREATED AT",
            minWidth: 130,
          },
          {
            id: "updated_at",
            align: "left",
            label: "UPDATED AT",
            minWidth: 130,
          },
          {
            id: "Manage",
            align: "left",
            label: "MANAGE",
            minWidth: 130,
          }
        );

        setDataHeaders(dataHeaders);
        setTableHeaders(dataTableHeaders);
        // console.log("dataTableHeaders:");

        setData(data);
        setTotalPages(meta.total);
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [searchable]);

  return (
    <Grid container style={{ padding: theme.spacing(3) }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
        }}
        item
        xs={5}
      >
        <Box style={{ paddingRight: "20px" }}>Search</Box>
        <TextField
          onChange={(e) => setSearchableKey(e.target.value)}
          variant="outlined"
          style={{ width: "100%" }}
          inputProps={{
            style: { padding: "12px", backgroundColor: "white" },
          }}
          placeholder="Search By Assignee"
        />
      </Grid>
      <Grid
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   padding: "10px 0px",
        // }}
        item
        xs={12}
      >
        <Paper className={classes.root}>
          <Datatable
            loading={fetchDataApiStatus.isPending}
            totalRecords={totalPages}
            tableHeader={tableHeaders}
            getDataList={fetchData}
          >
            {response.map((data, index) => {
              return (
                <TableRow key={index}>
                  {dataHeaders.map((header) => (
                    <TableCell>
                      {renderData({ key: header, value: data[header] })}
                    </TableCell>
                  ))}
                  <TableCell>{moment(data.created_at).format("D MMM Y")}</TableCell>
                  <TableCell>{moment(data.updated_at).format("D MMM Y")} </TableCell>
                  <TableCell>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <IconButton {...bindTrigger(popupState)}>
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onClick={() => {
                                // setSelectedEntry(data);
                                // // console.log("dataL",data)
                                // setOpenModal(true);
                                history.push(`/tasks/${data.id}/edit`);
                                popupState.setOpen(false);
                              }}
                            >
                              Edit
                            </MenuItem>
                            {/* <MenuItem
                          onClick={() => {
                            // if (product.status === "delivered") {
                            //   setConfirmation(true);
                            // } else {
                            //   setOpenModal(true);
                            // }
                            // setselectedData(product);
                            popupState.setOpen(false);
                          }}
                        >
                          Delete
                        </MenuItem> */}
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                </TableRow>
              );
            })}
          </Datatable>
          <Modal
            height="169px"
            width="400px"
            title="Update"
            // headerComponent={NewConfirmationModalHeader}
            status={openModal}
            // loading={actionApiStatus.isPending}
            closeBtnText="Cancel"
            saveBtnText="Yes"
            saveHandler={() => {
              alert("ok");
            }}
            closeHandler={() => {
              setOpenModal(!openModal);
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {JSON.stringify(selectedEntry)}
            </Box>
          </Modal>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default TasksByService;
