import { CircularProgress } from '@material-ui/core'
import React from 'react'

function DefaultLoader() {
    return (
        <div>
            <CircularProgress />
        </div>
    )
}

export default DefaultLoader
