import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Header from "../layouts/Header";
import TabPanel from "../MuiComponent/TabPanel";
import TransactionDatatable from "./transaction/TransactionDatatable";
import DocumentCheckedDatatable from "./transaction/DocumentCheckedDatatable";
import SubscriptionFeesDatatable from "./transaction/SubscriptionFeesDatatable";

const useStyles = makeStyles((theme) => ({
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function TransactionManagement() {
  const [filter, setFilter] = React.useState("captured");
  const classes = useStyles();

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Transaction Management
        </Typography>
      </Header>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => setFilter("captured")}
            className={clsx({
              [classes.activeFilter]: filter === "captured",
            })}
            variant="contained"
          >
            Completed
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({
              [classes.activeFilter]: filter === "refunded",
            })}
            onClick={() => setFilter("refunded")}
          >
            Refunded
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({
              [classes.activeFilter]: filter === "failed",
            })}
            onClick={() => setFilter("failed")}
          >
            Failed
          </Button>
        </Box>
      </Box>

      <TransactionDatatable filter={filter} />
    </>
  );
}

export default TransactionManagement;
