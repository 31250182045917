import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  useTheme,
  TableCell,
  IconButton,
  Menu,
  DialogContentText,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import Datatable from "../../MuiComponent/Datatable";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { httpClient } from "../../config/httpClient";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Modal from "../../MuiComponent/Modal";
import { useSnackbar } from "notistack";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import ViewPurchase from "./ViewPurchase";
import { useAuth } from "../../providers/AuthProvider";
import HasBusinessDetailsCheck from "../../components/HasBusinessDetailsCheck";
import _ from "lodash";
import { useSelector } from "react-redux";
import { currency } from "../../utils/utility";
import TasksByService from "./TasksByService";

const tableHeader = [
  { id: "name", align: "left", label: "NAME", minWidth: 130 },
  {
    id: "manage",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

const TaskDatatable = forwardRef(({ actionHandler }, ref) => {
  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  const theme = useTheme();
  const classes = useStyles();
  const auth = useAuth();
  const globalClasses = useGeneralStyle();
  const history = useHistory();
  const [productList, setProductsList] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [selectedData, setselectedData] = useState({});
  const getProductsApiStatus = useCallbackStatus();
  const actionApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const [searchable, setSearchableKey] = useState("");
  const [modalDel, setModalDel] = React.useState(false);
  const notification = useSnackbar();
  const [mode, setMode] = useState("table");
  const [billData, setBillData] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { userData } = useSelector((state) => state.general);
  const [businessCheck, setBusinessCheck] = useState(false);

  const deleteProduct = async () => {
    await actionApiStatus.run(
      httpClient("POST", `/admin/removePurchase`, {
        body: {
          id: selectedData.id,
        },
      }).then((result) => {
        notification.enqueueSnackbar("Product Deleted Successfully", {
          variant: "success",
        });
      })
    );
  };

  const fetchData = async (pageSize = 10, pageNumber = 1) => {
    console.log("userData:",userData)
    const apiBody = {
      page: pageNumber,
      page_size: pageSize,
      search_string: search,
      business_id: userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getProductsApiStatus.run(
      httpClient("POST", "/partner/tasks", {
        body: apiBody,
      }).then((result) => {
        console.log("Result All Products: ", result);

        const {
          data: { data, meta },
        } = result;

        setProductsList(data);
        setTotalPages(meta.total);
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    console.log("suppliersupplier", productList);
  }, []);

  const ModalHeaderDel = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Delete</Box>
        </Box>
      </>
    );
  };

  const ConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            Information
            {/* You can’t perform any changes this bill */}
          </Box>
        </Box>
      </>
    );
  };

  const NewConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            Confirm delete
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <HasBusinessDetailsCheck
        status={businessCheck}
        onClose={() => {
          if (_.isNull(userData?.businessDetails?.account_details))
            history.push("/profile/account-details");
          else history.push("/profile/business-details");
        }}
      />
      <Modal
        headerComponent={ModalHeaderDel}
        height="180px"
        buttonAlign="center"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        loading={actionApiStatus.isPending}
        saveHandler={() => {
          deleteProduct();
        }}
        closeHandler={() => {
          setModalDel(!modalDel);
        }}
        status={modalDel}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Are You sure you want to delete.</Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {mode == "table" ? (
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
            item
            xs={5}
          >
            <Box style={{ paddingRight: "20px" }}>Search</Box>
            <TextField
              onChange={(e) => setSearchKey(e.target.value)}
              variant="outlined"
              style={{ width: "100%" }}
              inputProps={{
                style: { padding: "12px", backgroundColor: "white" },
              }}
              placeholder="by Name"
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
              padding: "10px 0px",
            }}
            item
            xs={7}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (
                  _.isNull(userData.businessDetails) ||
                  _.isNull(userData?.businessDetails?.account_details)
                )
                  setBusinessCheck(true);
                else {
                  history.push("/tasks/add");
                  actionHandler("addRecord");
                }
              }}
            >
              Add Task
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Datatable
                loading={getProductsApiStatus.isPending}
                totalRecords={totalPages}
                tableHeader={tableHeader}
                getDataList={fetchData}
              >
                {productList.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>
                        <a
                          className={globalClasses.link}
                          style={{ color: "#2895c4" }}
                          onClick={() => {
                            // setBillData(data);
                            // setMode("view");
                            // console.log("DATA:",data)
                            history.push(`/tasks/${data.id}/view`, {
                              service: data,
                            });
                          }}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Datatable>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={5}
            >
              <Box style={{ paddingRight: "20px" }}>Search</Box>
              <TextField
                onChange={(e) => setSearchableKey(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                inputProps={{
                  style: { padding: "12px", backgroundColor: "white" },
                }}
                placeholder="Enter Here"
              />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={12}
            >
              <Paper className={classes.root}>
                <TasksByService searchable={searchable} service={billData} />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      <Modal
        height="186px"
        width="388px"
        buttonAlign="center"
        headerComponent={ConfirmationModalHeader}
        saveBtnText="okay"
        status={confirmation}
        closeHandler={() => {
          setConfirmation(!confirmation);
        }}
        saveHandler={() => {
          setConfirmation(!confirmation);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          You can’t perform any changes to the bill once it is marked as
          delivered
        </Box>
      </Modal>

      <Modal
        height="169px"
        width="388px"
        headerComponent={NewConfirmationModalHeader}
        status={openModal}
        loading={actionApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Yes"
        saveHandler={deleteProduct}
        closeHandler={() => {
          setOpenModal(!openModal);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Are you sure you want to delete this bill ?
        </Box>
      </Modal>
    </>
  );
});

export default TaskDatatable;
