import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import DefaultLoader from "../../layouts/DefaultLoader";
import DynamicForm from "../../MuiComponent/DynamicForm";
import { useAuth } from "../../providers/AuthProvider";
import { convertBackendErrorResponse } from "../../utils/utility";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  inputLabel: {
    color: "black",
    fontSize: "14px",
    padding: "10px 0px",
  },
  table: {
    minWidth: 650,
  },
  inputRootAutoComplete: {
    padding: "0px 8px !important",
  },
}));

function AddTask({ actionHandler, editMode }) {
  const globalClasses = useGeneralStyle();
  const theme = useTheme();
  const history = useHistory();
  const { userData } = useAuth();
  const [search, setSearchKey] = useState("");
  const [searchProduct, setSearchProductKey] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [servicesData, setServices] = useState([]);
  const [servicesStaff, setServiceStaff] = useState([]);
  const [productList, setProductsList] = useState([]);
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  const { task_id } = useParams();
  const getSupllierApiStatus = useCallbackStatus();
  const getProductsApiStatus = useCallbackStatus();
  const actionApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  let [billTotal, setBillTotal] = useState(0);
  let [billDiscount, setBillDiscount] = useState(0);
  const [formData, setFormData] = useState({
    fields: [],
  });
  const [formMeta, setFormMeta] = useState({});

  //   {
  //     fields: [
  //       {
  //         name: "product_id",
  //         label: "Product Id",
  //         type: "text",
  //         placeholder: "Select Product",
  //       },
  //       {
  //         name: "quantity",
  //         label: "Quantity",
  //         type: "text",
  //         placeholder: "Enter Quantity",
  //       },
  //       {
  //         name: "weight_given",
  //         label: "Weight Given",
  //         type: "text",
  //         placeholder: "Enter Weight",
  //       },
  //       {
  //         name: "date",
  //         label: "date",
  //         type: "text",
  //         placeholder: "Enter Date",
  //       },
  //       {
  //         name: "weight_recieved",
  //         label: "Weight Recieved",
  //         type: "text",
  //         placeholder: "Enter Weight",
  //       },
  //       {
  //         name: "submition_date",
  //         label: "Submittion Date",
  //         type: "text",
  //         placeholder: "Select Submittion",
  //       },
  //       {
  //         name: "chillan",
  //         label: "Chillan",
  //         type: "text",
  //         placeholder: "Enter Chillan Weight",
  //       },
  //       {
  //         name: "status",
  //         label: "Status",
  //         type: "select",
  //         enum: ["pending", "recieved", "Pending"],
  //         placeholder: "Enter Chillan Weight",
  //       },
  //     ],
  //   }

  const auth = useAuth();

  let formik = useFormik({
    initialValues: {
      service_id: "",
      staff_id: "",
      form_input: {},
      note: "",
    },
    onSubmit: async () => {
      Object.keys(values.form_input).map((key) => {
        let record = formData.fields.find((data) => data.name == key);
        if (
          (values.form_input[key] == null || values.form_input[key] == "") &&
          record.defaultValue
        ) {
          values.form_input[key] = record.defaultValue;
        }
      });

      if (task_id) {
        await actionApiStatus.run(
          httpClient("PUT", `/partner/update/${task_id}`, {
            body: values,
          })
            .then((result) => {
              history.goBack();
              notification.enqueueSnackbar("Task Updated Successfully", {
                variant: "success",
              });
            })
            .catch((er) => {
              // setLoader(false);
              formik.setFieldError(
                "form_input",
                convertBackendErrorResponse(er.errors)
              );
              console.log("Error:", er);
            })
        );
      } else
        await actionApiStatus.run(
          httpClient("POST", "/partner/create-task", {
            body: values,
          })
            .then((result) => {
              history.goBack();
              notification.enqueueSnackbar("Task Added Successfully", {
                variant: "success",
              });
            })
            .catch((er) => {
              // setLoader(false);
              formik.setFieldError(
                "form_input",
                convertBackendErrorResponse(er.errors)
              );
              console.log("Error:", er);
            })
        );
    },
    // validationSchema:  Yup.object().shape({
    //     service_id: Yup.string().required(),
    //     // form_input:Yup.object().shape({

    //     // }),
    //     note:Yup.string().required()
    // })
    // validationSchema:
    //   mode == "edit" || mode == "delete"
    //     ? editBannerValidation
    //     : addBannerValidation,
  });

  let {
    values,
    touched,
    errors,
    setValues,
    setErrors,
    setFieldValue,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = formik;

  const fetchData = async (pageSize = 10, pageNumber = 1) => {
    await getSupllierApiStatus.run(
      httpClient(
        "GET",
        `/partner/listServices?page_size=${pageSize}&type=partner&page=${pageNumber}&search_string=${search}&pagination_required: true&status=completed&business_id=${auth.userData.business_id}`,
        {
          body: {},
        }
      ).then((result) => {
        const {
          data: { data, meta },
        } = result;

        setServices(data);
      })
    );
  };

  const getServiceStaff = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      page_size: pageSize,
      pagination_required: true,
      search_string: search,
      business_id: userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getSupllierApiStatus.run(
      httpClient("POST", "/partner/fetchServiceStaff", {
        body: apiBody,
      })
        .then((result) => {
          console.log("Result", result);

          const {
            data: { data, meta },
          } = result;

          setServiceStaff(data);
        })
        .catch((er) => {
          console.log("Error", er);
        })
    );
  };

  const getTaskById = async (id) => {
    await getSupllierApiStatus.run(
      httpClient("POST", `/partner/tasks/${id}`, {
        body: {},
      }).then((result) => {
        const { data } = result;
        console.log("DATAL", data);
        setFieldValue(
          "form_input",
          data?.form_structure?.fields?.reduce((acc, field) => {
            acc[field.name] = data[field.name];
            return acc;
          }, {})
        );
        setFormData(data?.form_structure);
        setFormMeta(data?.datatable_meta);
        setFieldValue("service_id", data.service_id);
        setFieldValue("staff_id", data.staff_id);

        // setValues(data);
      })
    );
  };

  const getAllProductsList = async () => {
    const apiBody = {
      search_string: searchProduct,
      business_id: userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getProductsApiStatus.run(
      httpClient("POST", "/partner/getProducts", {
        body: apiBody,
      }).then((result) => {
        console.log("Result All Products: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS All Products: ", total);
        setProductsList(data);
        // setProducts(data);
      })
    );
  };

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      fetchData(50, 1);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (task_id) {
      getTaskById(task_id);
    } else console.log("add");
    getServiceStaff(50, 1);
  }, []);

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      getAllProductsList();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchProduct]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ padding: theme.spacing(3) }}
      >
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBackIosIcon /> Back
        </Button>
        {/* <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={actionApiStatus.isPending || loader}
            onClick={submitForm}
          >
            {actionApiStatus.isPending || loader ? (
              <CircularProgress size={23} />
            ) : editMode ? (
              "Update Bill"
            ) : (
              "Add Bill"
            )}
          </Button>
        </Box> */}
      </Box>
      <Box
        style={{
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }}
      >
        <Paper style={{ border: "1px solid #e0e0e0" }}>
          {servicesData.length > 0 ? (
            <>
              <Grid
                container
                spacing={2}
                style={{ padding: "15px", width: "100%" }}
              >
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    Service*
                  </InputLabel>
                  {servicesData.length > 0 ? (
                    <Autocomplete
                      disabled={task_id ? true : false}
                      classes={{ inputRoot: classes.inputRootAutoComplete }}
                      id="combo-box-demo"
                      options={servicesData}
                      defaultValue={servicesData.find(
                        (service) => service?.id == values?.service_id
                      )}
                      value={servicesData.find(
                        (service) => service?.id == values?.service_id
                      )}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "form_input",
                          newValue?.form_structure?.fields?.reduce(
                            (acc, field) => {
                              acc[field.name] = "";
                              return acc;
                            },
                            {}
                          )
                        );
                        setFormData(newValue?.form_structure);
                        setFieldValue("service_id", newValue?.id);
                      }}
                      getOptionLabel={(option) => option.name}
                      style={{ height: "12px" }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder="Select Service"
                            onChange={(event) => {
                              setSearchKey(event.target.value);
                            }}
                            variant="outlined"
                          />
                        );
                      }}
                    />
                  ) : (
                    <DefaultLoader />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel
                    style={{ marginTop: 20 }}
                    className={classes.inputLabel}
                  >
                    Assigned To*
                  </InputLabel>
                  {getSupllierApiStatus.isPending == false ? (
                    <Autocomplete
                      classes={{ inputRoot: classes.inputRootAutoComplete }}
                      id="combo-box-demo"
                      options={servicesStaff}
                      defaultValue={servicesStaff.find(
                        (staff) => staff?.id == values?.staff_id
                      )}
                      value={servicesStaff.find(
                        (staff) => staff?.id == values?.staff_id
                      )}
                      onChange={(event, newValue) => {
                        // setFieldValue(
                        //   "form_input",
                        //   newValue?.form_structure?.fields?.reduce(
                        //     (acc, field) => {
                        //       acc[field.name] = "";
                        //       return acc;
                        //     },
                        //     {}
                        //   )
                        // );
                        // setFormData(newValue?.form_structure);
                        setFieldValue("staff_id", newValue?.id);
                      }}
                      getOptionLabel={(option) => option.name}
                      style={{ height: "12px" }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder="Select Service"
                            onChange={(event) => {
                              setSearchKey(event.target.value);
                            }}
                            variant="outlined"
                          />
                        );
                      }}
                    />
                  ) : (
                    <DefaultLoader />
                  )}
                </Grid>
              </Grid>

              <DynamicForm
                loading={{
                  productList: getProductsApiStatus.isPending,
                }}
                productList={productList}
                formik={formik}
                formData={formData?.fields || []}
              />
            </>
          ) : (
            <Box padding={20} textAlign={"center"}>
              <DefaultLoader />
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
}

export default AddTask;
