// api domain
export const apiDomain = "https://beaglelawappapi.jump360.me/";

// api basepath
let apiBasePathObj;

/** DEV */
const devAPIBasePaths = {
    common: "common",
    admin:"admin",
    cms:"cms"
};

/** Test */
const testAPIBasePaths = { ...devAPIBasePaths };

/** Prod */
const prodAPIBasePaths = { ...devAPIBasePaths };

// load the appropriate base paths
switch (process.env.REACT_APP_ENVIRONMENT) {
    case "dev":
        apiBasePathObj = devAPIBasePaths;
        break;
    case "test":
        apiBasePathObj = testAPIBasePaths;
        break;
    case "prod":
        apiBasePathObj = prodAPIBasePaths;
        break;
    default:
        apiBasePathObj = devAPIBasePaths;
}

// export it
export const apiBasePath = apiBasePathObj;
