import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  useTheme
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useFormik } from "formik";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../MuiComponent/ConfirmationModal";
import Datatable from "../../MuiComponent/Datatable";
import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { faqCategoryValidation } from "../../validations/login-validate";

const tableHeader = [
  {
    id: "population",
    label: "Category Name",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "No. Of Questions",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MANAGE",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

const ConfirmationModalHeader = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" style={{ fontSize: "14px" }}>
        <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
          You can’t delete this category
        </Box>
      </Box>
    </>
  );
};

function CategoryDatatable() {
  const classes = useStyles();
  const globalClasses = useGeneralStyle();
  const addCategoryApiStatus = useCallbackStatus();
  const [categoryList, setCategoriesList] = useState([]);
  const [mode, setMode] = useState("add");
  const [search, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const getCategoryListStatus = useCallbackStatus();
  const [alertModal, setAlertModal] = useState(false);
  const notification = useSnackbar();
  const theme = useTheme();

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: () => {
      if (mode == "edit") updateCategoy();
      if (mode == "delete") deleteCategory();
      else if (mode == "add") addCategory();
    },
    validationSchema: faqCategoryValidation,
  });

  const deleteCategory = async () => {
    await getCategoryListStatus.run(
      httpClient("POST", "/admin/deleteFaqCategory", {
        body: values,
      })
        .then((result) => {
          console.log("Result: ", result);
          setConfirmation(false);
          resetForm();
          getCategoryApiCall();

          notification.enqueueSnackbar("Category Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const updateCategoy = async () => {
    await getCategoryListStatus.run(
      httpClient("POST", "/admin/updateFaqCategory", {
        body: values,
      })
        .then((result) => {
          console.log("Result: ", result);
          setModal(false);
          resetForm();

          getCategoryApiCall();

          notification.enqueueSnackbar("Category Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addCategory = async () => {
    await addCategoryApiStatus.run(
      httpClient("POST", "/admin/addFaqCategory", {
        body: values,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          getCategoryApiCall();

          notification.enqueueSnackbar("Category Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const getCategoryApiCall = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getCategoryListStatus.run(
      httpClient("POST", "/admin/listFaqCategory", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);

        const {
          data: { data, total },
        } = result;

        setCategoriesList(data);
        setTotalPages(total);
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCategoryApiCall();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const ModalHeader = () => {
    return (
      <>
        <Box textAlign="center" padding={1}>
          {mode === "edit" ? "Edit" : "Add"} Category Name
        </Box>
      </>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this category
      </ConfirmationModal>

      <Modal
        height="240px"
        width="100%"
        loading={addCategoryApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText={mode === "edit" ? "update" : "Save"}
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(false);
        }}
        saveHandler={() => {
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Category Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMode("add");
              setModal(!modal);
            }}
          >
            Add New Category
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getCategoryListStatus.isPending}
              getDataList={getCategoryApiCall}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {categoryList.map((category) => {
                return (
                  <TableRow>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.totalquestion}</TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: category.id,
                                    name: category.name,
                                  });
                                  setMode("edit");
                                  setModal(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: category.id,
                                    name: category.name,
                                  });

                                  if (category.totalquestion == 0) {
                                    setMode("delete");
                                    setConfirmation(true);
                                    popupState.setOpen(false);
                                  } else {
                                    setAlertModal(true);
                                    popupState.setOpen(false);
                                  }
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        height="170px"
        width="100%"
        buttonAlign="center"
        headerComponent={ConfirmationModalHeader}
        saveBtnText="okay"
        status={alertModal}
        closeHandler={() => {
          setAlertModal(!alertModal);
        }}
        saveHandler={() => {
          setAlertModal(!alertModal);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          You can’t delete this category because this category contains
          questions,
        </Box>
      </Modal>
    </>
  );
}

export default CategoryDatatable;
