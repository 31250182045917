import { httpClient } from "../config/httpClient";
import Resizer from "react-image-file-resizer";

export const S3_ASSETS_PATH = (key, userData) => {
  let paths = {
    category: `${userData.business_id}/category`,
    product: `${userData.business_id}/product`,
    profile: "business_profile",
  };

  return paths[key];
};

export const fileupload = ({ file, folder, disk = "public" }) => {
  let fd = new FormData();
  fd.append("file", file);
  fd.append("folder", folder);
  fd.append("disk", disk);

  return new Promise((resolve, reject) => {
    httpClient("POST", "/common/uploadFile", {
      body: fd,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateHeightWidth = ({ file, height, width }) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width === width && img.height === height) {
        resolve(true);
      } else {
        reject(`Sorry, this image doesn't look like the size we wanted. It's 
        ${img.width} x ${img.height} but we require 100 x 100 size image.`);
      }
    };
  });
};

export const resizeImageDimensions = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export function convertBackendErrorResponse(response) {
  const convertedResponse = {};
  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const value = response[key];
      if (Array.isArray(value)) {
        convertedResponse[key] = value.join(" ");
      } else {
        convertedResponse[key] = value;
      }
    }
  }
  return convertedResponse;
}

export function currency({ currency="INR", value }) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(value);
}
