import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import galleryIcon from "../assets/images/icons/gallery.svg";
import pencilIcon from "../assets/images/icons/pencil.svg";
import trashIcon from "../assets/images/icons/trash.svg";
import { USER_TOKEN } from "../config/constants";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import useCancelRequest from "../hooks/useCancelToken";
import Header from "../layouts/Header";
import Modal from "../MuiComponent/Modal";
import TabPanel from "../MuiComponent/TabPanel";
import { useAuth } from "../providers/AuthProvider";
import FilePicker from "../utils/FilePicker";
import AccountDetails from "./profile/AccountDetails";
import BusinessDetails from "./profile/BusinessDetails";
import ChangePassword from "./profile/ChangePassword";
import PersonalDetails from "./profile/PersonalDetails";
import { fileupload, S3_ASSETS_PATH } from "../utils/utility";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    margin: "23px 32px",
  },
  avatarBox: {
    position: "absolute",
    margin: "0px",
    cursor: "pointer",
    top: "82px",
    left: "92px",
  },
  settingsOptionsListItemIcon: {
    minWidth: 0,
  },
}));

const renderLoading = (data, height, width) => {
  if (data) {
    return data;
  } else {
    return (
      <Skeleton
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
    );
  }
};

const ModalHeader = () => {
  return (
    <>
      <Box textAlign="center" padding={1}>
        Business Logo
      </Box>
    </>
  );
};

function ProfileManagement() {
  const classes = useStyles();
  // const [tab, setTab] = useState("personal-details");
  const { tab } = useParams();
  const [modal, setModal] = React.useState(false);
  const auth = useAuth();
  const getUserByIdApi = useCallbackStatus();
  const apiSource = useCancelRequest();
  let [userProfile, setProfileData] = useState({});
  const updateProfileApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [profileUploadingLoader, setProfileUploadingLoader] = useState(false);
  let history = useHistory();

  // useEffect(() => {
  //   const parsed = queryString.parse(history.location.search);
  //   parsed.tab && setTab(parsed.tab);
  // }, []);

  let getUserById = useCallback(async () => {
    await getUserByIdApi.run(
      httpClient("GET", `/${process.env.REACT_APP_ACCESS_MODE}/user`)
        .then((result) => {
          console.log("RESULT:", result);
          setProfileData({
            ...result,
            first_name: result.name,
            businessDetails: result.businessDetails
              ? result.businessDetails
              : {
                  name: "",
                  email: "",
                  gst_number: "",
                  pancard: "",
                  prefix: null,
                  contact_number: "",
                  address: "",
                  state: null,
                  city: null,
                  pincode: "",
                  account_details: {
                    bank_name: "",
                    account_holder_name: "",
                    account_number: "",
                    ifsc_code: "",
                  },
                },
          });
        })
        .catch((er) => {
          console.log("Error: ", er);
        })
    );
  });

  const fileHandler = async (file, alt) => {
    userProfile = {
      ...userProfile,
      profile_picture_file: file,
    };

    setProfileData(userProfile);
  };

  const updateProfileHandler = async () => {
    let result = {};

    console.log("Profile Picture Data: ", userProfile);

    let fd = new FormData();
    // fd.append("admin", auth.getUserId());
    fd.append("id", auth.getUserId());

    if (typeof userProfile.profile_picture_file == "object") {
      let result = await fileupload({
        file: userProfile.profile_picture_file,
        folder: S3_ASSETS_PATH("profile", auth.userData),
        disk: "s3_private",
      });
      fd.append("profile_photo", result.path);
    }

    await updateProfileApiStatus.run(
      httpClient("POST", "/partner/updateProfile", {
        body: fd,
      }).then((result) => {
        const { data } = result;
        data.token = window.localStorage.getItem(USER_TOKEN);

        console.log("Result Update Admin Profile: ", result, data);
        auth.setUser(data);
        setModal(!modal);
        getUserById();

        notification.enqueueSnackbar("Profile Updated Successfully", {
          variant: "success",
        });
      })
    );
    // setProfileUploadingLoader(true)

    // if(_.isUndefined(userProfile.profilePicturePath)){
    //     result = await updateProfileApiStatus.run(
    //         apiClient("POST", 'admin', 'editadminprofile', {
    //             body: {
    //                 admin_id: auth.getUserId(),
    //                 profile_picture: null,
    //             },
    //             shouldUseDefaultToken: false,
    //             cancelToken: apiSource.token,
    //             enableLogging: true
    //         })
    //     )
    // }else
    // {
    //     let signedObject = await awsLinkGenerate(userProfile.profilePicturePath, 'image', 'profile_pictures', auth.getUserId())
    //     let isUploaded=await awsFileUpload(signedObject.signedUrl, signedObject.file)

    //     if(isUploaded.status===200)
    //         setProfileUploadingLoader(false)

    //     result = await updateProfileApiStatus.run(
    //         apiClient("POST", 'admin', 'editadminprofile', {
    //             body: {
    //                 admin_id: auth.getUserId(),
    //                 profile_picture: signedObject.signedUrl.split('?')[0],
    //             },
    //             shouldUseDefaultToken: false,
    //             cancelToken: apiSource.token,
    //             enableLogging: true
    //         })
    //     )
    // }

    // const { content: { data } } = result

    // data.secret = window.localStorage.getItem(USER_TOKEN)
    // auth.setUser(data)
    // getUserById()
    // setModal(!modal)
  };

  useEffect(() => {
    getUserById();
  }, []);

  return (
    <>
      <Modal
        height="340px"
        width="340px"
        loading={profileUploadingLoader || updateProfileApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Done"
        closeBtnText="Cancel"
        closeHandler={() => {
          setModal(!modal);
        }}
        saveHandler={updateProfileHandler}
        status={modal}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <Avatar
              src={
                typeof userProfile.profile_picture_file == "object" &&
                !_.isNull(userProfile.profile_picture_file)
                  ? URL.createObjectURL(userProfile.profile_picture_file)
                  : userProfile.profile_photo_url
              }
              className={classes.avatar}
            />
          </Box>
          <Box display="flex">
            <Box
              style={{ borderRight: "2px solid #858593", paddingRight: "10px" }}
            >
              <FilePicker
                multiple={false}
                filetype="image"
                fileHandler={fileHandler}
                type="iconButton"
                icon={galleryIcon}
              />
            </Box>
            <Box>
              <IconButton
                onClick={() => fileHandler(null)}
                size="small"
                style={{ paddingLeft: "10px" }}
              >
                <img height="20px" src={trashIcon} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          My Profile
        </Typography>
      </Header>
      <Box style={{ padding: "12px" }}>
        <Box style={{ padding: "12px 0px" }}>
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBackIosIcon style={{ height: "15px" }} /> Back
          </Button>
        </Box>
        <Grid
          style={{ backgroundColor: "white", border: "1px solid #d3d3d3" }}
          container
        >
          <Grid item xs={12} container>
            <Grid item xs={12} style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Box display="flex" alignItems="center">
                <Box style={{ position: "relative" }}>
                  <Avatar
                    src={userProfile.profile_photo_url}
                    className={classes.avatar}
                  />
                  <img
                    className={classes.avatarBox}
                    src={pencilIcon}
                    onClick={() => setModal(!modal)}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    component="span"
                    style={{ fontSize: "17px", fontWeight: 600 }}
                  >
                    {renderLoading(userProfile.first_name, 30, 150)}
                  </Box>
                  <Box component="span" style={{ color: "#63646c" }}>
                    {renderLoading(userProfile.email, 30, 100)}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{ borderRight: "1px solid #d3d3d3" }}
            >
              <List>
                <ListItem
                  button
                  selected={tab === "personal-profile"}
                  onClick={() => history.replace("/profile/personal-profile")}
                >
                  <ListItemText primary="Personal Details" />
                  <ListItemIcon className={classes.settingsOptionsListItemIcon}>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem
                  button
                  selected={tab === "change-password"}
                  onClick={() => history.replace("/profile/change-password")}
                >
                  <ListItemText primary="Change Password" />
                  <ListItemIcon className={classes.settingsOptionsListItemIcon}>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem
                  button
                  selected={tab === "business-details"}
                  onClick={() => history.replace("/profile/business-details")}
                >
                  <ListItemText primary="Business Details" />
                  <ListItemIcon className={classes.settingsOptionsListItemIcon}>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem
                  button
                  selected={tab === "account-details"}
                  onClick={() => history.replace("/profile/account-details")}
                >
                  <ListItemText primary="Account Details" />
                  <ListItemIcon className={classes.settingsOptionsListItemIcon}>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TabPanel value={tab} index="personal-profile">
                <PersonalDetails
                  loading={getUserByIdApi.isPending}
                  refresh={getUserById}
                  profileData={userProfile}
                />
              </TabPanel>
              <TabPanel value={tab} index="change-password">
                <ChangePassword />
              </TabPanel>
              <TabPanel value={tab} index="business-details">
                <BusinessDetails
                  loading={getUserByIdApi.isPending}
                  refresh={getUserById}
                  data={userProfile.businessDetails}
                />
              </TabPanel>
              <TabPanel value={tab} index="account-details">
                <AccountDetails
                  loading={getUserByIdApi.isPending}
                  refresh={getUserById}
                  data={userProfile.businessDetails}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ProfileManagement;
