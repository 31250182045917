import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Header from "../layouts/Header";
import TabPanel from "../MuiComponent/TabPanel";
import CategoryDatatable from "./faq/CategoryDatatable";
import QuestionDatatable from "./faq/QuestionDatatable";

const useStyles = makeStyles((theme2) => ({
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function FAQManagement() {
  const [filter, setFilter] = React.useState("categories");
  const classes = useStyles();

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Company info
        </Typography>
      </Header>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => setFilter("categories")}
            className={clsx({
              [classes.activeFilter]: filter === "categories",
            })}
            variant="contained"
          >
            CATEGORIES
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({ [classes.activeFilter]: filter === "questions" })}
            onClick={() => setFilter("questions")}
          >
            Questions
          </Button>
        </Box>
      </Box>

      <TabPanel value={filter} index="categories">
        <CategoryDatatable />
      </TabPanel>

      <TabPanel value={filter} index="questions">
        <QuestionDatatable />
      </TabPanel>
    </>
  );
}

export default FAQManagement;
