import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  makeStyles,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextField from "../../MuiComponent/TextField";
import { useFormik } from "formik";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { httpClient } from "../../config/httpClient";
import { useSnackbar } from "notistack";
import { currency } from "../../utils/utility";
import { useAuth } from "../../providers/AuthProvider";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  inputLabel: {
    color: "black",
    fontSize: "14px",
    padding: "10px 0px",
    fontWeight: "600",
  },
  table: {
    minWidth: 650,
  },
  inputRootAutoComplete: {
    padding: "0px 8px !important",
  },
}));

let billTotal = 0;
let billDiscount = 0;

function ViewPurchase({ data, mode, setMode }) {
  const classes = useStyles();
  const theme = useTheme();
  const { userData } = useAuth();
  const history = useHistory();
  const actionApiStatus = useCallbackStatus();
  const notification = useSnackbar();

  const updateStatus = async (apiBody) => {
    await actionApiStatus.run(
      httpClient("POST", "/partner/updatePurchase", {
        body: apiBody,
      }).then((result) => {
        console.log("Result All Products: ", result);
        setMode("table");
        notification.enqueueSnackbar("Status Updated Successfully", {
          variant: "success",
        });
      })
    );
  };

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        status: data.status,
        note: data.note,
        reference_number: data.reference_number,
      },
      onSubmit: () => {
        console.log("apibody: ", values);
        updateStatus({
          id: data.id,
          ...values,
        });
      },
      // validationSchema: resetPasswordValidation,
    });

  useEffect(() => {
    console.log("data View Purchase: ", data);
  }, [data]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ padding: theme.spacing(3) }}
      >
        <Button
          onClick={() => {
            // history.goBack();
            setMode("table");
          }}
        >
          <ArrowBackIosIcon /> Back
        </Button>
        <Box>
          {mode == "edit" && (
            <Button onClick={submitForm} variant="contained" color="primary">
              Update Bill
            </Button>
          )}
        </Box>
      </Box>

      <Box
        style={{
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }}
      >
        <Paper style={{ border: "1px solid #e0e0e0" }}>
          <Grid
            container
            spacing={2}
            style={{ padding: "15px", width: "100%" }}
          >
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>Supplier*</InputLabel>
              <Box>{data.business_name}</Box>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Reference Number*
              </InputLabel>
              <Box>
                {mode == "view" ? (
                  values.reference_number
                ) : (
                  <TextField
                    fullWidth
                    id="reference_number"
                    name="reference_number"
                    variant="outlined"
                    placeholder="80"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reference_number}
                    inputProps={{
                      style: {
                        padding: "8px",
                        // width: "50px",
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Purchase Date*
              </InputLabel>
              <Box>{moment(data.purchase_date).format("MM-DD-YYYY")}</Box>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Purchase Status*
              </InputLabel>
              {mode == "view" ? (
                values.status
              ) : (
                <Select
                  id="status"
                  name="status"
                  error={touched.status && errors.status}
                  helperText={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                  variant="outlined"
                  style={{ width: "100%", height: "35px" }}
                  placeholder="Status"
                >
                  <MenuItem value="ordered">Ordered</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                </Select>
              )}
            </Grid>
            <Box padding="0 10px" fontWeight="600">
              Products
            </Box>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Unit Cost</TableCell>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">Rate</TableCell>
                      <TableCell align="right">Total (Excl. GST)</TableCell>
                      <TableCell align="right">Tax</TableCell>
                      <TableCell align="right">Total (Incl. GST)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.products.map((Product, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {Product.product_name}
                          </TableCell>
                          <TableCell align="right">
                            {Product.ordered_quantity}
                          </TableCell>
                          <TableCell align="right">
                            {/* <TextField
                                type="number"
                                id="unit_cost"
                                name="unit_cost"
                                variant="outlined"
                                placeholder="20"
                                disabled
                                inputProps={{
                                  style: {
                                    padding: "8px",
                                    width: "50px",
                                    fontSize: "14px",
                                  },
                                }}
                              ></TextField> */}
                            {currency({
                              currency: userData.currency,
                              value: Product.unit_cost,
                            })}
                          </TableCell>
                          <TableCell align="right">
                            {Product.discount}%
                          </TableCell>
                          <TableCell align="right">
                            {(() => {
                              let price = Product.unit_cost;
                              let discount = Product.discount;

                              Product.rate = price - (price * discount) / 100;
                              return currency({
                                currency: userData.currency,
                                value: Product.rate,
                              });
                            })()}
                          </TableCell>
                          <TableCell align="right">
                            {currency({
                              currency: userData.currency,
                              value: Product.rate * Product.ordered_quantity,
                            })}
                          </TableCell>
                          <TableCell align="right">
                            {currency({
                              currency: userData.currency,
                              value:
                                Product.rate *
                                (Product.tax / 100) *
                                Product.ordered_quantity,
                            })}{" "}
                            ({Product.tax}
                            %)
                          </TableCell>
                          <TableCell align="right">
                            {(() => {
                              Product.incl_gst_total =
                                Product.rate * Product.ordered_quantity +
                                Product.rate *
                                  (Product.tax / 100) *
                                  Product.ordered_quantity;

                              return currency({
                                currency: userData.currency,
                                value: Product.incl_gst_total,
                              });
                            })()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box paddingRight="45px">Net Amount</Box>
              <Box>
                {currency({
                  currency: userData.currency,
                  value: data.total,
                })}
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              padding: "15px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Discount Type
              </InputLabel>
              <Box>{data.discount_type}</Box>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Discount Value*
              </InputLabel>
              <Box>{data.discount_value}</Box>
            </Grid>

            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box paddingRight="45px">Discount(-)</Box>
              <Box>
                {currency({
                  currency: userData.currency,
                  value: data.discount_amount,
                })}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box paddingRight="45px">Net Payable</Box>
              <Box>
                {currency({
                  currency: userData.currency,
                  value: data.total - data.discount_amount,
                })}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>
                Shipping Details*
              </InputLabel>
              <Box>{data.shipping_details}</Box>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <InputLabel
                className={classes.inputLabel}
                style={{ paddingRight: "82px" }}
              >
                Shipping Value (+)
              </InputLabel>
              <Box>
                {currency({
                  currency: userData.currency,
                  value: data.shipping_cost,
                })}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                Additional Note
              </InputLabel>
              <Box>
                {mode == "view" ? (
                  values.note
                ) : (
                  <TextField
                    fullWidth
                    multiline
                    id="note"
                    name="note"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.note}
                    inputProps={{
                      style: {
                        height: "89px",
                        padding: "8px",
                        // width: "50px",
                        fontSize: "14px",
                      },
                    }}
                  />
                )}

                {/* This Bill contains shipping charges but not mentioned with bill */}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default ViewPurchase;
