import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import TextField from "../../MuiComponent/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  adminProfileForm,
  businessProfileValidation,
} from "../../validations/login-validate";
import PeopleIcon from "../../assets/images/icons/people.svg";
import { USER_TOKEN } from "../../config/constants";
import { httpClient } from "../../config/httpClient";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, setUser as setUserGlobal } from "../../store/actions";
import { selectCities, selectStates } from "../../store/selectors";

const useStyles = makeStyles(() => ({
  inputRootAutoComplete: {
    height: "43px",
    padding: "0px 8px !important",
  },
}));

function BusinessDetails({ data, refresh, loading }) {
  const globalClasses = useGeneralStyle();
  const theme = useTheme();
  const notification = useSnackbar();
  const updateProfileApiStatus = useCallbackStatus();
  const statesList = useSelector(selectStates);
  const citiesList = useSelector(selectCities);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [countries, setCountries] = useState([
    { dial_code: "+91", name: "India" },
  ]);

  const {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    setFieldValue,
    setValues,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      gst_number: "",
      pancard: "",
      prefix: "",
      contact_number: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
    },
    validationSchema: businessProfileValidation,
    onSubmit: () => {
      updateProfile();
    },
  });

  useEffect(() => {
    if (data) setValues({ ...data });
  }, [data]);

  let updateProfile = async () => {
    await updateProfileApiStatus.run(
      httpClient("POST", "/partner/updateBusinessDetails", {
        body: {
          id: values.id,
          ...values,
          //   phone_number: values.phone_number.replace(/[-\s]/g, ""),
        },
      }).then((result) => {
        console.log("Result Update Admin Profile: ", result);
        const { data } = result;

        httpClient("GET", `/${process.env.REACT_APP_ACCESS_MODE}/user`, {
          body: {},
        })
          .then((result) => {
            dispatch(setUserGlobal(result));
          })
          .catch((er) => {
            notification.enqueueSnackbar(er.message, {
              variant: "error",
            });
          });

        refresh();

        notification.enqueueSnackbar("Profile Updated Successfully", {
          variant: "success",
        });
      })
    );
  };

  if (loading) {
    return (
      <Box
        style={{
          textAlign: "center",
          height: "50vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Legal Name of Business
            </InputLabel>
            <TextField
              id="name"
              name="name"
              error={touched.name && errors.name}
              helperText={touched.name && errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>Email</InputLabel>
            <TextField
              id="email"
              name="email"
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Business ID
            </InputLabel>
            <TextField
              id="gst_number"
              name="gst_number"
              error={touched.gst_number && errors.gst_number}
              helperText={touched.gst_number && errors.gst_number}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.gst_number}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="GSTIN / Business License"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              User Proff
            </InputLabel>
            <TextField
              id="pancard"
              name="pancard"
              error={touched.pancard && errors.pancard}
              helperText={touched.pancard && errors.pancard}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.pancard}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="PAN CARD / CIVIL ID"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Contact Number
            </InputLabel>
            <Box display={"flex"}>
              <Box marginRight={2}>
                {values.prefix !== "" && (
                  <Autocomplete
                    classes={{ inputRoot: classes.inputRootAutoComplete }}
                    id="combo-box-demo"
                    defaultValue={countries.find(
                      (country) => country.dial_code == values.prefix
                    )}
                    value={countries.find(
                      (country) => country.dial_code == values.prefix
                    )}
                    options={countries}
                    onChange={(event, newValue) => {
                      setFieldValue("prefix", newValue?.dial_code);
                    }}
                    getOptionLabel={(option) =>
                      `(${option.dial_code}) ${option.name}`
                    }
                    style={{ height: "50px", width: "150px" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="+91 (India)"
                          onChange={(event) => {
                            //setSearchKey(event.target.value);
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                )}
              </Box>
              <Box>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  error={touched.contact_number && errors.contact_number}
                  helperText={touched.contact_number && errors.contact_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="people-icon" src={PeopleIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/* <InputLabel className={globalClasses.inputLabel}>
              Contact Number
            </InputLabel>
            <MuiPhoneNumber
              id="contact_number"
              name="contact_number"
              error={touched.contact_number && errors.contact_number}
              helperText={touched.contact_number && errors.contact_number}
              value={values.contact_number}
              defaultCountry={"in"}
              variant="outlined"
              onChange={(v) => {
                rest.setValues({ ...values, contact_number: v });
                console.log("PHone Numbert: ", v.replace(/[-\s]/g, ""));
              }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Phone No (Optional)"
            /> */}
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Address
            </InputLabel>
            <TextField
              id="address"
              name="address"
              error={touched.address && errors.address}
              helperText={touched.address && errors.address}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>State</InputLabel>
            {values.state !== "" && (
              <Autocomplete
                classes={{ inputRoot: classes.inputRootAutoComplete }}
                id="combo-box-demo"
                defaultValue={values.state}
                value={statesList.find((state) => state.id == values.state)}
                options={statesList}
                onChange={(event, newValue) => {
                  setFieldValue("state", newValue?.id);
                  dispatch(
                    fetchCities({
                      state_id: newValue?.id,
                    })
                  );
                }}
                getOptionLabel={(option) => option.name}
                style={{ height: "50px" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select State"
                      onChange={(event) => {
                        //setSearchKey(event.target.value);
                      }}
                      variant="outlined"
                    />
                  );
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>City</InputLabel>
            {values.city !== "" && (
              <Autocomplete
                classes={{ inputRoot: classes.inputRootAutoComplete }}
                id="combo-box-demo"
                defaultValue={values.city}
                value={citiesList.find((city) => city.id == values.city)}
                options={citiesList}
                onChange={(event, newValue) => {
                  setFieldValue("city", newValue?.id);
                }}
                getOptionLabel={(option) => option.name}
                style={{ height: "50px" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select State"
                      onChange={(event) => {
                        //setSearchKey(event.target.value);
                      }}
                      variant="outlined"
                    />
                  );
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <InputLabel className={globalClasses.inputLabel}>
              Pincode
            </InputLabel>
            <TextField
              id="pincode"
              name="pincode"
              error={touched.pincode && errors.pincode}
              helperText={touched.pincode && errors.pincode}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.pincode}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder="write here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="people-icon" src={PeopleIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={updateProfileApiStatus.isPending ? true : false}
            onClick={submitForm}
          >
            {updateProfileApiStatus.isPending ? (
              <CircularProgress size={23} />
            ) : (
              "Update"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BusinessDetails;
