import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import { makeStyles } from "@material-ui/styles";
import TextField from "../../MuiComponent/TextField";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getFormStructure, modifyFormStructure } from "../../store/actions";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { selectFormStructure } from "../../store/selectors";
import { formStructureValidation } from "../../validations/login-validate";

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12px 14px",
    },
  },
}));

function FormFieldManagement() {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { business_id } = useParams();
  const formStructure = useSelector(selectFormStructure);

  useEffect(() => {
    dispatch(
      getFormStructure({
        business_id,
      })
    );
  }, []);

  const {
    values,
    touched,
    errors,
    submitForm,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      table_name: "",
      business_id,
      fields: [
        {
          name: "",
          type: "text",
          label: "",
          sequence: 0,
          placeholder: "",
        },
      ],
      validations: {
        apartment: "required",
        flat_number: "required",
      },
    },
    validationSchema: formStructureValidation,
    onSubmit: () => {
      dispatch(
        modifyFormStructure({
          business_id,
          table_name: values.table_name,
          form_structure: {
            fields: values.fields,
            validations: values.validations,
          },
        })
      );
    },
  });

  return (
    <Grid container style={{ padding: theme.spacing(3) }}>
      <Grid item xs={12}>
        <Box display="flex" flex={1}>
          <Box flex={1}>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon /> Back
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              //   disabled={getAllCategoryListStatus.isPending || loading}
              onClick={() => {
                // addMeetingStatus()
                submitForm();
              }}
            >
              Modify
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box paddingTop={2}>
          <Select
            fullWidth
            className={classes.selectInput}
            name={`table_name`}
            error={touched.table_name && errors.table_name}
            onChange={(e) => {
              if (formStructure[e.target.value])
                setFieldValue("fields", [
                  ...formStructure[e.target.value].fields,
                ]);
              setFieldValue("table_name", e.target.value);
            }}
            onBlur={handleBlur}
            defaultValue={values.table_name}
            value={values.table_name}
            variant="outlined"
          >
            <MenuItem value={""}>Select Table</MenuItem>
            <MenuItem value={"service_customers"}>Service Customers</MenuItem>
          </Select>
          <FormHelperText error={touched.table_name && errors.table_name}>
            {touched.table_name && errors.table_name}
          </FormHelperText>
        </Box>
        {JSON.stringify(values.fields)}
      </Grid>
      {values.fields.map((row, index) => {
        return (
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
            }}
            item
            xs={12}
          >
            <Paper className={classes.root}>
              <Box display={"flex"}>
                <Box flex={1}>
                  <Grid spacing={2} container>
                    <React.Fragment key={index}>
                      <Grid xs={3} item>
                        <TextField
                          id={`fields[${index}].name`}
                          name={`fields[${index}].name`}
                          //   error={
                          //     touched.fields[index].name &&
                          //     errors.fields[index].name
                          //   }
                          //   helperText={
                          //     touched.fields[index].name &&
                          //     errors.fields[index].name
                          //   }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.fields[index]?.name}
                          variant="outlined"
                          placeholder="Field Name"
                        />
                      </Grid>
                      <Grid xs={3} item>
                        <TextField
                          id={`fields[${index}].placeholder`}
                          name={`fields[${index}].placeholder`}
                          // error={touched.email && errors.email}
                          // helperText={touched.email && errors.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.fields[index]?.placeholder}
                          variant="outlined"
                          placeholder="Placeholder"
                        />
                      </Grid>
                      <Grid xs={2} item>
                        <TextField
                          id={`fields[${index}].label`}
                          name={`fields[${index}].label`}
                          // error={touched.email && errors.email}
                          // helperText={touched.email && errors.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.fields[index]?.label}
                          variant="outlined"
                          placeholder="Label"
                        />
                      </Grid>
                      <Grid xs={4} item>
                        <Box style={{ display: "flex" }}>
                          <Box flex={1}>
                            <Select
                              fullWidth
                              className={classes.selectInput}
                              id={`fields[${index}].type`}
                              name={`fields[${index}].type`}
                              // error={touched.email && errors.email}
                              // helperText={touched.email && errors.email}
                              onChange={(e) => {
                                if (e.target.value == "select") {
                                  //if type of field is select the adding enums
                                  setFieldValue(`fields[${index}].enum`, [
                                    {
                                      label: "",
                                      value: "",
                                    },
                                  ]);
                                } else {
                                  //if type of field not select removing enums
                                  if (
                                    values.fields[index].hasOwnProperty("enum")
                                  )
                                    delete values.fields[index].enum;
                                }

                                setFieldValue(
                                  `fields[${index}].type`,
                                  e.target.value
                                );
                              }}
                              onBlur={handleBlur}
                              value={values?.fields[index]?.type}
                              variant="outlined"
                            >
                              <MenuItem value={"text"}>Text</MenuItem>
                              <MenuItem value={"number"}>Number</MenuItem>
                              <MenuItem value={"select"}>Select</MenuItem>
                              <MenuItem value={"date"}>Date</MenuItem>
                              <MenuItem value={"autocomplete"}>
                                Autocomplete
                              </MenuItem>
                            </Select>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() => {
                                values.fields.splice(index, 1);
                                setFieldValue(`fields`, values.fields);
                              }}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </Box>
                        {values?.fields[index]?.type == "select" && (
                          <Grid
                            style={{ paddingTop: 10 }}
                            spacing={2}
                            container
                          >
                            {values.fields[index].enum.map(
                              (option, optionIndex) => {
                                return (
                                  <React.Fragment key={optionIndex}>
                                    <Grid item xs={5}>
                                      <TextField
                                        name={`fields[${index}].enum[${optionIndex}].label`}
                                        // error={touched.email && errors.email}
                                        // helperText={touched.email && errors.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={option.label}
                                        variant="outlined"
                                        placeholder="Enter Label"
                                      />
                                    </Grid>
                                    <Grid item xs={5}>
                                      <TextField
                                        name={`fields[${index}].enum[${optionIndex}].value`}
                                        // error={touched.email && errors.email}
                                        // helperText={touched.email && errors.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={option.value}
                                        variant="outlined"
                                        placeholder="Enter Value"
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <IconButton
                                        onClick={() => {
                                          values.fields[index].enum.splice(
                                            optionIndex,
                                            1
                                          );
                                          setFieldValue(
                                            `values.fields[${index}]?.enum`,
                                            values.fields[index]?.enum
                                          );
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                    </Grid>
                                  </React.Fragment>
                                );
                              }
                            )}
                            <Grid item xs={12}>
                              <Button
                                onClick={() => {
                                  values.fields[index].enum.push({
                                    label: "",
                                    value: "",
                                  });

                                  setFieldValue("fields", values.fields);
                                }}
                                variant="contained"
                                color="primary"
                                fullWidth
                              >
                                +
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  </Grid>
                </Box>
                <Box padding={2}>2</Box>
              </Box>
            </Paper>
          </Grid>
        );
      })}

      <Grid style={{ textAlign: "end", padding: "10px 0px" }} item xs={12}>
        <Button
          onClick={() => {
            values.fields.push({
              name: "",
              type: "text",
              label: "",
              sequence: 0,
              placeholder: "",
            });

            setFieldValue("fields", values.fields);
          }}
          style={{ fontWeight: "600" }}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormFieldManagement;
