import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Header from "../layouts/Header";
import CategoryList from "./category/CategoryList";

const useStyles = makeStyles((theme) => ({
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function CategoryManagement() {
  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Category Management
        </Typography>
      </Header>

      <CategoryList />
    </>
  );
}

export default CategoryManagement;
