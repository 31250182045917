import { Typography } from '@material-ui/core'
import React from 'react'
import Header from '../layouts/Header'
import SubscriptionDatatable from './subscription/SubscriptionDatatable'

function SubscriptionManagement() {
    return (
        <>
           <Header>
                <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
                    Subscription Management
                </Typography>
            </Header>   
            <SubscriptionDatatable/>
        </>
    )
}

export default SubscriptionManagement
