import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_CITIES_DATA,
  FETCH_COUNTRY_DATA,
  FETCH_STATE_DATA,
  GENERAL_API_CALL,
} from "../constants";
import { fetchData, makeRequest } from "../api";
import { fetchStates, setFormStructure, setGeneralApiData, setReducerData, setStates } from "../actions";
import { TYPES } from "../types";

function* generalApiCall({ payload }) {
  try {
    const data = yield call(fetchData, payload);
    yield put(setGeneralApiData({ data: data, key: payload.key }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCitiesData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getCities",
      apiBody: payload || {},
    });

    yield put(setReducerData({ data: data.data.data, property: "cities" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getStateData({ payload }) {
  try {
    console.log("FGET STATE DATA:")

    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getStates",
      apiBody: payload || {},
    });

    yield put(setReducerData({ data: data.data, property: "states" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCountriesData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getCountry",
      apiBody: payload | {},
    });

    yield put(setReducerData({ data: data.data.data, property: "countries" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* addStateData({ payload }) {
  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/admin/addState",
      apiBody: payload,
    });
     
    yield put(fetchStates())
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getFormStructure({ payload }) {
  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/admin/getFormStructure",
      apiBody: payload,
    });
     
    yield put(setFormStructure(data.data))
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* modifyFormStructure({ payload }) {
  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/admin/modifyFormStructure",
      apiBody: payload,
    });
     
    console.log("data modifyFormStructure",data)
    // yield put(setFormStructure(data.data))
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

export function* GeneralSaga() {
  yield takeEvery(FETCH_COUNTRY_DATA, getCountriesData);
  yield takeEvery(FETCH_STATE_DATA, getStateData);
  yield takeEvery(FETCH_CITIES_DATA, getCitiesData);
  yield takeEvery(GENERAL_API_CALL, generalApiCall);
  yield takeEvery(TYPES.ADD_STATE, addStateData);
  yield takeEvery(TYPES.GET_FORM_STRUCTURE_BY_ID, getFormStructure);
  yield takeEvery(TYPES.MODIFY_FORM_STRUCTURE_BY_ID, modifyFormStructure)
}
