import { makeStyles, Typography, useTheme } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { Route } from "react-router-dom";
import Header from "../layouts/Header";
import AddTask from "./subscription/AddTask";
import TaskDatatable from "./tasks/TaskDatatable";
import TasksByService from "./tasks/TasksByService";

const useStyles = makeStyles((theme2) => ({
  hidden: {
    display: "none",
  },
}));

function TaskManagement() {
  const classes = useStyles();
  const [pageName, setPageName] = useState("datatable");
  const datatableRef = useRef();
  const pageNameHandler = (type) => {
    setPageName(type);
    if (type === "datatable") datatableRef.current.fetchData();
  };

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Task Management
        </Typography>
      </Header>

      <Route exact path="/tasks/:task_id/edit">
        <AddTask actionHandler={() => {}} />
      </Route>

      <Route exact path="/tasks/add">
        <AddTask actionHandler={pageNameHandler} />
      </Route>

      <Route exact path="/tasks/:service_id/view">
        <TasksByService />
      </Route>

      <Route exact path="/tasks">
        <TaskDatatable ref={datatableRef} actionHandler={pageNameHandler} />
      </Route>
    </>
  );
}

export default TaskManagement;
