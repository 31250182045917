import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { MenuContext } from "../contexts/MenuContext";
import { useAuth } from "../providers/AuthProvider";
import AboutUs from "../screens/AboutUs";
import BannerManagement from "../screens/BannerManagement";
import CategoryManagement from "../screens/CategoryManagement";
import CityManagement from "../screens/CityManagement";
import CountryManagement from "../screens/CountryManagement";
import CouponManagment from "../screens/CouponManagment";
import Dashboard from "../screens/Dashboard";
import FAQManagement from "../screens/FAQManagement";
import NewsletterAndInquireyManagment from "../screens/NewsletterAndInquireyManagment";
import OrderManagement from "../screens/OrderManagement";
import POS from "../screens/POS";
import PointofSale from "../screens/PointofSale";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import ProductManagement from "../screens/ProductManagement";
import ProfileManagement from "../screens/ProfileManagement";
import PurchaseMangement from "../screens/PurchaseMangement";
import ReviewRating from "../screens/ReviewRating";
import RoleManagement from "../screens/RoleManagement";
import ServiceManagement from "../screens/ServiceManagement";
import SliderManagement from "../screens/SliderManagement";
import StateManagement from "../screens/StateManagement";
import SubscriptionManagement from "../screens/SubscriptionManagement";
import Terms from "../screens/Terms";
import TransactionManagement from "../screens/TransactionManagement";
import UserManagement from "../screens/UserManagement";
import Invoice from "../screens/invoice/Invoice";
import AdminRegister from "../screens/login/AdminRegister";
import ForgotPassword from "../screens/login/ForgotPassword";
import Login from "../screens/login/Login";
import OtpVerification from "../screens/login/OtpVerification";
import ResetPassword from "../screens/login/ResetPassword";
import ViewPurchase from "../screens/purchase/ViewPurchase";
import { PERMISSIONS } from "../store/constants";
import Loader from "../utils/Loader";
import ServiceBill from "../screens/ServiceBills";
import _ from "lodash";
import TaskManagement from "../screens/TaskManagement";
import AddTask from "../screens/subscription/AddTask";
const DashboardLayout = React.lazy(() => import("../layouts/DashboardLayout"));

function PrivateRoute({ children, hasAccess, ...rest }) {
  let auth = useAuth();

  if (hasAccess) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.isAuthenticated() ? (
            <MenuContext>
              <DashboardLayout>{children}</DashboardLayout>
            </MenuContext>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.isAuthenticated() ? (
            <MenuContext>
              <DashboardLayout>Access Denied</DashboardLayout>
            </MenuContext>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

function RouteList() {
  const {
    userData: { permissions },
  } = useSelector((state) => state.general);

  const hasAccess = (module_name) => {
    if (permissions.includes(module_name)) {
      return true;
    } else return false;
    // let activePages = [];
    // activePages = JSON.parse(localStorage.getItem("permissions"));

    // if (!_.isNull(activePages) && activePages.includes(module_name)) {
    //   return true;
    // } else return false;
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* <PrivateRoute
          hasAccess={hasAccess(PERMISSIONS.DASHBOARD)}
          exact
          path="/"
        >
          <Redirect to="/" />
        </PrivateRoute> */}
        <PrivateRoute
          hasAccess={hasAccess(PERMISSIONS.DASHBOARD)}
          exact
          path="/"
        >
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/categories"
          hasAccess={hasAccess(PERMISSIONS.CATEGORY_MANAGEMENT)}
        >
          <CategoryManagement />
        </PrivateRoute>

        <PrivateRoute
          path="/newsletter-inquirey"
          hasAccess={hasAccess(PERMISSIONS.NEWSLETTER_INQUIREY)}
        >
          <NewsletterAndInquireyManagment />
        </PrivateRoute>

        <PrivateRoute
          path="/products"
          hasAccess={hasAccess(PERMISSIONS.PRODUCT_MANAGEMENT)}
        >
          <ProductManagement />
        </PrivateRoute>

        <PrivateRoute
          path="/reviewRating"
          hasAccess={hasAccess(PERMISSIONS.REVIEW_RATING)}
        >
          <ReviewRating />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/company/about"
          hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
        >
          <AboutUs />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/company/privacy-policy"
          hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
        >
          <PrivacyPolicy />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/company/terms"
          hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
        >
          <Terms />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/roles/:guard_name"
          hasAccess={hasAccess(PERMISSIONS.ROLE_ACCESS)}
        >
          <RoleManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/tasks"
          hasAccess={hasAccess(PERMISSIONS.TASK_MANAGEMENT)}
        >
          <TaskManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/purchase"
          // hasAccess={true}
          hasAccess={hasAccess(PERMISSIONS.PURCHASE_MANAGEMENT)}
        >
          <PurchaseMangement />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/point-of-sale/add"
          hasAccess={true}
          // hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
        >
          <POS />
        </PrivateRoute>
        <PrivateRoute
          path="/point-of-sale"
          hasAccess={true}
          // hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
        >
          <PointofSale />
        </PrivateRoute>
        <PrivateRoute
          path="/services"
          hasAccess={true}
          // hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
        >
          <ServiceManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/service-bills"
          hasAccess={true}
          // hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
        >
          <ServiceBill />
        </PrivateRoute>
        <PrivateRoute
          path="/view"
          hasAccess={true}
          // hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
        >
          <ViewPurchase />
        </PrivateRoute>

        <PrivateRoute
          path="/banners"
          hasAccess={hasAccess(PERMISSIONS.BANNER_MANAGEMENT)}
        >
          <BannerManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/sliders"
          hasAccess={hasAccess(PERMISSIONS.SLIDER_MANAGEMENT)}
        >
          <SliderManagement />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/orders"
          hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
        >
          <OrderManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/transactions"
          hasAccess={hasAccess(PERMISSIONS.TRANSACTION_MANAGEMENT)}
        >
          <TransactionManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/orders/invoice"
          hasAccess={hasAccess(PERMISSIONS.ORDER_MANAGEMENT)}
        >
          <Invoice />
        </PrivateRoute>
        <PrivateRoute path="/subscriptions">
          <SubscriptionManagement />
        </PrivateRoute>
        {/* <PrivateRoute path="/meetings">
        <MeetingManagement />
      </PrivateRoute>
      <PrivateRoute path="/messaging">
        <ChatManagement />
      </PrivateRoute> */}
        <PrivateRoute path="/profile/:tab" hasAccess={true}>
          <ProfileManagement />
        </PrivateRoute>
        {/* <PrivateRoute path="/documents">
        <DocumentManagement />
      </PrivateRoute> */}
        <PrivateRoute
          path="/company/faq"
          hasAccess={hasAccess(PERMISSIONS.CMS_MANAGEMENT)}
        >
          <FAQManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/users/:user_type"
          hasAccess={hasAccess(PERMISSIONS.USER_MANAGEMENT)}
        >
          <UserManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/coupon"
          hasAccess={hasAccess(PERMISSIONS.COUPON_MANAGEMENT)}
        >
          <CouponManagment />
        </PrivateRoute>
        {/* <PrivateRoute path="/settings">
        <SettingManagement />
      </PrivateRoute> */}
        {/* <PrivateRoute path="/setting/area">
        <AreaManagement />
      </PrivateRoute> */}
        <PrivateRoute
          path="/setting/city"
          hasAccess={hasAccess(PERMISSIONS.SETTINGS)}
        >
          <CityManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/setting/state"
          hasAccess={hasAccess(PERMISSIONS.SETTINGS)}
        >
          <StateManagement />
        </PrivateRoute>
        <PrivateRoute
          path="/setting/country"
          hasAccess={hasAccess(PERMISSIONS.SETTINGS)}
        >
          <CountryManagement />
        </PrivateRoute>
        <Route exact path="/register" component={AdminRegister} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/otp-verification" component={OtpVerification} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/chat" component={ResetPassword} />
      </Switch>
    </Suspense>
  );
}

export default RouteList;
