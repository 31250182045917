import { createAction } from "@reduxjs/toolkit";
import { TYPES } from "./types";

export * from "./category/actions";
export * from "./user/actions";

export const addState = createAction(TYPES.ADD_STATE);
export const getFormStructure = createAction(TYPES.GET_FORM_STRUCTURE_BY_ID);
export const setFormStructure = createAction(TYPES.SET_FORM_STRUCTURE_BY_ID);
export const modifyFormStructure = createAction(TYPES.MODIFY_FORM_STRUCTURE_BY_ID);
