import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  Menu,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import useGeneralStyle from "../../assets/css/general";
import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import Datatable from "../../MuiComponent/Datatable";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { httpClient } from "../../config/httpClient";
import { useFormik } from "formik";
import { faqQuestionFormValidation } from "../../validations/login-validate";
import { useSnackbar } from "notistack";
import moment from "moment";
import ConfirmationModal from "../../MuiComponent/ConfirmationModal";

const tableHeader = [
  { id: "name", align: "left", label: "Date", minWidth: 170 },
  {
    id: "size",
    label: "Question",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "detailed_profile",
    label: "CATEGORY NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function QuestionDatatable({ userType }) {
  const classes = useStyles();
  const globalClasses = useGeneralStyle();
  const getCategoryListStatus = useCallbackStatus();
  const [page, setPage] = React.useState(0);
  const [search, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = React.useState(10);
  const [questionList, setQuestionsList] = useState([]);
  const addQuestionApiStatus = useCallbackStatus();
  const [mode, setMode] = useState("add");
  const [categoryList, setcategoryList] = useState([]);
  const [modal, setModal] = React.useState(false);
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = React.useState(false);
  const [filter, setFilter] = React.useState("all");

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      category_id: "",
      question: "",
      answer: "",
    },
    onSubmit: () => {
      if (mode == "edit") updateQuestion();
      if (mode == "delete") deleteQuestion();
      else if (mode == "add") addQuestion();
    },
    validationSchema: faqQuestionFormValidation,
  });

  const theme = useTheme();

  const deleteQuestion = async () => {
    await addQuestionApiStatus.run(
      httpClient("POST", "/admin/deleteFaqQuestion", {
        body: values,
      })
        .then((result) => {
          console.log("Result: ", result);
          setModal(false);
          setConfirmation(false);
          resetForm();

          getQuestionsApiCall();

          notification.enqueueSnackbar("Question Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const updateQuestion = async () => {
    await addQuestionApiStatus.run(
      httpClient("POST", "/admin/updateFaqQuestion", {
        body: values,
      })
        .then((result) => {
          console.log("Result: ", result);
          setModal(false);
          resetForm();

          getQuestionsApiCall();

          notification.enqueueSnackbar("Question Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addQuestion = async () => {
    await addQuestionApiStatus.run(
      httpClient("POST", "/admin/addFaqQuestion", {
        body: values,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          getQuestionsApiCall();

          notification.enqueueSnackbar("Category Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const getAllCategories = async () => {
    const apiBody = {
      listType: "all",
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getCategoryListStatus.run(
      httpClient("POST", "/admin/listFaqCategory", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);
        setcategoryList(result.data);
      })
    );
  };

  const getQuestionsApiCall = async (pageSize = 10, pageNumber = 1) => {
    const apiBody = {
      page: pageNumber,
      pageSize: pageSize,
      search_string: search,
      category: filter,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    if (apiBody.category === "all") {
      delete apiBody.category;
    }

    await getCategoryListStatus.run(
      httpClient("POST", "/admin/listFaqQuestions", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS: ", total);
        setQuestionsList(data);
        setTotalPages(total);
      })
    );
  };

  useEffect(() => {
    getAllCategories();
    getQuestionsApiCall();
  }, [filter]);

  return (
    <>
      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this Question
      </ConfirmationModal>

      <Modal
        height="400px"
        width="100%"
        loading={addQuestionApiStatus.isPending}
        title={mode === "edit" ? "Edit Question" : "Add Question"}
        saveBtnText={mode === "edit" ? "update" : "Save"}
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(!modal);
        }}
        saveHandler={() => {
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Category
                </InputLabel>
                <Select
                  id="category_id"
                  name="category_id"
                  error={touched.category_id && errors.category_id}
                  helperText={touched.category_id && errors.category_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.category_id}
                  className={globalClasses.selectInput}
                  variant="outlined"
                >
                  <MenuItem disabled selected value="">
                    Select Category
                  </MenuItem>
                  {categoryList.map((category) => {
                    return (
                      <MenuItem value={category.id}>{category.name}</MenuItem>
                    );
                  })}
                </Select>

                <InputLabel className={globalClasses.inputLabel}>
                  Question
                </InputLabel>
                <TextField
                  id="question"
                  name="question"
                  error={touched.question && errors.question}
                  helperText={touched.question && errors.question}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.question}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />

                <InputLabel className={globalClasses.inputLabel}>
                  Answer
                </InputLabel>
                <TextField
                  id="answer"
                  name="answer"
                  error={touched.answer && errors.answer}
                  helperText={touched.answer && errors.answer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.answer}
                  inputProps={{ style: { padding: "0px" } }}
                  multiline
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={12}
        >
          <Box style={{ paddingRight: "10px" }}>Category</Box>

          <Select
            onClick={(e) => {
              setFilter(e.target.value);
            }}
            value={filter}
            className={classes.selectInput}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            {categoryList.map((category) => {
              return <MenuItem value={category.id}>{category.name}</MenuItem>;
            })}
          </Select>

          <Box flexGrow={1} textAlign="end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setMode("add");
                setModal(!modal);
              }}
            >
              Add Question
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getCategoryListStatus.isPending}
              getDataList={getQuestionsApiCall}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {questionList.map((question) => {
                return (
                  <TableRow>
                    <TableCell>
                      {moment(question.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{question.question}</TableCell>
                    <TableCell>{question.category.name}</TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: question.id,
                                    category_id: question.category_id,
                                    question: question.question,
                                    answer: question.answer,
                                  });
                                  setMode("edit");
                                  setModal(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setValues({
                                    id: question.id,
                                    category_id: question.category_id,
                                    question: question.question,
                                    answer: question.answer,
                                  });
                                  setMode("delete");
                                  setConfirmation(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default QuestionDatatable;
