import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from "../../MuiComponent/TextField";
import EmailIcon from "../../assets/images/drawer/email.svg";
import logo from "../../assets/images/logo/Image.png";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
import { forgotPasswordValidation } from "../../validations/login-validate";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.custom.heading,
    fontSize: "14px",
  },
}));

function ForgotPassword(props) {
  let history = useHistory();
  let auth = useAuth();

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        email: "",
      },
      onSubmit: () => {
        forgotPassword();
      },
      validationSchema: forgotPasswordValidation,
    });

  const apiSource = useCancelRequest();
  const forgotApiStatus = useCallbackStatus();
  const notification = useSnackbar();

  let forgotPassword = async () => {
    await forgotApiStatus.run(
      httpClient("POST", "common/sendotp", {
        body: {
          type: "forgot_password",
          email: values.email,
          entity_type: "admin",
        },
      })
        .then((result) => {
          history.replace("/otp-verification", {
            from: "forgot-password",
            email: values.email,
          });

          notification.enqueueSnackbar("Otp sent successfully on email", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);
          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  const classes = useStyles();

  useEffect(() => {
    if (auth.getUserId()) {
      history.push("/");
    }
  });

  return (
    <OuterLayout
      heading="Forgot your Password"
      logo={logo}
      subHeading="No worries! Enter your registerred email and we will send you a rest password email"
    >
      <Box style={{ paddingTop: "30px" }}>
        <InputLabel className={classes.inputLabel}>Your Email</InputLabel>
        <TextField
          id="email"
          name="email"
          error={touched.email && errors.email}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          style={{ paddingTop: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="emailicon" src={EmailIcon} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Enter Your Email"
        />
      </Box>
      <Box style={{ paddingTop: "30px", textAlign: "center" }}>
        <Button
          disabled={forgotApiStatus.isPending}
          onClick={submitForm}
          style={{ fontWeight: "600" }}
          variant="contained"
          color="primary"
        >
          {forgotApiStatus.isPending ? <CircularProgress size={23} /> : "Send"}
        </Button>
      </Box>
    </OuterLayout>
  );
}

export default ForgotPassword;
