import DateFnsUtils from "@date-io/moment";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AttachmentIcon from "../../assets/images/icons/attachment-clip.svg";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import FilePicker from "../../utils/FilePicker";
import { convertBackendErrorResponse, fileupload } from "../../utils/utility";
import { addBillValidation } from "../../validations/login-validate";
import useGeneralStyle from "../../assets/css/general";
import { useAuth } from "../../providers/AuthProvider";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  inputLabel: {
    color: "black",
    fontSize: "14px",
    padding: "10px 0px",
  },
  table: {
    minWidth: 650,
  },
  inputRootAutoComplete: {
    padding: "0px 8px !important",
  },
}));

function AddBill({ actionHandler, editMode }) {
  const globalClasses = useGeneralStyle();
  const theme = useTheme();
  const history = useHistory();
  const { userData } = useAuth();
  const [search, setSearchKey] = useState("");
  const [searchProduct, setSearchProductKey] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [productList, setProductsList] = useState([]);
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  const getSupllierApiStatus = useCallbackStatus();
  const getProductsApiStatus = useCallbackStatus();
  const actionApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  let [billTotal, setBillTotal] = useState(0);
  let [billDiscount, setBillDiscount] = useState(0);
  const auth = useAuth();

  let {
    values,
    touched,
    errors,
    setValues,
    setErrors,
    setFieldValue,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      supplier_id: "",
      reference_number: "",
      purchase_date: moment().format("YYYY-MM-DD"),
      status: "ordered",
      unit_cost: "",
      quantity: "",
      discount_type: "fixed",
      discount_value: 0,
      shipping_details: "",
      shipping_cost: "",
      additional_note: "",
      discount_amount: "",
      image_name: "",
      image_path: "",
      products: [],
      total: 0,
    },
    onSubmit: () => {
      // if (mode == "edit") editBanner();
      // else if (mode == "delete") deleteBanner();
      // else
      addPurchaseBill();
    },
    validationSchema: addBillValidation,
    // validationSchema:
    //   mode == "edit" || mode == "delete"
    //     ? editBannerValidation
    //     : addBannerValidation,
  });

  const addPurchaseBill = async () => {
    let totalBillingValue = 0;
    totalBillingValue = values.products.reduce((totalBillingValue, item) => {
      // netAmount = netAmount + item.rate * item.quantity;
      totalBillingValue = totalBillingValue + item.incl_gst_total;
      return totalBillingValue;
    }, totalBillingValue);

    let apiBody = {
      ...values,
      total: totalBillingValue,
      discount_amount: billDiscount,
    };

    setLoader(true);
    if (typeof values.image_path == "object") {
      let result = await fileupload({
        file: values.image_path,
        folder: "purchase_bills",
        disk: "s3_private",
      });

      apiBody.image_path = result.path;
    }

    await actionApiStatus.run(
      httpClient("POST", "/partner/addPurchase", {
        body: apiBody,
      })
        .then((result) => {
          setLoader(false);
          resetForm();
          history.push("/purchase");
          actionHandler("datatable");
          notification.enqueueSnackbar("Bill Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          setLoader(false);
          setErrors(convertBackendErrorResponse(er.errors));
          console.log("Error", er);
        })
    );
  };

  const fetchsuppliers = async () => {
    const apiBody = {
      // page: pageNumber,
      // page_size: pageSize,
      // pagination_required: true,
      search_string: search,
      business_id: auth.userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getSupllierApiStatus.run(
      httpClient("POST", "/partner/fetchSuppliers", {
        body: apiBody,
      })
        .then((result) => {
          console.log("Result", result);

          const {
            data: { data, meta },
          } = result;

          setSupplierData(data);
        })
        .catch((er) => {
          console.log("Error", er);
        })
    );
  };

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      setValues({ ...values, image_name: file.name, image_path: file });
      console.log("IMAGE SELECTED: ", {
        ...values,
        image_name: file.name,
        image_path: file,
      });
    }
  };

  const getAllProductsList = async () => {
    const apiBody = {
      search_string: searchProduct,
      business_id: userData.business_id,
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    await getProductsApiStatus.run(
      httpClient("POST", "/partner/getProducts", {
        body: apiBody,
      }).then((result) => {
        console.log("Result All Products: ", result);

        const {
          data: { data, total },
        } = result;

        console.log("TOTAL RECOREDS All Products: ", total);
        setProductsList(data);
        // setProducts(data);
      })
    );
  };

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      fetchsuppliers();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      getAllProductsList();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchProduct]);

  const renderError = ({ field, index }) => {
    if (!_.isUndefined(touched?.products) && !_.isUndefined(errors.products)) {
      if (!_.isUndefined(errors?.products[index]))
        return errors?.products[index][field];
    }
  };

  useEffect(() => {
    let totalBilling = values.products.reduce((totalBilling, item) => {
      // netAmount = netAmount + item.rate * item.quantity;
      totalBilling = totalBilling + item.incl_gst_total;
      return totalBilling;
    }, 0);

    setBillTotal(totalBilling);

    let discount = 0;
    if (values.discount_type == "percentage")
      discount = billTotal * (values.discount_value / 100);
    else {
      if (values.discount_value > 0) {
        discount = values.discount_value;
      } else {
        discount = 0;
      }
    }

    setBillDiscount(parseFloat(discount));
  }, [values]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ padding: theme.spacing(3) }}
      >
        <Button
          onClick={() => {
            // actionHandler("datatable");
            history.goBack()
          }}
        >
          <ArrowBackIosIcon /> Back
        </Button>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={actionApiStatus.isPending || loader}
            onClick={submitForm}
          >
            {actionApiStatus.isPending || loader ? (
              <CircularProgress size={23} />
            ) : editMode ? (
              "Update Bill"
            ) : (
              "Add Bill"
            )}
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }}
      >
        <Paper style={{ border: "1px solid #e0e0e0" }}>
          <Grid
            container
            spacing={2}
            style={{ padding: "15px", width: "100%" }}
          >
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>Supplier*</InputLabel>
              <Autocomplete
                classes={{ inputRoot: classes.inputRootAutoComplete }}
                id="combo-box-demo"
                options={supplierData}
                onChange={(event, newValue) => {
                  setFieldValue("supplier_id", newValue.id);
                }}
                getOptionLabel={(option) => option.business_name}
                style={{ height: "12px" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select Supplier"
                      onChange={(event) => {
                        setSearchKey(event.target.value);
                      }}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Reference Number*
              </InputLabel>
              <TextField
                id="reference_number"
                name="reference_number"
                error={touched.reference_number && errors.reference_number}
                helperText={touched.reference_number && errors.reference_number}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.reference_number}
                variant="outlined"
                placeholder="Reference Number"
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InputLabel className={classes.inputLabel}>
                  Purchase Date*
                </InputLabel>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  fullWidth
                  id="date-picker-dialog"
                  className={classes.inputTextField}
                  format="YYYY-MMM-DD"
                  value={values.purchase_date}
                  inputProps={{
                    style: { padding: "10px", fontSize: "14px" },
                  }}
                  TextFieldComponent={(props) => {
                    return (
                      <TextField
                        {...props}
                        value={moment(values.purchase_date).format(
                          "YYYY-MM-DD"
                        )}
                        InputProps={{
                          ...props.InputProps,
                          className: classes.inputTextField,
                        }}
                      />
                    );
                  }}
                  onChange={(date) => {
                    console.log(
                      "DOB DATE SELECTED: ",
                      moment(date).format("YYYY-MM-DD HH:mm:ss")
                    );
                    setFieldValue(
                      "purchase_date",
                      moment(date).format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: `datePicker1`,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Purchase Status*
              </InputLabel>
              <Select
                id="status"
                name="status"
                error={touched.status && errors.status}
                helperText={touched.status && errors.status}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.status}
                variant="outlined"
                style={{ width: "100%", height: "35px" }}
                placeholder="reference No"
              >
                <MenuItem value="ordered">Ordered</MenuItem>
                <MenuItem value="delivered">Delivered</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
              {/* <InputLabel className={classes.inputLabel}>
                  File Uploader*
                </InputLabel> */}
              <TextField
                // error={touched.file_uploader && errors.file_uploader}
                // helperText={touched.file_uploader && errors.file_uploader}
                value={values.image_name}
                variant="outlined"
                placeholder="Select Image"
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
                InputProps={{
                  endAdornment: (
                    <FilePicker
                      fileHandler={fileHandler}
                      type="iconButton"
                      icon={AttachmentIcon}
                      filetype="image"
                    />
                  ),
                }}
              />
              <IconButton></IconButton>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                Search Product*
              </InputLabel>
              <Autocomplete
                freeSolo={false}
                classes={{ inputRoot: classes.inputRootAutoComplete }}
                id="combo-box-demo"
                options={productList}
                getOptionLabel={(option) => option.name}
                style={{ height: "12px" }}
                onChange={(event, newValue) => {
                  console.log("PRODUCTS PRICE: ", newValue, values.products);
                  if (newValue) {
                    values.products.push({
                      tax: newValue.tax,
                      name: newValue.name,
                      product_id: newValue.id,
                      discount: 0,
                      rate: 0,
                      quantity: newValue?.product_data?.quantity,
                      unit_cost: "",
                    });
                    console.log("PRODUCTS ss: ", values.products);
                    setValues({ ...values });
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select Supplier"
                      onChange={(event) => {
                        setSearchProductKey(event.target.value);
                      }}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <br />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Unit Cost</TableCell>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">Rate</TableCell>
                      <TableCell align="right">Total (Excl. GST)</TableCell>
                      <TableCell align="right">Tax</TableCell>
                      <TableCell align="right">Total (Incl. GST)</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.products.map((row, index) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            id="quantity"
                            name="quantity"
                            type="number"
                            error={renderError({
                              field: "quantity",
                              index: index,
                            })}
                            helperText={renderError({
                              field: "quantity",
                              index: index,
                            })}
                            onBlur={handleBlur}
                            variant="outlined"
                            inputProps={{
                              style: {
                                padding: "8px",
                                width: "50px",
                                fontSize: "14px",
                              },
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                `products[${index}].quantity`,
                                event.target.value
                              );
                            }}
                            value={row.quantity}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            id="unit_cost"
                            name="unit_cost"
                            error={renderError({
                              field: "unit_cost",
                              index: index,
                            })}
                            helperText={renderError({
                              field: "unit_cost",
                              index: index,
                            })}
                            onBlur={handleBlur}
                            variant="outlined"
                            inputProps={{
                              style: {
                                padding: "8px",
                                width: "50px",
                                fontSize: "14px",
                              },
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                `products[${index}].unit_cost`,
                                event.target.value
                              );
                            }}
                          ></TextField>
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            id="discount"
                            name="discount"
                            value={row.discount}
                            error={renderError({
                              field: "discount",
                              index: index,
                            })}
                            helperText={renderError({
                              field: "discount",
                              index: index,
                            })}
                            onBlur={handleBlur}
                            variant="outlined"
                            inputProps={{
                              style: {
                                padding: "8px",
                                width: "50px",
                                fontSize: "14px",
                              },
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                `products[${index}].discount`,
                                event.target.value
                              );
                            }}
                          ></TextField>
                        </TableCell>
                        <TableCell align="right">
                          {(() => {
                            let price = row.unit_cost;
                            let discount = row.discount;

                            row.rate = price - (price * discount) / 100;
                            return row.rate.toFixed(2);
                          })()}
                        </TableCell>
                        <TableCell align="right">
                          {(row.rate * row.quantity).toFixed(2)}
                          {/* {row.rate * row.quantity -
                            row.rate * (row.tax / 100) * row.quantity} */}
                        </TableCell>
                        <TableCell align="right">
                          {(row.rate * (row.tax / 100) * row.quantity).toFixed(
                            2
                          )}{" "}
                          ({row.tax}
                          %)
                        </TableCell>
                        <TableCell align="right">
                          {(() => {
                            row.incl_gst_total =
                              row.rate * row.quantity +
                              row.rate * (row.tax / 100) * row.quantity;

                            return row.incl_gst_total.toFixed(2);
                          })()}
                        </TableCell>
                        <TableCell align="right">
                          <a
                            className={globalClasses.link}
                            style={{ color: "#2895c4" }}
                            onClick={() => {
                              values.products.splice(index, 1);
                              setFieldValue("products", values.products);
                            }}
                          >
                            Remove
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box paddingRight="45px">Total Amount</Box>
              <Box>
                Rs. {billTotal.toFixed(2)}
                {/* {(() => {
                  let netAmount = 0;
                  billTotal = values.products.reduce((billTotal, item) => {
                    // netAmount = netAmount + item.rate * item.quantity;
                    billTotal = billTotal + item.incl_gst_total;
                    return billTotal;
                  }, billTotal);

                  // setBillTotal(billTotal);
                  return billTotal.toFixed(2);
                })()} */}
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              padding: "15px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Discount Type
              </InputLabel>
              <Select
                id="discount_type"
                name="discount_type"
                error={touched.discount_type && errors.discount_type}
                helperText={touched.discount_type && errors.discount_type}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.discount_type}
                variant="outlined"
                style={{ width: "100%", height: "35px" }}
                placeholder="reference No"
                // inputProps={{
                //   style: {},
                // }}
              >
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className={classes.inputLabel}>
                Discount Value*
              </InputLabel>
              <TextField
                id="discount_value"
                name="discount_value"
                error={touched.discount_value && errors.discount_value}
                helperText={touched.discount_value && errors.discount_value}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.discount_value}
                variant="outlined"
                placeholder="discount_value"
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box paddingRight="45px">Discount(-)</Box>
              <Box>
                Rs {billDiscount.toFixed(2)}
                {/* {(() => {
                  let discount = 0;
                  if (values.discount_type == "percentage")
                    discount = billTotal * (values.discount_value / 100);
                  else {
                    if (values.discount_value > 0) {
                      discount = values.discount_value;
                    } else {
                      discount = 0;
                    }
                  }

                  billDiscount = discount;
                  // setBillDiscount(parseFloat(discount));
                  return parseFloat(discount).toFixed(2);
                })()} */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box paddingRight="45px">Net Payable</Box>
              <Box>
                Rs. {(billTotal - billDiscount.toFixed(2)).toFixed(2)}
                {/* {(() => {
                  let netAmount = 0;
                  values.products.map((item) => {
                    netAmount = item.incl_gst_total;
                  });

                  billTotal = netAmount;
                  return (netAmount - billDiscount).toFixed(2);
                })()} */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>
                Shipping Details*
              </InputLabel>
              <TextField
                id="shipping_details"
                name="shipping_details"
                error={touched.shipping_details && errors.shipping_details}
                helperText={touched.shipping_details && errors.shipping_details}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shipping_details}
                variant="outlined"
                placeholder="shipping details"
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <InputLabel
                className={classes.inputLabel}
                style={{ paddingRight: "82px" }}
              >
                Shipping Value (+)
              </InputLabel>
              <TextField
                id="shipping_cost"
                name="shipping_cost"
                error={touched.shipping_cost && errors.shipping_cost}
                helperText={touched.shipping_cost && errors.shipping_cost}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shipping_cost}
                variant="outlined"
                placeholder="shipping cost"
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                Additional Note
              </InputLabel>
              <TextField
                id="additional_note"
                name="additional_note"
                error={touched.additional_note && errors.additional_note}
                helperText={touched.additional_note && errors.additional_note}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.additional_note}
                variant="outlined"
                placeholder="additional note"
                style={{ width: "100%" }}
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default AddBill;
