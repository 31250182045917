const { createTheme } = require("@material-ui/core");

export const portalTheme={
  primary: "#fbc068",
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#95d6ff",
      main: "#db2116",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    custom: {
      heading: "#1c1a1a",
      subHeading: "#7c7c7c",
    },
  },
  typography: {
    fontFamily: [
      "Work Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiButton: {
      contained: {
        "&:hover": {
          color: "white",
          backgroundColor: "#2895c4",
        },
      },
      containedPrimary: {
        "&:hover": {
          color: "white",
          backgroundColor: "#2895c4",
        },
      },
    },
    MuiTableCell: {
      body: {
        color: "#63646c!important",
        fontWeight: 500,
      },
    },
    MuiButton: {
      disableElevation: {
        color: "#a7a5a5",
      },
    },
    // MuiSwitch: {
    //   checked: {
    //     color: orange[500],
    //     '& + $bar': {
    //       backgroundColor: orange[500],
    //     },
    //   },
    // },
  },
});

export default theme;
