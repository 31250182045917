import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Box, Button, DialogContentText, Grid, IconButton, InputLabel, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import Modal from '../../MuiComponent/Modal';
import TextField from '../../MuiComponent/TextField';

const columns = [
    { id: 'name', align: 'left', label: 'Registered Date', minWidth: 170 },
    {
        id: 'population',
        label: 'User Name',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'SPECIALITY/Interest',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'USER TYPE',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'detailed_profile',
        label: 'DETAILED PROFILE',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'density',
        label: 'MANAGE',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toFixed(2),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles((theme2) => ({
    root: {
        width: '100%',
        overflowX: "auto",
        backgroundColor: "white",
        padding: "10px"

    },
    heading: {
        fontWeight: 600
    },
    inputLabel:{
        color:"color",
        fontSize:"13px",
        padding:"10px 0px"
    },
    value: {
        color: "#63646c",
    },
    container: {
        height: "440px",
        width: "100%"
    },
    activeFilter: {
        backgroundColor: theme2.palette.primary.main,
        color: "white",
        fontWeight: 600
    }
}));

function SubscriptionDatatable({ userType, meetingSettings }) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [modal, setModal] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filter, setFilter] = React.useState('active')
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const viewMeeting = () => {
        setModal(!modal)
    }

    const theme = useTheme()

    return (
        <>
            <Modal
                height="440px"
                width="100%"
                title="Create Subscription"
                saveBtnText="Create"
                closeBtnText="close"
                closeHandler={() => { setModal(!modal) }}
                saveHandler={() => { setModal(!modal) }}
                status={modal} >
                <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box display="flex" flexDirection="column">
                                <InputLabel className={classes.inputLabel}>Title</InputLabel>
                                <TextField variant="outlined" placeholder="write here"/>
                            
                                <InputLabel className={classes.inputLabel}>Price</InputLabel>
                                <TextField FormHelperTextProps={{style:{fontSize:"10px"}}} placeholder="write here" variant="outlined"/>
                                
                                {/* <InputLabel className={classes.inputLabel}>Category*</InputLabel>
                                <Select variant="outlined">
                                    <MenuItem >Employment</MenuItem>
                                </Select> */}
                                {/* <TextField FormHelperTextProps={{style:{fontSize:"10px"}}} helperText="*Template format pdf/docx and size should not be more than 2 MB." variant="outlined"/> */}
                            </Box>
                        </Grid>
                        <Grid item xs={6}> 
                        <Box display="flex" flexDirection="column">
                                <InputLabel className={classes.inputLabel}>Duration*</InputLabel>
                                <TextField variant="outlined" placeholder="Write here in days"/>
                            </Box>
                        </Grid>
                         <Grid item xs={12}>
                                <InputLabel className={classes.inputLabel}>Description</InputLabel>
                                <TextField variant="outlined" placeholder="Write here in days" multiline/>
                         </Grid>
                    </Grid>
                </DialogContentText>
            </Modal>

            <Box display="flex" style={{ backgroundColor: "white" }}>
                <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
                    <Button disableElevation onClick={() => setFilter('active')} className={clsx({ [classes.activeFilter]: filter === 'active' })} variant="contained">Active</Button>
                </Box>
                <Box style={{ margin: "10px 10px" }}>
                    <Button disableElevation variant="contained" className={clsx({ [classes.activeFilter]: filter === 'disabled' })} onClick={() => setFilter('disabled')}>Disabled</Button>
                </Box>
            </Box>


            <Grid container style={{ padding: theme.spacing(3) }}>
                <Grid style={{ display: "flex", alignItems: "center", padding: "10px 0px" }} item xs={5}>
                    <Box style={{ paddingRight: "20px" }}>Search</Box>
                    <TextField variant="outlined" style={{ width: "100%" }} inputProps={{ style: { padding: "12px", backgroundColor: "white" } }} placeholder="by Name" />
                </Grid>
                <Grid style={{ display: "flex", alignItems: "center", flexDirection: "row-reverse", padding: "10px 0px" }} item xs={7}>
                    <Button variant="contained" color="primary" onClick={() => setModal(!modal)}>Create Subscription</Button>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        {userType}
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, fontWeight: 600 }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow hover role="checkbox" tabIndex={-1} >
                                        <TableCell>
                                            data1
              </TableCell>
                                        <TableCell>
                                            data1
              </TableCell>
                                        <TableCell>
                                            data1
              </TableCell>
                                        <TableCell>
                                            data1
              </TableCell>
                                        <TableCell align="center">
                                            <Link style={{ color: "#2895c4" }} onClick={viewMeeting}>View</Link>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}


export default SubscriptionDatatable
