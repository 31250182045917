import { Box, Button, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Timer from "react-compound-timer/build";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo/Image.png";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
import { otpValidation } from "../../validations/login-validate";

function OtpVerification(props) {
  let history = useHistory();
  let auth = useAuth();

  let formik = useFormik({
    initialValues: {
      otp: null,
    },
    onSubmit: () => {
      validateOtp();
    },
    validationSchema: otpValidation,
  });

  // const [resendTimerValue,setResendTimerValue]=useState(300000)
  const [resendTimerValue, setResendTimerValue] = useState(10000);

  const sendOtpApiStatus = useCallbackStatus();
  const ValidateOtpApiStatus = useCallbackStatus();
  const notification = useSnackbar();

  let resendOtp = async (timerActions) => {
    let apiBody = {
      email: history.location.state.email,
    };

    await sendOtpApiStatus.run(
      httpClient("POST", "/common/sendotp", {
        body: {
          type: "forgot_password",
          entity_type: "admin",
          ...apiBody,
        },
      })
        .then((result) => {
          let { waitTime } = result;

          setResendTimerValue(waitTime * 30);
          // // setResendTimerValue((currentValue)=>currentValue+300000)
          timerActions.setTime(waitTime * 30);
          timerActions.reset();
          timerActions.start();

          notification.enqueueSnackbar("Otp sent Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);
        })
    );
  };

  let validateOtp = async () => {
    let apiBody = {
      email: history.location.state.email,
      otp: formik.values.otp,
    };

    await ValidateOtpApiStatus.run(
      httpClient("POST", "/common/validateOtp", {
        body: {
          type: "forgot_password",
          entity_type: "admin",
          ...apiBody,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          history.replace("/reset-password", {
            optVerified: true,
            ...apiBody,
            from: "otp-verification",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);
          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  useEffect(() => {
    if (auth.getUserId()) {
      history.push("/");
    }
  });

  useEffect(() => {
    if (
      _.isUndefined(history.location.state) ||
      history.location.state.from !== "forgot-password"
    ) {
      console.log("History:", history);
      history.push("/forgot-password");
    }
  });

  const content = `Enter the 6-digit code ${
    process.env.REACT_APP_APPLICATION_NAME
  } just sent to ${
    _.isUndefined(history.location.state) ? null : history.location.state.email
  }`;

  return (
    <OuterLayout heading="Verification" logo={logo} subHeading={content}>
      <Box style={{ paddingTop: "30px" }}>
        <OtpInput
          value={formik.values.otp}
          onChange={(otp) => {
            formik.setValues({ otp: otp });
          }}
          numInputs={6}
          shouldAutoFocus
          inputStyle={{ fontSize: "50px", marginRight: "28px" }}
        />
      </Box>
      <Box style={{ paddingTop: "30px", textAlign: "left" }}>
        <Timer initialTime={resendTimerValue} direction="backward">
          {({ start, resume, pause, stop, reset, getTimerState, setTime }) => {
            const state = getTimerState();
            return (
              <>
                <Button
                  disabled={
                    state === "INITED" ||
                    state === "PLAYING" ||
                    sendOtpApiStatus.isPending
                      ? true
                      : false
                  }
                  onClick={() =>
                    resendOtp({ start, resume, pause, stop, reset, setTime })
                  }
                  style={{ fontWeight: "600", marginRight: "40px" }}
                  variant="contained"
                  color="primary"
                >
                  {state === "INITED" || state === "PLAYING" ? (
                    <React.Fragment>
                      <Timer.Minutes /> : <Timer.Seconds />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {state === "STOPPED" && sendOtpApiStatus.isPending ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Resend"
                      )}
                    </React.Fragment>
                  )}
                </Button>
              </>
            );
          }}
        </Timer>
        {/* <Button onClick={resendOtp} style={{ fontWeight: "600", marginRight: "40px" }} variant="contained" color="primary">Resend</Button> */}
        <Button
          onClick={formik.submitForm}
          disabled={ValidateOtpApiStatus.isPending ? true : false}
          style={{ fontWeight: "600" }}
          variant="contained"
          color="primary"
        >
          {ValidateOtpApiStatus.isPending ? (
            <CircularProgress size={20} />
          ) : (
            "Validate"
          )}
        </Button>
      </Box>
    </OuterLayout>
  );
}

export default OtpVerification;
