import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextField from "../../MuiComponent/TextField";
import EmailIcon from "../../assets/images/drawer/email.svg";
import LockIcon from "../../assets/images/drawer/password.svg";
import logo from "../../assets/images/logo/Image.png";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import OuterLayout from "../../layouts/login/OuterLayout";
import { useAuth } from "../../providers/AuthProvider";
import { loginValidation } from "../../validations/login-validate";
// import { login } from '../../store/actions/UserActions';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.custom.heading,
    fontSize: "14px",
  },
}));

function Login(props) {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const loginApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  let { from } = location.state || { from: { pathname: "/" } };

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: () => {
        login();
      },
      validationSchema: loginValidation,
    });

  let login = async () => {
    await loginApiStatus.run(
      httpClient("POST", `/${process.env.REACT_APP_ACCESS_MODE}/login`, {
        body: values,
      })
        .then((result) => {
          auth.setUser(result.data).then((result) => {
            notification.enqueueSnackbar("Login Successfully", {
              variant: "success",
            });

            history.replace(from);
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.message, {
            variant: "error",
          });
        })
    );
  };

  useEffect(() => {
    if (auth.getUserId()) {
      history.push("/");
    }
  });

  return (
    <OuterLayout
      heading="Login"
      logo={logo}
      subHeading={`This is ${process.env.REACT_APP_APPLICATION_NAME} Admin Portal`}
    >
      <Box style={{ paddingTop: "10px" }}>
        <InputLabel className={classes.inputLabel}>Your email</InputLabel>
        <TextField
          id="email"
          name="email"
          error={touched.email && errors.email}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          style={{ paddingTop: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="emailicon" src={EmailIcon} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Enter Your Email"
        />
      </Box>
      <Box style={{ paddingTop: "20px" }}>
        <InputLabel className={classes.inputLabel}>Password</InputLabel>
        <TextField
          id="password"
          name="password"
          error={touched.password && errors.password}
          helperText={touched.password && errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              submitForm();
            }
          }}
          style={{ paddingTop: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="lockicon" src={LockIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword((prevValue) => !prevValue)}
              >
                {showPassword ? (
                  <VisibilityOff fontSize="small" className={classes.icon} />
                ) : (
                  <Visibility fontSize="small" className={classes.icon} />
                )}
              </IconButton>
            ),
          }}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          placeholder="Enter Your Password"
        />
      </Box>
      <Box style={{ paddingTop: "10px", textAlign: "right" }}>
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/forgot-password"
        >
          Forgot Password?
        </Link>
      </Box>
      <Box style={{ paddingTop: "10px", textAlign: "center" }}>
        <Button
          disabled={loginApiStatus.isPending}
          onClick={submitForm}
          style={{ fontWeight: "600" }}
          variant="contained"
          color="primary"
        >
          {loginApiStatus.isPending ? <CircularProgress size={23} /> : "Login"}
        </Button>
      </Box>
    </OuterLayout>
  );
}

export default Login;
