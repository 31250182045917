import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useFormik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { lazy, useCallback, useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router-dom";
import QuillEditor from "../../MuiComponent/QuillEditor";
import TabPanel from "../../MuiComponent/TabPanel";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import FilePicker from "../../utils/FilePicker";
import { fileupload, S3_ASSETS_PATH } from "../../utils/utility";
import {
  addProductValidation,
  addProductValidationWithLimitedFields,
} from "../../validations/login-validate";
// import DraftJS from "../../MuiComponent/DraftJS";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useAuth } from "../../providers/AuthProvider";
import DefaultLoader from "../../layouts/DefaultLoader";
const RichTextEditorV2 = lazy(() =>
  import("../../MuiComponent/RichTextEditorV2")
);
const DraftJS = lazy(() => import("../../MuiComponent/DraftJS"));

const useStyles = makeStyles((theme) => ({
  meetingHead: {
    padding: "14px",
    fontSize: "16px",
    borderBottom: "1px solid #e0e0e0",
  },
  imagePicker: {
    // margin: "4px",
    // padding: "30px 75px",
    // cursor: "pointer",
    // fontSize: "50px",
    "&:hover": {
      backgroundColor: "#e1e1e1",
    },
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function AddProduct({ addMeetingStatus, editMode }) {
  const history = useHistory();
  const theme = useTheme();
  const auth = useAuth();
  const classes = useStyles();
  const ref1 = useRef({});
  const ref2 = useRef({});
  const [loading, setLoading] = useState(false);
  const [detailTab, setDetailsTab] = React.useState("short_description");
  const [categoryList, setCategoriesList] = useState([]);
  const [priceTaxExcluded, setPriceTaxExcluded] = useState(0);
  const getAllCategoryListStatus = useCallbackStatus();
  const getProductByIdApiStatus = useCallbackStatus();
  const globalClasses = useGeneralStyle();
  const notification = useSnackbar();

  let {
    values,
    touched,
    errors,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      short_description: EditorState.createEmpty(),
      description: EditorState.createEmpty(),
      isFeatured: "N",
      cover_image: "",
      categories: ["none"],
      images: [],
      offer_price: 0,
      removed_images: [],
      pos_price: "",
      retail_price: 0,
      barcode: "",
      tax: "",
      quantity: 0,
      options: [
        {
          name: "",
          optionValues: [
            {
              value: "",
              offer_price: 0,
              retail_price: 0,
            },
          ],
        },
      ],
    },
    onSubmit: async () => {
      console.log("VALUES: ", values);
      editMode ? updateData() : saveData();
    },
    validationSchema: addProductValidationWithLimitedFields,
  });

  const getAllCategoryApiCall = useCallback(async () => {
    const apiBody = {
      all: "true",
      business_id: auth.userData.business_id,
    };

    await getAllCategoryListStatus.run(
      httpClient("POST", "/common/category", {
        body: apiBody,
      }).then((result) => {
        console.log("Result: ", result);

        const { data } = result;

        setCategoriesList(data);
      })
    );
  }, [getAllCategoryListStatus]);

  const getProductById = useCallback(
    async (id) => {
      await getProductByIdApiStatus.run(
        httpClient("GET", `/partner/product/${id}/edit`, {
          body: {},
        }).then((result) => {
          console.log("getProductById Result: ", result);

          const { data } = result;
          data.removed_images = [];
          const parseRawDescription = JSON.parse(data.description);
          const rawDescriptionToContentState =
            convertFromRaw(parseRawDescription);
          data.description = EditorState.createWithContent(
            rawDescriptionToContentState
          );
          const parseShortDescription = JSON.parse(data.short_description);
          const rawShortDescriptionToContentState = convertFromRaw(
            parseShortDescription
          );
          data.short_description = EditorState.createWithContent(
            rawShortDescriptionToContentState
          );
          setValues(data);
        })
      );
    },
    [getProductByIdApiStatus, setValues]
  );

  useEffect(() => {
    if (history.location.pathname.split("/")[3] === "edit") {
      console.log(
        "EDIT MODE RECORED DATA EFFEDTF",
        history.location.pathname.split("/")[2]
      );
      let productId = history.location.pathname.split("/")[2];
      getProductById(productId);
    }
  }, [editMode, history.location.pathname]);

  useEffect(() => {
    getAllCategoryApiCall();
  }, []);

  // useEffect(() => {
  //   console.log("ref1 HEERERE : ", _.isEmpty(ref1.current.quill));

  //   if (!_.isEmpty(ref1.current.quill)) {
  //     ref1.current.quill.on("text-change", function (delta, oldDelta, source) {
  //       console.log(ref1.current.quill.getContents());
  //       setFieldValue(
  //         "short_description",
  //         JSON.stringify(ref1.current.quill.getContents().ops)
  //       );
  //     });
  //   }
  // }, [ref1.current.quill]);

  const filesHandler = (files) => {
    if (files) {
      values.images.push(files);
      setValues({ ...values });
      console.log("FILE SELECTED: ", files);
    }
  };

  const saveData = () => {
    let optionData = {};

    let promises = [];
    let results = [];

    setLoading(true);
    values.images.forEach((image) => {
      promises.push(
        fileupload({
          file: image,
          folder: S3_ASSETS_PATH("product", auth.userData),
          disk: "s3_private",
        }).then((result) => {
          results.push({
            path: result.path,
          });
        })
      );
    });

    Promise.all(promises).then(async (result) => {
      console.log(results);
      values.options.forEach((option) => {
        if (option.name !== "" && option.optionValues.value !== "")
          optionData[option.name] = option.optionValues;
      });

      let shortDescriptionContentState =
        values.short_description.getCurrentContent();
      let descriptionContentState = values.description.getCurrentContent();

      let apiBody = {
        name: values.name,
        tax: values.tax ? values.tax : 0,
        short_description: JSON.stringify(
          convertToRaw(shortDescriptionContentState)
        ),
        barcode: values.barcode,
        description: JSON.stringify(convertToRaw(descriptionContentState)),
        isFeatured: values.isFeatured,
        pos_price: values.pos_price,
        cover_image: 0,
        // cover_image: results[0]?.path,
        offer_price: values.offer_price,
        retail_price: values.retail_price,
        quantity: values.quantity,
        categories: values.categories,
        options: JSON.stringify(optionData),
        images: results,
      };

      await getAllCategoryListStatus.run(
        httpClient("POST", "/partner/addProduct", {
          body: apiBody,
        }).then((result) => {
          setLoading(false);
          // addMeetingStatus("datatable");
          history.push("/products");
          notification.enqueueSnackbar("Product Added Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        })
      );
    });
  };

  // const saveData = async () => {
  //     let fd = new FormData()
  //     fd.append('name', values.name)
  //     fd.append('short_description', values.short_description)
  //     fd.append('description', values.description)
  //     fd.append('is_featured', values.isFeatured)
  //     fd.append('cover_image', values.images[0])
  //     fd.append('offer_price', values.offer_price)
  //     fd.append('retail_price', values.retail_price)
  //     fd.append('quantity', values.quantity)

  //     values.images.map(image => {
  //         fd.append('images[]', image)
  //     })

  //     values.categories.map(category => {
  //         fd.append('categories[]', category)
  //     })

  //     let optionData = {}

  //     values.options.map((option, index) => {
  //         optionData[option.name] = option.optionValues
  //     })

  //     console.log('optionData: ', optionData)
  //     console.log('optionData: ', JSON.stringify(optionData))

  //     fd.append('options', JSON.stringify(optionData))

  //     await getAllCategoryListStatus.run(
  //         httpClient('POST', '/admin/product', {
  //             body: fd
  //         }).then(result => {
  //             console.log("Result: ", result)

  //             notification.enqueueSnackbar('Product Added Successfully', {
  //                 variant: "success",
  //                 autoHideDuration: 2000,
  //             });
  //         })
  //     )
  // }

  const updateData = async () => {
    let fd = new FormData();
    let shortDescriptionContentState =
      values.short_description.getCurrentContent();
    let descriptionContentState = values.description.getCurrentContent();

    fd.append("name", values.name);
    fd.append(
      "short_description",
      JSON.stringify(convertToRaw(shortDescriptionContentState))
    );
    fd.append(
      "description",
      JSON.stringify(convertToRaw(descriptionContentState))
    );
    fd.append("is_featured", values.isFeatured);
    // fd.append(
    //   "cover_image",
    //   _.isUndefined(values.images[0].path)
    //     ? values.images[0]
    //     : values.images[0].path
    // );
    fd.append("cover_image", 0);
    fd.append("pos_price", values.pos_price);
    fd.append("offer_price", values.offer_price);
    fd.append("barcode", values.barcode);
    fd.append("tax", values.tax);
    fd.append("retail_price", values.retail_price);
    fd.append("quantity", values.quantity);
    fd.append("removed_images", JSON.stringify(values.removed_images));
    values.categories.forEach((category) => {
      fd.append("categories[]", category);
    });

    values.images.forEach((data) => {
      console.log("data: ", data);
      if (_.isUndefined(data.path)) {
        fd.append("images[]", data);
      } else fd.append("imagesExist[]", JSON.stringify(data));
    });

    let optionData = {};

    values.options.forEach((option, index) => {
      option.optionValues.map((item) => {
        return (item.name = option.name);
      });

      if (option.name !== "" && option.optionValues.value !== "")
        optionData[option.name] = option.optionValues;
    });

    // console.log('optionData: ', optionData)
    // for (var key of fd.values()) {
    //     console.log(key);
    // }

    fd.append("options", JSON.stringify(optionData));

    await getAllCategoryListStatus.run(
      httpClient("POST", `/partner/product/${values.id}`, {
        body: fd,
      }).then((result) => {
        console.log("Result: ", result);
        // addMeetingStatus("datatable");
        history.goBack();
        notification.enqueueSnackbar("Product Updated Successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      })
    );
  };

  const priceExclTax = () => {
    let offerPrice = values.offer_price;
    let tax = values.tax;

    setPriceTaxExcluded(offerPrice - offerPrice * (tax / 100));
  };

  const RichTextEditorSync = React.useCallback(
    (props) => {
      return <RichTextEditorV2 data={props.data} onChange={props.onChange} />;
    },
    [values.description, values.short_description]
  );

  useEffect(() => {
    priceExclTax();
  }, [values.offer_price, values.tax]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ padding: theme.spacing(3) }}
      >
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBackIosIcon /> Back
        </Button>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={getAllCategoryListStatus.isPending || loading}
            onClick={() => {
              // addMeetingStatus()
              submitForm();
            }}
          >
            {getAllCategoryListStatus.isPending || loading ? (
              <CircularProgress size={18} />
            ) : editMode ? (
              "Update"
            ) : (
              "Add"
            )}
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }}
      >
        <Paper elevation={0} style={{ border: "1px solid #e0e0e0" }}>
          <Box display="flex" padding={2} className={classes.meetingHead}>
            <Box flexGrow={1}>{editMode ? "Edit" : "Add"} Product</Box>
            <Box textAlign="right">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid xs={12} item>
                  Featured
                </Grid>
                <Grid xs={12} item>
                  <AntSwitch
                    checked={values.isFeatured == "Y" ? true : false}
                    onChange={() => {
                      values.isFeatured = values.isFeatured == "Y" ? "N" : "Y";
                      console.log("CHECBOX VALUE: ", values);
                      setValues({ ...values });
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Grid container style={{ padding: "12px", margin: "30px 0" }}>
            <Grid
              style={{ position: "relative" }}
              container
              item
              md={6}
              xs={12}
            >
              {values.images.map((image, index) => {
                return (
                  <>
                    <Grid
                      style={{
                        // height: "55%",
                        display: "flex",
                        padding: "10px",
                        justifyContent: "center",
                      }}
                      xs={4}
                    >
                      <Box
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          fontSize: "50px",
                          "&:hover": {
                            backgroundColor: "#e1e1e1",
                          },
                        }}
                      >
                        <Box
                          style={{
                            position: "absolute",
                            margin: 0,
                            fontSize: 20,
                            right: "20px",
                            color: "red",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            values.removed_images.push(image.id);
                            values.images.splice(index, 1);
                            setValues({ ...values });
                          }}
                          component="span"
                        >
                          x
                        </Box>

                        {_.isUndefined(image.path) ? (
                          <>
                            <Box
                              style={{ minHeight: "215px", maxHeight: "215px" }}
                            >
                              <img
                                // height="100%"
                                width="100%"
                                style={{
                                  objectFit: "cover",
                                  maxHeight: "200px",
                                }}
                                src={URL.createObjectURL(image)}
                              />
                            </Box>
                          </>
                        ) : (
                          <Box
                            style={{ minHeight: "215px", maxHeight: "215px" }}
                          >
                            <img
                              width="100%"
                              src={image.image_url}
                              style={{ objectFit: "cover", maxHeight: "200px" }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    {index == values.images.length - 1 && (
                      <Box
                        style={{ display: "flex", height: "24%", width: "24%" }}
                      >
                        <Grid style={{ height: "100%" }} xs={4}>
                          <FilePicker
                            fileHandler={filesHandler}
                            type="component"
                            filetype="image"
                          >
                            <Box
                              style={{
                                fontSize: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "29px",
                                padding: "30px 85px",
                              }}
                              className={classes.imagePicker}
                            >
                              +
                            </Box>
                          </FilePicker>
                        </Grid>
                      </Box>
                    )}
                  </>
                );
              })}

              {values.images.length == 0 && (
                <Box style={{ display: "flex", height: "24%", width: "24%" }}>
                  <Grid item style={{ height: "100%", width: "100%" }} xs={12}>
                    <FilePicker
                      fileHandler={filesHandler}
                      type="component"
                      filetype="image"
                    >
                      <Box
                        style={{
                          fontSize: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "10px",
                          padding: "30px 85px",
                        }}
                        className={classes.imagePicker}
                      >
                        +
                      </Box>
                    </FilePicker>
                  </Grid>
                </Box>
              )}
              <Box
                style={{
                  position: "absolute",
                  bottom: 0,
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {errors.images}
              </Box>
            </Grid>

            <Grid
              container
              spacing={2}
              item
              md={6}
              xs={12}
              style={{ height: "25%" }}
            >
              <Grid item xs={6}>
                {/* {JSON.stringify(values)} */}
                <InputLabel className={globalClasses.inputLabel}>
                  Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name ? true : false}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  variant="outlined"
                  placeholder="Name"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Category
                </InputLabel>
                <Select
                  multiple
                  id="categories"
                  name="categories"
                  error={errors.categories && touched.categories ? true : false}
                  onChange={(event, value) => {
                    if (values.categories[0] == "none")
                      values.categories[0] = value.props.value;
                    else {
                      values.categories = event.target.value;
                    }
                    setFieldValue(values.categories);
                  }}
                  onBlur={handleBlur}
                  value={values.categories}
                  fullWidth
                  variant="outlined"
                  className={globalClasses.selectInput}
                >
                  <MenuItem disabled selected value="none">
                    Select
                  </MenuItem>
                  {categoryList.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Box style={{ fontSize: "12px", color: "red" }}>
                  {touched.categories && errors.categories}
                </Box>
                {/* <TextField variant="outlined" placeholder="Category" /> */}
              </Grid>
              {/* <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Offer Price (Incl.Tax)
                </InputLabel>
                <TextField
                  id="offer_price"
                  type="number"
                  name="offer_price"
                  error={
                    touched.offer_price && errors.offer_price ? true : false
                  }
                  helperText={touched.offer_price && errors.offer_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.offer_price}
                  variant="outlined"
                  placeholder="Offer Price"
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Retail Price
                </InputLabel>
                <TextField
                  id="retail_price"
                  name="retail_price"
                  type="number"
                  error={
                    touched.retail_price && errors.retail_price ? true : false
                  }
                  helperText={touched.retail_price && errors.retail_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.retail_price}
                  variant="outlined"
                  placeholder="Retail Price"
                />
              </Grid> */}
              <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  POS Price (Excl.Tax)
                </InputLabel>
                <TextField
                  id="pos_price"
                  type="number"
                  name="pos_price"
                  error={touched.pos_price && errors.pos_price ? true : false}
                  helperText={touched.pos_price && errors.pos_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pos_price}
                  variant="outlined"
                  placeholder="POS Price"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Quantity
                </InputLabel>
                <TextField
                  disabled
                  id="quantity"
                  name="quantity"
                  type="number"
                  error={touched.quantity && errors.quantity ? true : false}
                  helperText={touched.quantity && errors.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quantity}
                  variant="outlined"
                  placeholder="Quantity"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Barcode Code (SKU)
                </InputLabel>
                <TextField
                  id="barcode"
                  name="barcode"
                  type="number"
                  error={touched.barcode && errors.barcode ? true : false}
                  helperText={touched.barcode && errors.barcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.barcode}
                  variant="outlined"
                  placeholder="Barcode"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Tax (%) {errors.tax}
                </InputLabel>
                <TextField
                  id="tax"
                  name="tax"
                  type="number"
                  error={touched.tax && errors.tax ? true : false}
                  helperText={touched.tax && errors.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tax}
                  variant="outlined"
                  placeholder="Tax"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <InputLabel className={globalClasses.inputLabel}>
                  Price (Excl. Tax)
                </InputLabel>

                <TextField
                  id="tax"
                  name="tax"
                  type="number"
                  disabled
                  value={priceTaxExcluded}
                  variant="outlined"
                  placeholder="Tax"
                />
              </Grid> */}
            </Grid>
          </Grid>

          {/* <Grid container style={{ padding: "12px" }} spacing={2}>
            <Grid item xs={12}>
              <Tabs
                value={detailTab}
                onChange={(event, newValue) => setDetailsTab(newValue)}
                TabIndicatorProps={{
                  style: { backgroundColor: theme.palette.primary.main },
                }}
                aria-label="simple tabs example"
              >
                <Tab value="short_description" label="Short Description" />
                <Tab value="description" label="Description" />
              </Tabs>

              <TabPanel index="short_description" value={detailTab}>
                <Box style={{ marginTop: 10 }}>
                  {getProductByIdApiStatus.isPending ? (
                    <DefaultLoader />
                  ) : (
                    <DraftJS
                      data={values.short_description}
                      onChange={({ state }) => {
                        setFieldValue("short_description", state);
                      }}
                    />
                  )}

                  <Box style={{ color: "red" }}>{errors.short_description}</Box>
                </Box>
              </TabPanel>

              <TabPanel index="description" value={detailTab}>
                <Box style={{ marginTop: 10 }}>
                  <DraftJS
                    data={values.description}
                    onChange={({ state }) => {
                      console.log("content : ", state);
                      setFieldValue("description", state);
                    }}
                  />
                  <Box style={{ color: "red" }}>{errors.description}</Box>
                </Box>
              </TabPanel>
            </Grid>
          </Grid> */}

          <Grid container style={{ padding: "12px" }} spacing={2}>
            <Grid item xs={12} style={{ fontSize: "18px", fontWeight: 600 }}>
              Product Options
            </Grid>

            {values.options.map((option, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid
                    style={{ display: "flex", alignItems: "flex-end" }}
                    item
                    xs={12}
                  >
                    <Box>
                      <InputLabel className={globalClasses.inputLabel}>
                        Option Name
                      </InputLabel>
                      <TextField
                        onChange={(e) => {
                          values.options[index].name = e.target.value;
                          setValues({ ...values });
                        }}
                        value={option.name}
                        variant="outlined"
                        placeholder="Value"
                        xs={12}
                      />
                    </Box>
                    <Box flexGrow={1} textAlign="end">
                      <Button
                        variant="contained"
                        onClick={() => {
                          values.options.splice(index, 1);
                          setValues({ ...values });
                        }}
                        style={{ background: "#e64848", color: "white" }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Grid>

                  {option.optionValues.map((optval, index2) => {
                    return (
                      <React.Fragment key={index2}>
                        <Grid item xs={3}>
                          <InputLabel className={globalClasses.inputLabel}>
                            Value
                          </InputLabel>
                          <TextField
                            onChange={(e) => {
                              values.options[index].optionValues[index2].value =
                                e.target.value;
                              setValues({ ...values });
                            }}
                            value={optval.value}
                            variant="outlined"
                            placeholder="Value"
                            xs={12}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel className={globalClasses.inputLabel}>
                            Offer Price (Incl.Tax)
                          </InputLabel>
                          <TextField
                            onChange={(e) => {
                              values.options[index].optionValues[
                                index2
                              ].offer_price = e.target.value;
                              setValues({ ...values });
                            }}
                            value={optval.offer_price}
                            variant="outlined"
                            placeholder="Offer Price"
                            type="number"
                            xs={12}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel className={globalClasses.inputLabel}>
                            Retail Price
                          </InputLabel>
                          <TextField
                            onChange={(e) => {
                              values.options[index].optionValues[
                                index2
                              ].retail_price = e.target.value;
                              setValues({ ...values });
                            }}
                            value={optval.retail_price}
                            variant="outlined"
                            placeholder="Retail Price"
                            type="number"
                            xs={12}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            alignSelf: "flex-end",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                option.optionValues.push({
                                  name: option.name,
                                  value: "",
                                  offer_price: 0,
                                  retail_price: 0,
                                });

                                setValues({ ...values });
                              }}
                            >
                              +
                            </Button>
                          </Box>
                          {index2 > 0 && (
                            <Box>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  option.optionValues.splice(index2, 1);
                                  setValues({ ...values });
                                }}
                              >
                                -
                              </Button>
                            </Box>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}

            <Grid
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
              item
              xs={12}
            >
              {/* <Box>
                    <InputLabel className={globalClasses.inputLabel}>Option Name</InputLabel>
                    <TextField variant="outlined" placeholder="Value" xs={12}/>
              </Box> */}

              {/* <Box flexGrow={1} textAlign="end"> */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  values.options.push({
                    name: "",
                    optionValues: [
                      {
                        value: "",
                        offer_price: 0,
                        retail_price: 0,
                      },
                    ],
                  });

                  setValues({ ...values });
                  // addNewOptionRow()
                }}
              >
                Add
              </Button>
              {/* </Box> */}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default AddProduct;
