import React, { createContext, useContext } from 'react'

const MenuCtx = createContext({
    menuStatus: false,
    handleDrawerToggle: () => { }
})

function MenuContext(props) {
    const [menuStatus, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!menuStatus);
    };

    return (
        <MenuCtx.Provider value={{menuStatus,handleDrawerToggle}}>
            {props.children}
        </MenuCtx.Provider>
    )
}

const useMenuCtx = () => {
    const context = useContext(MenuCtx);

    if (context === 'undefined') {
        console.error('useMenuCtx must be used inside <MenuContext>')
    }

    return context;
}

export { MenuContext, useMenuCtx }
